import React, { Component } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer'; 
import { http } from '../helper';
import DataTable from 'react-data-table-component';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import Popup from '../common/popup';
import moment from 'moment';
import discountIcon from '../assets/images/discount-icon.png';

class Stripepaymentlog extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            searchTextVal:"",
            stripeloglist: [],
            templist :[],
            loading: true,
            popupView: "",
            popupView1: "",
            visibleAlert:false,
            holidayvalue : '',
            alertPopup:false,
            isLoading:false,
        };

        if(!sessionStorage.getItem("isLoggedIn")){
            window.location.href = '/';
        }
        if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
            {
              window.location.href = '/unauthorized';
            }
    
     }

    getStripelogslist() {
        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
           loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        } 
        let URL = BaseUrl + "admin/stripelogs";
        http.get(URL).then((response) => {
           
            const sortedData =  response.data.data
            .filter(item => item.orderID)
            .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({ stripeloglist: sortedData, loading: false, templist:response.data.data});
        }).catch((err) => {
            console.log(err);
        })
    }

    getOrderDetails=(stripelog)=>{
        this.setState({
            popupView1: (
                <Popup
                    extClass="order-pop"
                    headMoreTxt={
                        <>
                         
                        </>
                    }
                    closebtn={
                        <>
                            <div className="order-top">
                                <div className="head">
                                    <h4>Order Details - {stripelog.orderID}</h4>
                                </div>
                                <span onClick={() => this.setState({ popupView1: "" })} className='close'></span>
                            </div>
                        </>
                    }
                    bodyMoreTxt={<div className='loading-buffer'></div>}
                />
            ),
            alertPopup: false,
            isLoading: true,
        });
    
        var numberOfAjaxCAllPending = 0;
        var timeInterval = 20000;
        var current = this;
        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }

       var URL = BaseUrl + "admin/getorderdetails/"+stripelog.orderID;
       http.get(URL).then((response) => {
            numberOfAjaxCAllPending++;
            let responseData = [];
            responseData = response.data.data[0];
             
             clearInterval(myInterval);
             current.setState({isLoading:false})
            current.viewOrderlogDetails(responseData);
            current.setState({alertPopup: false});

        }).catch((err) => {
            console.log(err);
            current.setState({alertPopup: false,isLoading:false});
        });
        var myInterval = setInterval(function() {
            if(numberOfAjaxCAllPending == 0){
              numberOfAjaxCAllPending++;
              timeInterval = '';
              current.setState({alertPopup: true,isLoading:false});
            }},timeInterval);
    }
    str_replace(product_name){

        if(product_name !==''){

          product_name =  product_name.replaceAll('amp;', '&');
          product_name = product_name.replaceAll("apos;'", "'' ");
          product_name = product_name.replaceAll("quot;'", "'' ");
           
          return product_name;

        }else{

            return product_name;
        }
    }
    priceFormat(price) {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2
        });
    }

    vatAmountCalcuation(order){
        //var old_order = order;
        var totalAmount = 0;
        order.items.map((item, value)=>{
           if(item.TaxCode !=='' && item.TaxCode === 'T1'){
              totalAmount += item.LinePrice;
           }
         });
         return totalAmount;
        }
    viewOrderlogDetails=(order)=>
    {
        var ordertotal = 0;
        var subTotal = 0;
        this.setState({alertPopup: false, alertMessage: ''});
        order.items.map((item) => { ordertotal += item.LinePrice; });
        order.items.map((item) => { subTotal += item.UnitPrice * item.QuantityOrdered; });
        var vatTotalAmount = this.vatAmountCalcuation(order);
        var vatAmount = 0;
        if(vatTotalAmount > 0){
            vatAmount = (vatTotalAmount / 100) * 20; 
            ordertotal = ordertotal + vatAmount;
        }

        if(order.accountEmail !== undefined)
            {
                this.setState({oaccountName:order.accountName,oaccountEmail:order.accountEmail,oaccountAddress:order.accountAddress},() => this.createPopup(order, subTotal, ordertotal, vatAmount, vatTotalAmount));
              
            }
            else{
          
            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
                loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            }
            let URL = BaseUrl + "admin/account/"+order.accountCode;
            http.get(URL).then((response) => {
                let responseData = [];
                responseData = response.data.data;
                let addressFields = [
                    responseData.accountAddress1,
                    responseData.accountAddress2,
                    responseData.accountAddress3,
                    responseData.accountAddress4,
                    responseData.accountAddress5,
                  ];
                this.setState({oaccountName:responseData.accountName,oaccountEmail:responseData.accountEmail,oaccountAddress:addressFields.filter(field => field !== undefined && field !="").join(", ")},() => this.createPopup(order, subTotal, ordertotal, vatAmount, vatTotalAmount));
              }).catch((err) => {
               
                this.setState({ loading: false });
                
            });
                
        }
    }
    createPopup = (order, subTotal, ordertotal, vatAmount, vatTotalAmount) => {  
      // Sort order items to move those with UnitPrice 0 to the end
    const sortedItems = [...order.items].sort((a, b) => {
        if (a.UnitPrice === 0 && b.UnitPrice !== 0) return 1;
        if (a.UnitPrice !== 0 && b.UnitPrice === 0) return -1;
        return 0;
      });     
    let popupResult = <Popup
        extClass="order-pop"
        headMoreTxt={
            <>
             <div className="order-top">
             <div className="bottom order-flex">
                {/* Changed by K2b sangeetha for getting account details of particular order  */}
                     <div className='order_no_details'>
                         <h3>Order No - {order.OrderNumber}</h3>
                         <p>Order Placed on: <span className="date"> {moment(order.createdAt).format('DD/MM/YYYY hh:mm A')}</span> </p>
                         <p>Order Delivery on: <span className="date"> {order.DateRequired} </span></p>
                         
                         {order.PaymentMethod === 'A' &&
                           <p>Payment Method:<span className="pay"> {order.OrderPaid === 'YES' ? 'Paid (Pay Later)':'Pay Later'}</span></p>

                         }
                         {order.PaymentMethod === 'K' &&
                           <p>Payment Method:<span className="pay"> Online Payment</span></p>
                         }

                         <p>Status:<span className="pay"> {order.OrderStatus}</span></p>
                     </div>

                     <div className='order_account_details'>
                         <p>Name: <span className="date">{this.state.oaccountName}</span> </p>
                         <p>Account Code: <span className="date">{order.accountCode}</span> </p>
                         <p>Email Address: <span className="pay">{this.state.oaccountEmail}</span></p>
                         <p>Address: <span className="pay">{this.state.oaccountAddress}</span></p>
                          {order.PODetails && (<p>
                                PO Details:{" "}
                                <span className="pay">{order.PODetails}</span>
                            </p>)}
                     </div>
                {/* Changed by K2b sangeetha for getting account details of particular order  */}

                </div>

             </div>
            </>
        }
        closebtn={<>
                <div className="pop-header-top order-top">
                    <div className="head">
                        <h4>Order Details - {order.OrderNumber} </h4>
                    </div>
                <span onClick={() => this.setState({ popupView1: "" })} className='close'></span> 
                </div>

        </>}

        bodyMoreTxt={
            <>
                             
                <div className="modal-table">
                    <h4 className="item-title">Ordered Items</h4>
                    <div className="checkout-table">
                        <div className="table_overflow">
                            <table>
                                <thead>
                                    <tr>
                                        <td>Product</td>
                                        <td>Unit of Sale</td>
                                        <td>Quantity</td>
                                        <td align="center">Price</td>
                                        <td align="right">Total</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Added Special offer and product number like frontend */}
                                {sortedItems.map((item, index) => {

                                    return (item.QuantityOrdered > 0 && <tr key={index}
                                        className={item.UnitPrice === 0 ? 'free-prod-bg' : ''}>
                                        <td data-title="Product" className='data-product-hide'>{this.str_replace(item.Description)} ({(item.ProductNumber.includes('_')) ? item.ProductNumber.substring(0,item.ProductNumber.indexOf('_')) : item.ProductNumber}){item.TaxCode !=='' && item.TaxCode === 'T1' && <span className='color-red'>*</span>}
                                            {item.OfferFlag == "true" && <span className='offer-tag-wrap'><span className="offer-tag"><img src={discountIcon} alt="Offer" className="tag-icon" />Special Offer</span></span>}

                                        </td>
                                        <td data-title="Unit of Sale">{item.PricingUnit}</td>
                                        <td data-title="Quantity">{item.QuantityOrdered}</td>
                                        <td data-title="Price" align="center">{this.priceFormat(item.UnitPrice)}</td>
                                        <td data-title="Total" align='right'>{this.priceFormat(item.LinePrice)}</td>

                                    </tr>)
                                    })}
                                     {/* Added Special offer and product number like frontend */}
                                </tbody>
                                <thead className='ord-grand-total'>
                                { order.OrderNote !=='' && order.OrderNote !== null && 
                                    <tr>
                                        <td  align="left" width={120} colSpan="7">Order Notes: <span className='order_remarks'> {this.getOrderNotes(order.OrderNote)}</span></td>
                                    </tr>
                                    }
                                    <tr>
                                        <td  align="right" width={120} colSpan="4">Subtotal:</td>
                                        <td align="right">{this.priceFormat(subTotal)}</td>
                                    </tr>
                                    {vatAmount > 0 && 
                                     <tr>
                                        <td align="right" width={120} colSpan="4" className='td-vat-fee'>
                                            <span className="strong">VAT <span className='vat-txt'>(20% of <span className='vat-amt'>{this.priceFormat(vatTotalAmount)}</span>)<span className='color-red'>*</span>:</span></span>
                                        </td>
                                        <td className='cart-prod-vat' align="right">{this.priceFormat(vatAmount)}</td>
                                      </tr>
                                      }

                                    
                                    <tr>
                                        <td  align="right" width={120} colSpan="4">Shipping:</td>
                                        <td align="right"><span className='color-red'>FREE</span></td>
                                    </tr>
                        
                                    <tr><td  align="right" width={120} colSpan="4">Grand Total:</td><td align="right">{this.priceFormat(ordertotal)}</td></tr>
                                    </thead>
                            </table>
                        </div>
                    </div>

                </div>
            </>
        }
    />;

    this.setState({ popupView1: popupResult });
    }

    getOrderNotes(OrderNote){
        
        var note_test = OrderNote.replaceAll(' PIXHALLCSL', '');
          note_test =  note_test.replaceAll('amp;', '&');
          note_test = note_test.replaceAll("apos;", "'' ");
          note_test = note_test.replaceAll("apos;'", "'' ");
          note_test = note_test.replaceAll("quot;'", "'' ");  
          note_test = note_test.replaceAll("quot;", "'' ");        
          note_test = note_test.replaceAll("'' '", "'' ");
          return note_test;
    }


    viewStripepaymentlogDetails(stripelog) {
        var requestval = stripelog.requestValue.replace(/\//g, '');
        var responseval = stripelog.responseValue.replace(/\//g, '');
        var price = Number(JSON.parse(stripelog.requestValue.replace(/\//g, '')).amount)/100;
        let popupResult = <Popup
            extClass="order-pop pop-container-sm"
            headMoreTxt={
                <>
                    <div className="order-top">
                        <div className="head">
                            <h4>Stripe Payment Log Details</h4>
                        </div>
                    </div>
                </>
            }
            bodyMoreTxt={
                <>
                    <div className="order-top">
                        
                        <div className="bottom vendor-details stripelogdetails">
                            <p><span className="labeltxt">AccountCode</span>: {stripelog.accountCode}</p>
                            <p><span className="labeltxt">Order Number</span>: {stripelog.orderID}</p>
                            <p><span className="labeltxt">Stripe Token</span>: {stripelog.referenceToken}</p>
                            <p><span className="labeltxt">Request Value</span>: {requestval}</p>
                            <p><span className="labeltxt">Response Value</span>: {responseval}</p>
                            <p><span className="labeltxt">Total Amount</span>: { this.priceFormat(price)}</p>
                            <p><span className="labeltxt">Created Date</span>: {moment(stripelog.createdAt).format('DD-MM-YYYY')}</p>
                            {/* <p><span className="labeltxt">Status</span> - {holiday.holidayStatus}</p> */}
                            
                        </div>
                    </div>
                </>
            }
            closebtn={<><span onClick={() => this.setState({ popupView: "" })} className='close'></span></>}

           
        />;

        this.setState({ popupView: popupResult });

    }

    componentDidMount() {
        this.getStripelogslist();
    }

   

    handleReset=()=>{
        this.setState({searchTextVal:'',stripeloglist:this.state.templist});
     };

    handleChange = async(value) => {
        this.setState({searchTextVal:value});
        this.filterData(value);
      };
           

      filterData = async(value) => {

        let accoun = this.state.templist;
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.accountCode.toString().toLowerCase().includes(lowercasedValue) ||
            (item.orderID && item.orderID.toString().toLowerCase().includes(lowercasedValue)) ||
            (item.referenceToken && item.referenceToken.toString().toLowerCase().includes(lowercasedValue)))
            {
              result.push(item);
            }
          })
          this.setState({stripeloglist:result});
        }else {
        this.setState({stripeloglist:accoun});
      }
      };

      priceFormat(price) {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2
        });
    }

    render() {

        const columns = [
            {
                name: "Account Code",
                selector: row => row.accountCode,
                sortable: true,
            },
            {
                name: "Order Number",
                selector: row =><div style={{display:"flex"}}>
                    <a data-title='View Order Details' onClick={this.getOrderDetails.bind(this, row)}>{row.orderID}</a></div> ,
                sortable: true,
            },
            {
                name: "Stripe Token",
                selector: row => row.referenceToken,
                sortable: true,
            },
            {
                name: "Created Date",
                selector: row => moment(row.createdAt).format('DD-MM-YYYY HH:mm:ss'),
                sortable: true,
            },
            {
                name: "Total Amount",
                
                selector: row => this.priceFormat(Number(JSON.parse(row.requestValue.replace(/\//g, '')).amount)/100),
                sortable: true,
                sortFunction: (a, b) => {
                    const amountA = Number(JSON.parse(a.requestValue.replace(/\//g, '')).amount) / 100;
                    const amountB = Number(JSON.parse(b.requestValue.replace(/\//g, '')).amount) / 100;
                    return amountA - amountB;
                }
            },
            {
                name: "Actions",
                selector: row => <div style={{display:"flex"}}>
                    <a className='button title-tooltip title-up' data-title='View Details' style={{color: "white"}} onClick={this.viewStripepaymentlogDetails.bind(this, row)}><i class="fa fa-eye" aria-hidden="true"></i></a>
                    
                    </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells
                    
                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells
                    
                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage:'',
        };

        return (
            <>
                <div className="dashboard-block">

                    
                        <Sidebar />
                    
                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                        <div className="container">
                            <h1 className="checkout-title">Stripe Payment Log list</h1>  

                            <div className="right-table-outer-wrap">
                            <div className="table-filter search-orders">
                              <div className='table-search-reset'>
                                <div className="table-search">
                                    <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Account Code, Order Number, Stripe Token" />
                                    
                                </div>
                                <button className='submitbtn button search-holi-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i class="fa fa-search"></i></button>
                                <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                              </div>
                             
                            </div>
                            
                            <div className="table_overflow stripelog-payment-table">
                            
                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, no stripe payment logs were matching your keyword, try a new search.</div>}
                                        data={this.state.stripeloglist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                    />
                                    
                                    </div>
                                   
                    
                            </div>
                        </div>
                        </section>
                        <Footer />
                        
                    </div>
                    {this.state.popupView}
                    {this.state.popupView1}
                </div>
               
            </>
        );
    }
}

export default Stripepaymentlog;