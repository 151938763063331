import React, { useState,useEffect,useRef } from 'react';
import { BaseUrl } from '../common/config';
import { http } from '../helper';
import Popup from "../common/popup";


const editProductDetails=(props) => {
    const [isLoading, setIsLoading] = useState(true);    
    const [formData, setFormData] = useState({
        Pid:props.editProddet.productNumber,
        unitofsale: props.editProddet.packageSize || '',
        PrdName: props.editProddet.productDescription || '',
        prodImage: props.editProddet.productImage || '',
        prodnewImage: props.editProddet.productImage || '',
        imageURL:props.editProddet.productImage || '',
        prodSpec: props.editProddet.productSpec || '',
        pdfURL: props.editProddet.productSpec || '',
        PrdIngred: props.editProddet.productIngredient || '',
        PrdAllerg:props.editProddet.productUSP || '',
        Prdtaxcode:props.editProddet.productTaxCode || '',
        Prdgroup:props.editProddet.ProductGroup || '',
        Prdsuitablefor:props.editProddet.SuitableFor || '',
        //newFile:null,   
        //newPdfFile:null,  
    });
    const [productGroups, setProductGroups] = useState([]);
    const [taxCodes, setTaxCodes] = useState([]);
    const [suitableFors, setSuitableFors] = useState([]);
    const taxCodeRef = useRef(null);
    const productGroupRef = useRef(null);
    const suitableForRef = useRef(null);
    useEffect(()=>{
        fetchData(); 
    },[])


    useEffect(() => {
        if (!isLoading) {
            if (taxCodeRef.current) {
                taxCodeRef.current.value = formData.Prdtaxcode;
            }
            if (productGroupRef.current) {
                productGroupRef.current.value = formData.Prdgroup;
            }
            if (suitableForRef.current) {
                suitableForRef.current.value = formData.Prdsuitablefor;
            }
        }
    }, [formData, isLoading]);

    

    const fetchData = async () => {
        setIsLoading(true);
        
        try {
        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }       
        
    let URL = BaseUrl + `admin/product/masterentries`;
    const response = await http.get(URL)
         
        if (response.data.success) {
            const { productgroup, taxcode, suitablefor } = response.data.data;
            setProductGroups(productgroup);
            setTaxCodes(taxcode);
            setSuitableFors(suitablefor);
        }
                
     }catch(err) {
        console.log(err);
        
     }finally{
        setIsLoading(false);
     }
    };

     const [errors, setErrors] = useState({});
     const [errorField, setErrorField] = useState(null);

    const handleValue = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear error message for the corresponding field
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };


    const editSubmit=(e)=>{
        e.preventDefault(); // Prevent default form submission behavior

        const isValid = formvalidation();
        if(isValid)
        { 
            //setIsFileLoading(true);  
           props.editProdDetails(formData);
        }
        else {
            // Focus on the first field with an error message
           setErrorField(Object.keys(errors)[0]);
        }
    }
    const handleFileChange = (event) => {
        const newPdfFile = event.target.files[0];
        if (newPdfFile) {
            const allowedTypes = ['application/pdf'];
            if(newPdfFile.size > 2 * 1024 * 1024){
                setErrors({prodSpec : "File size exceeds 2 MB"});
                return false;
            }else if (allowedTypes.includes(newPdfFile.type)) {
       
            setFormData({
                ...formData,
                 prodSpec: newPdfFile,
                 //newPdfFile:newPdfFile,
            });
      
        }
        else
        {
            setErrors({prodSpec : "Only PDF files are allowed!!!"});
            return false;
        }
        const { name, value } = event.target;
         // Clear error message for the corresponding field
         setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    }

      };

    const handleImageFileChange = (event) => {
        const newFile = event.target.files[0];
        if (newFile) {
            const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg'];
           
            if(newFile.size > 2 * 1024 * 1024){
                
                setErrors({prodImage : "File size exceeds 2 MB"});
                return false;
            }else if (allowedTypes.includes(newFile.type)) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setFormData({
                ...formData,
                prodnewImage: reader.result,
                prodImage: newFile
              });
           };
         reader.readAsDataURL(newFile);
        }
        else
        {
            setErrors({prodImage : "Only PNG, JPG, and JPEG files are allowed!!!"});
            return false;
        }
        const { name, value } = event.target;
         // Clear error message for the corresponding field
         setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    }

      };

    const formvalidation = () =>
    {        
        const newErrors = {};
        let isValid = true;

        if (formData.PrdName.trim() === '') {
            newErrors.PrdName = "Product Description is Required";
            isValid = false;
        }
        if (formData.unitofsale.trim() === '') {
            newErrors.unitofsale = "Unit Of Sale is Required";
            isValid = false;
        }

        // if (formData.PrdIngred.trim() === '') {
        //     newErrors.PrdIngred = "Ingerdients is Required";
        //     isValid = false;
        // }
        // if (formData.PrdAllerg.trim() === '') {
        //     newErrors.PrdAllerg = "Allergens is Required";
        //     isValid = false;
        // }
        if (formData.Prdtaxcode.trim() === '') {
            newErrors.Prdtaxcode = "Tax Code is Required";
            isValid = false;
        }

        if (formData.Prdgroup.trim() === '') {
            newErrors.Prdgroup = "Product Group is Required";
            isValid = false;
        }
        if (formData.Prdsuitablefor.trim() === '') {
            newErrors.Prdsuitablefor = "Suitable For is Required";
            isValid = false;
        }
      
        if(document.getElementById("prodImage").files.length > 0){
            const fileInput = document.getElementById('prodImage'); // Assuming 'prodImage' is the ID of your file input element
            const allowedExtensions = ['png', 'jpg', 'jpeg'];
            if(fileInput.size > 2 * 1024 * 1024){
               
                newErrors.prodImage = "File size exceeds 2 MB";
                isValid = false;
            }else if (!hasExtension(fileInput, allowedExtensions)) {
                newErrors.prodImage = "Only PNG, JPG, and JPEG files are allowed!!!";
                isValid = false;
            }

        }

        setErrors(newErrors);
        return isValid;
    
        
    }
    const hasExtension = (fileInput, exts) => {
        const fileName = fileInput.value.toLowerCase();
        const extension = fileName.split('.').pop(); // Get the file extension
        return exts.includes(extension);
    };

    
  return (
    <Popup
            extClass="order-pop info-poup"
            headMoreTxt={
                <>
                    <div className="order-top">
                    <div className="head">
                    <h4>Edit Product Details</h4>
                    </div>
                   
                    </div>
                </>
            }
            bodyMoreTxt={
               ( isLoading || props.editloading) ? (
                  <div className='loading-buffer'></div>
                ):(  
                        <form className="form-field bottom vendor-details" onSubmit={(e)=>editSubmit(e)}>
                            <div className="input-field">
                            <span className="labeltxt">Product Code</span>
                                <input type='text'  name="Pid" value={formData.Pid} readOnly />
                            </div>
                           
                            <div className="input-field">
                            <span className="labeltxt">Description (Product Name)</span>
                                <input type='text'  name="PrdName" value={formData.PrdName} onChange={handleValue} />
                                {errors.PrdName && <div className='error-msg'>{errors.PrdName}</div>}
                            </div>
                            <div className="input-field col-2">
                            <span className="labeltxt">Category</span>
                            <input type='text' name="categoryId" value={(props.editProddet.categoryId ==="1" ? "PIZZA":((props.editProddet.categoryId ==="10" || props.editProddet.categoryId ==="13") ? "PASTA, RICE & NOODLE SAUCES":"BURRITO"))} readOnly/>
                            </div>
                            <div className="input-field col-2">
                            <span className="labeltxt">Tax Code</span>
                            <select name="Prdtaxcode" ref={taxCodeRef} onChange={handleValue}>
                                <option value="">Select</option>
                                {taxCodes.map(code => (
                                    <option key={code._id} value={code.MasterValue}>{code.MasterValue}</option>
                                ))}
                            </select>

                                {errors.Prdtaxcode && <div className='error-msg'>{errors.Prdtaxcode}</div>}
                            </div>
                           
                            <div className="input-field">
                                <span className="labeltxt">Product Image</span>
                                <span className="text-note"> (Maximum Upload File Size: 2 MB)</span>
                                <input type='file' id="prodImage" name="prodImage" onChange={handleImageFileChange}/>
                                {errors.prodImage &&   <div className="color-red">{errors.prodImage}<br/><br/></div>}
                                {formData.prodnewImage && <img width='100' src={formData.prodnewImage} alt="Preview" />}                      
                                <input type='hidden' name="imageURL" value={formData.prodImage}/>
                            </div>
                            
                            <div className="input-field">
                            <span className="labeltxt">Product Reference</span>
                                <input type='text'  name="PrdReference" value={props.editProddet.ProductReference} readOnly />
                            </div>
                            <div className="input-field">
                            <span className="labeltxt">USP (Allergens)</span>
                            <textarea name="PrdAllerg" readOnly value={formData.PrdAllerg}/>
                            </div>
                            <div className="input-field">
                            <span className="labeltxt">Colours (Ingredients)</span>
                               <textarea name='PrdIngred' value={formData.PrdIngred} onChange={handleValue}/>
                                {errors.PrdIngred && <div className='error-msg'>{errors.PrdIngred}</div>}
                            </div>
                            <div className="input-field">
                              <span className="labeltxt">Spec (Product Specification)</span>
                              <span className="text-note"> (Maximum Upload File Size: 2 MB)</span>
                                <input type='file' id="prodSpec" name="prodSpec" onChange={handleFileChange}/>
                                {errors.prodSpec &&  <div className="color-red">{errors.prodSpec}<br/><br/></div>}                               
                                {formData.pdfURL && <div className='up-data-file'><a href={formData.pdfURL} target='_blank'>{formData.pdfURL}</a></div>}
                                <input type='hidden' name="pdfURL" value={formData.pdfURL}/>
                            </div>
                            <div className="input-field col-2">
                            <span className="labeltxt">Pack Size (Unit Of Sale)</span>
                                <input type='text' name="unitofsale" value={formData.unitofsale} onChange={handleValue}/>
                                {errors.unitofsale && <div className='error-msg'>{errors.unitofsale}</div>}
                            </div>
                                     
                            <div className="input-field col-2">
                            <span className="labeltxt">Product Group</span>
                            <select name="Prdgroup" ref={productGroupRef} onChange={handleValue}>
                                <option value="">Select</option>
                                {productGroups.map(group => (
                                    <option key={group._id} value={group.MasterValue}>{group.MasterValue}</option>
                                ))}
                            </select>

                                {errors.Prdgroup && <div className='error-msg'>{errors.Prdgroup}</div>}
                            </div>
                            <div className="input-field col-2">
                            <span className="labeltxt">Suitable For</span>
                                <select name="Prdsuitablefor" ref={suitableForRef} onChange={handleValue}>
                                    <option value="">Select</option>
                                    {suitableFors.map(suitable => (
                                        <option key={suitable._id} value={suitable.MasterValue}>{suitable.MasterValue}</option>
                                    ))}
                                </select>
                                {errors.Prdsuitablefor && <div className='error-msg'>{errors.Prdsuitablefor}</div>}
                            </div>
                            
                           

                            <div className="btn-field btn-center"><button type="submit">Submit</button>&nbsp;&nbsp;<button onClick={(e)=>props.popupclose(e,false,'')}>Cancel</button></div>          
                        </form>
                 )
            }
                              
            closebtn={<><span onClick={(e)=>props.popupclose(e,false,'')} className='close'></span></>}

           
        />
    
  );
}

export default editProductDetails
