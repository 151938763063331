import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../common/layout/header";
import Footer from "../common/layout/footer";
import Loader from "../common/loader";
import { BaseUrl } from "../common/config";
import Sidebar from "../common/sidebar";
import CommonAlert from "../common/Commonalert";
import { http } from "../helper";

const Editfreeproducts = () => {
  ///exit();
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [freeproducts, setfreeProducts] = useState([]);

  const [categoryVal, setCategoryVal] = useState([]);

  const [freecategoryVal, setfreeCategoryVal] = useState([]);
  const [checkedval, setCheckedval] = useState([]);
  const [checkedfreeval, setCheckedfreeval] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [offerexpanded, setOfferExpanded] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [checkedfreeState, setCheckedfreeState] = useState([]);
  const [inputlimitField, setInputlimitField] = useState([]);
  const [inputfreelimitField, setInputfreelimitField] = useState([]);
  const [inputFieldtitle, setInputFieldtitle] = useState([]);
  const [errmessage, setErrMessage] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([
    "pizza",
    "pasta",
    "burrito",
  ]);
  const [selectedFreeCategories, setSelectedFreeCategories] = useState([
    "pizza",
    "pasta",
    "burrito",
  ]);
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [filteredFreeProduct, setFilteredFreeProduct] = useState([]);

  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState([]);

  const params = useParams();

  if (!sessionStorage.getItem("isLoggedIn")) window.location.href = "/";

  if (
    JSON.parse(sessionStorage.getItem("authData")) != "" &&
    !JSON.parse(
      sessionStorage.getItem("authData")
    ).adminRole.permissions.includes("manage_freeproducts")
  ) {
    window.location.href = "/unauthorized";
  }

  function routeChange(e) {
    e.preventDefault();
    window.location.href = "/freeproducts";
    return false;
  }

  // Map of category names to category IDs
  const categoryMap = {
    pizza: ["1"],
    pasta: ["10", "13"],
    burrito: ["11", "12"],
  };

  let categoryid = {};

  useEffect(() => {
    const filteredProducts = getFilteredProducts();

    const arry = filteredProducts.map((prd) => {
      // Check if the productNumber is included in the checkedval array
      if (prd.PDP_Status === "deactivate") {
        return false;
      }
      return checkedval.includes(prd.productNumber);
    });

    setCheckedState(arry);
    setFilteredProduct(filteredProducts);
  }, [products, checkedval, selectedCategories]);

  useEffect(() => {
    const filteredFreeProducts = getFilteredFreeProducts();
    const arry = filteredFreeProducts.map((prd) => {
      // Check if the productNumber is included in the checkedval array
      if (prd.PDP_Status === "deactivate") {
        return false;
      }
      return checkedfreeval.includes(prd.productNumber);
    });

    setCheckedfreeState(arry);
    setFilteredFreeProduct(filteredFreeProducts);
  }, [freeproducts, checkedfreeval, selectedFreeCategories]);

  useEffect(() => {
    setCheckedState(new Array(products.length).fill(false));

    setCheckedval([]);
  }, [inputlimitField]);

  useEffect(() => {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL1 = BaseUrl + `admin/offer/${params.id}`;
    http
      .get(URL1)
      .then((response) => {
        let responseData = response.data.data;
        setLoading(false);
        setInputFieldtitle(responseData.offerTitle);
        setInputlimitField(responseData.productQtyToBeAdded);
        setCategoryVal(responseData.categoryId);
        setCheckedval(responseData.productToBeAdded.split(","));

        setInputfreelimitField(responseData.freeProductQtyToAdd);
        setfreeCategoryVal(responseData.freeCategoryId);
        setCheckedfreeval(responseData.freeProductToAdd.split(","));
        //setSltStatus(responseData.status);
      })
      .catch((err) => {
        console.log(err);
      });

    var priceCode = "PL1";
    let URL = `${BaseUrl}admin/productprice/${priceCode}`;
    http
      .get(URL)
      .then((response) => {
        let responseData = response.data.data;
        setProducts(responseData);
        setLoading(false);
        // setCategoryName(categoryvalue);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    var priceCode = "PL1";
    let URL = `${BaseUrl}admin/productprice/${priceCode}`;
    http
      .get(URL)
      .then((response) => {
        let responseData = response.data.data;
        setProducts(responseData);
        setfreeProducts(responseData);
        setLoading(false);
        // setCategoryName(categoryvalue);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const handleOnChange = (position, e) => {
    const updatedCheckedState = checkedState.map((item, index) => {
      return index === position ? !item : item;
    });

    var updatedList = [...checkedval];

    if (e.target.checked) {
      updatedList = [...checkedval, e.target.value];
    } else {
      updatedList.splice(checkedval.indexOf(e.target.value), 1);
    }
    setCheckedval(updatedList);

    setCheckedState(updatedCheckedState);
  };

  //Free Product checkstate
  const handleOnChange1 = (position, e) => {
    const updatedfreeCheckedState = checkedfreeState.map((item, index) => {
      return index === position ? !item : item;
    });

    var updatedList = [...checkedfreeval];

    if (e.target.checked) {
      updatedList = [...checkedfreeval, e.target.value];
    } else {
      updatedList.splice(checkedfreeval.indexOf(e.target.value), 1);
    }
    setCheckedfreeval(updatedList);

    setCheckedfreeState(updatedfreeCheckedState);
  };

  const submitButton = (e) => {
    // let arr = ['1342','35840'];
    e.preventDefault();
    const isValid = formvalidation();

    if (isValid) {
      var loginDetails = {};
      if (sessionStorage.getItem("authData")) {
        loginDetails = JSON.parse(sessionStorage.getItem("authData"));
      }
      let payload = {
        offerid: params.id,
        offerTitle: inputFieldtitle.trim(),
        categoryId: categoryVal,
        productToBeAdded: checkedval.toString(),
        productQtyToBeAdded: parseInt(inputlimitField),
        freeProductToAdd: checkedfreeval.toString(),
        freeProductQtyToAdd: parseInt(inputlimitField),
        freeCategoryId: freecategoryVal,
        productExtToBeAdded: checkedfreeval.toString(),
        status: "A",
      };

      let URL = BaseUrl + "admin/offer/";
      http
        .put(URL, payload)
        .then((response) => {
          let responseData = response.data.data;

          if (response.data.success === true) {
            setAlertPopup(true);
            setAlertMessage(
              "Free Products Setup have been updated successfully"
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleclick = () => {
    setExpanded(!expanded);
  };

  const handleOfferclick = () => {
    setOfferExpanded(!offerexpanded);
  };

  const formvalidation = () => {
    const errmessage = {};
    let isValid = true;

    if (inputFieldtitle == "") {
      errmessage.inputfieldtitleerr = "Title is Required";
      isValid = false;
    }

    if (inputlimitField == "") {
      errmessage.inputlimitfielderr = "Product Limit is Required";
      isValid = false;
    }

    if (checkedval == "") {
      errmessage.checkedValerr =
        "Prodcuts should be selected based on the limit";
      isValid = false;
    }

    if (checkedfreeval == "") {
      errmessage.checkedfreeValerr =
        "Prodcuts should be selected based on the limit";
      isValid = false;
    }

    setErrMessage(errmessage);
    return isValid;
  };

  const eventhandlechange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.name == "inputFieldtitle") {
      setInputFieldtitle(e.target.value);
      setLoading(false);
    }

    if (e.target.name == "inputlimitField") {
      if (e.target.value === "" || re.test(e.target.value)) {
        setInputlimitField(e.target.value);
        setLoading(false);
      }
    }
    if (e.target.name === "inputfreelimitField") {
      setInputfreelimitField(e.target.value);
    }
  };

  // Handle category checkbox change
  const handleCategoryChange = (e) => {
    const { name, checked } = e.target;

    setSelectedCategories((prevSelectedCategories) => {
      if (checked) {
        // Add the category if it's checked
        return [...prevSelectedCategories, name];
      } else {
        // Remove the category if it's unchecked
        return prevSelectedCategories.filter((cat) => cat !== name);
      }
    });
  };

  const handleFreeCategoryChange = (e) => {
    const { name, checked } = e.target;

    setSelectedFreeCategories((prevSelectedFreeCategories) => {
      if (checked) {
        // Add the category if it's checked
        return [...prevSelectedFreeCategories, name];
      } else {
        // Remove the category if it's unchecked
        return prevSelectedFreeCategories.filter((cat) => cat !== name);
      }
    });
  };
  const getFilteredProducts = () => {
    // If no category is selected, show all products
    //if (selectedCategories.length === 0) return products;

    const selectedCategoryIds = selectedCategories
      .map((category) => categoryMap[category])
      .flat(); // Flatten the array to merge all IDs into one array
    // Filter products based on the selected category IDs
    return products.filter((prd) =>
      selectedCategoryIds.includes(prd.categoryId)
    );
  };
  const getFilteredFreeProducts = () => {
    const selectedFreeCategoryIds = selectedFreeCategories
      .map((category) => categoryMap[category])
      .flat(); // Flatten the array to merge all IDs into one array
    // Filter products based on the selected category IDs
    return freeproducts.filter((prd) =>
      selectedFreeCategoryIds.includes(prd.categoryId)
    );
  };

  //  const filteredProducts = getFilteredProducts();
  //  const filteredFreeProducts = getFilteredFreeProducts();

  return (
    <section className="dashboard-block">
      {alertPopup && <CommonAlert title="Alert" message={alertMessage} />}

      <Sidebar />

      <div className="dash-left">
        <Header />

        <section className="cart-block">
          {!loading && (
            <div className="container">
              <div className="flex-title">
                <h1 className="checkout-title">Edit Free Products Setup</h1>
                <div className="back-btn"><button onClick={(e) => routeChange(e)}>Back</button></div>
              </div>
              
              <div className="right-table-outer-wrap">
                <form
                  method="post"
                  className="editform freeprod-forms"
                  onSubmit={submitButton}
                >
                  <div className="freeproduct-fieldset">
                    <div className="input-field">
                      <label>Title</label>
                      <input
                        type="text"
                        value={inputFieldtitle}
                        name="inputFieldtitle"
                        onChange={eventhandlechange}
                        className="form-control"
                        placeholder="Title"
                      />
                      <div style={{ color: "red" }}>
                        {errmessage.inputfieldtitleerr}
                      </div>
                    </div>

                    <div className="input-field ">
                      <label>Offer Products to be added </label>

                      <input
                        type="text"
                        value={inputlimitField}
                        name="inputlimitField"
                        onChange={eventhandlechange}
                        className="form-control"
                        placeholder="Products Limit"
                      />

                      <div style={{ color: "red" }}>
                        {errmessage.inputlimitfielderr}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-box">
                  <div
                      onClick={handleOfferclick}
                      className={
                        offerexpanded === true
                          ? "accordion-header show"
                          : "accordion-header"
                      }
                    >
                      <h2>Offer Products</h2>
                      <span className="show-accordion"></span>
                    </div>
                  <div
                      className="accordion-body"
                      style={{ display: offerexpanded === true ? "block" : "none" }}
                    >
                  <div className="custom_check-box">
                    <div className="edit-free-cat-disp">
                      <h4>Category</h4>
                      <div className="edit-free-cat-chks">
                        <div class="check-box">
                          <input
                            type="checkbox"
                            id="cat01"
                            name="pizza"
                            onChange={handleCategoryChange}
                            checked={selectedCategories.includes("pizza")}
                          />
                          <label for="cat01">Pizza</label>
                        </div>
                        <div class="check-box">
                          <input
                            type="checkbox"
                            id="cat02"
                            name="pasta"
                            onChange={handleCategoryChange}
                            checked={selectedCategories.includes("pasta")}
                          />
                          <label for="cat02">Pasta Rice & Noodle Sauces</label>
                        </div>
                        <div class="check-box">
                          <input
                            type="checkbox"
                            id="cat03"
                            name="burrito"
                            onChange={handleCategoryChange}
                            checked={selectedCategories.includes("burrito")}
                          />
                          <label for="cat03">Burrito</label>
                        </div>
                      </div>
                      {filteredProduct.length === 0 && (
                        <p className="color-red">
                          Please select a category to view products
                        </p>
                      )}
                      {filteredProduct.length > 0 && (
                        <h4>Offer Products to be added</h4>
                      )}
                      {filteredProduct.map((prd, index) => {
                        const isDisabled = prd.PDP_Status === "deactivate";
                        return (
                          <div className="check-box">
                            <input
                              type="checkbox"
                              id={`chkbox${index}`}
                              name="prd_chkbox"
                              value={prd.productNumber}
                              checked={checkedState[index]}
                              onChange={(e) => handleOnChange(index, e)}
                              //defaultChecked={checkedval.includes(prd.productNumber)}
                              disabled={isDisabled} // Use disabled for inactive products
                            />
                            <label for={`chkbox${index}`}>
                              {prd.productDescription}{" "}
                              {"(" + prd.productNumber + ")"}
                            </label>
                          </div>
                        );
                      })}

                      <div style={{ color: "red" }}>
                        {errmessage.checkedValerr}
                      </div>
                    </div>
                  </div>
                  </div>
                  </div>
                  <div className="accordion-box">
                    <div
                      onClick={handleclick}
                      className={
                        expanded === true
                          ? "accordion-header show"
                          : "accordion-header"
                      }
                    >
                      <h2>Free Products</h2>
                      <span className="show-accordion"></span>
                    </div>
                    <div
                      className="accordion-body"
                      style={{ display: expanded === true ? "block" : "none" }}
                    >
                      <div className="custom_check-box">
                        <div className="edit-free-cat-disp">
                          <h4>Category</h4>
                          <div className="edit-free-cat-chks">
                            <div class="check-box">
                              <input
                                type="checkbox"
                                id="fcat01"
                                name="pizza"
                                onChange={handleFreeCategoryChange}
                                checked={selectedFreeCategories.includes(
                                  "pizza"
                                )}
                              />
                              <label for="fcat01">Pizza</label>
                            </div>
                            <div class="check-box">
                              <input
                                type="checkbox"
                                id="fcat02"
                                name="pasta"
                                onChange={handleFreeCategoryChange}
                                checked={selectedFreeCategories.includes(
                                  "pasta"
                                )}
                              />
                              <label for="fcat02">
                                Pasta Rice & Noodle Sauces
                              </label>
                            </div>
                            <div class="check-box">
                              <input
                                type="checkbox"
                                id="fcat03"
                                name="burrito"
                                onChange={handleFreeCategoryChange}
                                checked={selectedFreeCategories.includes(
                                  "burrito"
                                )}
                              />
                              <label for="fcat03">Burrito</label>
                            </div>
                          </div>
                          {filteredFreeProduct.length === 0 && (
                            <p className="color-red">
                              Please select a category to view products
                            </p>
                          )}
                            {filteredFreeProduct.length > 0 && (
                             <h4>Free Products</h4>
                            )}
                          {filteredFreeProduct.map((prd, index) => {
                            const isDisabled = prd.PDP_Status === "deactivate";

                            return (
                              !checkedval.includes(prd.productNumber) && (
                                <div className="check-box">
                                  {loading && <Loader />}
                                  <input
                                    type="checkbox"
                                    id={`freechkbox${index}`}
                                    name="freeprd_chkbox"
                                    value={prd.productNumber}
                                    checked={checkedfreeState[index]}
                                    onChange={(e) => handleOnChange1(index, e)}
                                    disabled={isDisabled} // Use disabled for inactive products
                                  />
                                  <label for={`freechkbox${index}`}>
                                    {prd.productDescription}{" "}
                                    {"(" + prd.productNumber + ")"}{" "}
                                  </label>
                                </div>
                              )
                            );
                          })}
                          <div style={{ color: "red" }}>
                            {errmessage.checkedfreeValerr}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit">Submit</button> &nbsp;&nbsp;{" "}
                  <button onClick={(e) => routeChange(e)}>Cancel</button>
                </form>
              </div>
            </div>
          )}

          {loading && <Loader />}
        </section>
        <Footer />
      </div>
    </section>
  );
};
export default Editfreeproducts;
