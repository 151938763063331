import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import Loader from '../common/loader';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import { http } from '../helper';


const Editfreeproductspdf = () =>{
   
    ///exit();
    const [loading,setLoading] = useState(true);
    const [inputFieldtitle, setInputFieldtitle] = useState([]); 
    const [existfilename, setExistFilename] = useState([]); 
    const [existImgfilename, setExistImgFilename] = useState([]);
    const [filepath, setFilePath] = useState([]); 
    const [Imgfilepath, setImgFilePath] = useState([]);
    const [fileinput, setFileInput] = useState([]); 
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [visibleAlert,setVisibleAlert] = useState(false);
    const [visibleAlertPdf,setVisibleAlertPdf] = useState(false);
    const [visibleImageAlert,setVisibleImageAlert] = useState(false);
    const [visibleAlertImage,setVisibleAlertImage] = useState(false);
    const [deleteAlert,setDeleteAlert] = useState(false);
    const [deleteImageAlert,setDeleteImageAlert] = useState(false);
    const [isLoading,setIsLoading]= useState(false);
    const [bannerImage, setBannerImage] = useState(null); // Store the banner image
    const [isFeatured, setIsFeatured] = useState([]);  // For featured checkbox
    const [sortOrder, setSortOrder] = useState(1);  
    const [status, setStatus] = useState([]);   

    const params = useParams();
    
    const routeChange = () =>{ 
        window.location.href='/newproducts'
    }

 
    if(!sessionStorage.getItem("isLoggedIn")){
        window.location.href = '/';
    }
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_newproducts'))
        {
          window.location.href = '/unauthorized';
        }
    useEffect(()=>{
      
        let URL1 = BaseUrl + `admin/editpdf/${params.id}`;
        http.get(URL1).then( (response) => {
            let responseData = response.data.data;
            setLoading(false);
            setInputFieldtitle(responseData[0].PDFTitle);
            //setFileInput(responseData[0].PDFfilename);
            setExistFilename(responseData[0].PDFfilename);
            setExistImgFilename(responseData[0].BannerImageFilename);
            setFilePath(responseData[0].path);
            setImgFilePath(responseData[0].BannerImagePath);
            setIsFeatured(responseData[0].FeaturedPdf);
            setSortOrder(responseData[0].sortOrder);
            setStatus(responseData[0].Status);
        }).catch((err) => {
            console.log(err);
            
        }) 
        
 

    },[])

    const submitButton = (e) =>{
    
        e.preventDefault();
       
        const isValid = formvalidation();
        if(isValid)
        {
            handleUpload();
            // if(fileinput != '')
            // uploadFile(fileinput);
            // else
            // uploadWithoutFile();
        }
       
        
        
    }

      const handleFileUpload = (event) => {
        const file = event.target.files[0]; // Get the selected file
        if (event.target.id === 'pdf_file') {
          setFileInput(file);
          if (errmessage.fileinputerr) {
            setErrMessage(prevState => ({ ...prevState, fileinputerr: '' }));
          }
        } else if (event.target.id === 'banner_file') {
          setBannerImage(file);
          
          //setBannerImagePreview(URL.createObjectURL(file)); // Preview the uploaded image
          if (errmessage.bannerImageErr) {
            setErrMessage(prevState => ({ ...prevState, bannerImageErr: '' }));
          }
        }
      };
    
  const eventhandlechange = (e) => {

    if(e.target.name === 'inputFieldtitle'){
        setInputFieldtitle(e.target.value);
       
    }

    if(errmessage.inputFieldtitleerr != ''){
       errormsg.inptitletxt = false;
    }
    
    setErrormsg(errormsg);
   
  }

  const handleUpload=async()=>
  {
    setIsLoading(true);

    try {
        const formData = new FormData(); // Create a FormData object
        
        formData.append('title', inputFieldtitle.trim());  // PDF title
        formData.append('pid', params.id);  // ID of the PDF being edited
        formData.append('isFeatured', isFeatured);  // Featured checkbox value
        formData.append('sortOrder', sortOrder);    // Sort order input value
        formData.append('status', status);          // Status dropdown value

         // Check if a new PDF file is uploaded
         if (fileinput && fileinput instanceof File) {
            formData.append("pdf", fileinput);  // Append new PDF file if available

            // Check the file size
            let fileEle = document.getElementById('pdf_file');
            const fileSize = (fileEle.files[0].size / 1024 / 1024).toFixed(2); // File size in MB
            if (fileSize > 8) {
                setVisibleAlertPdf(true); // Show alert if file size exceeds 8MB
                setIsLoading(false);
                return false;
            }
        } else {
            // No new PDF uploaded, so use the existing file path
            formData.append('existingpath', filepath);
        }

         // Check if a new Banner Image file is uploaded
         if (bannerImage && bannerImage instanceof File) {
            formData.append("bannerImage", bannerImage);  // Append new bannerImage file if available

            // Check the file size
            let fileEle = document.getElementById('banner_file');
            const fileSize = (fileEle.files[0].size / 1024 / 1024).toFixed(2); // File size in MB
            if (fileSize > 2) {
                setVisibleAlertImage(true); // Show alert if file size exceeds 8MB
                setIsLoading(false);
                return false;
            }
        } else {
            // No new PDF uploaded, so use the existing file path
            formData.append('existingImgpath', Imgfilepath);
        }
        formData.append('deletealert', deleteAlert);
        formData.append('deleteImagealert', deleteImageAlert); 

        const response = await http.put(BaseUrl +'admin/editpdf', formData);
        if (response.data.success === true) {
         setIsLoading(false);
         setAlertPopup(true);
         setAlertMessage('New products updated successfully');  
         
       }
    }
    catch (error) {
        // Handle any errors that occur during the file upload
        setIsLoading(false);
        console.error('Error uploading file:', error);
    }
  }


  const hasExtension = (inputID, exts) => {
    var fileName = document.getElementById(inputID).value;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
}

    const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;

        if(inputFieldtitle == '' ){
            errmessage.inputFieldtitleerr = "This field is required";
            errmsg.inptitletxt = true;
            isValid = false;
        }
        
        if(existfilename === '' && document.getElementById("pdf_file").files.length == 0 ){
            errmessage.fileinputerr = "This field is required";
            errmsg.fileinptxt = true;
            isValid = false;
        }else if(existfilename === '' &&  document.getElementById("pdf_file").files.length > 0){
          if(!hasExtension('pdf_file', ['.pdf'])){
              errmessage.fileinputerr = "Only PDF files are allowed!!!";
              errmsg.fileinptxt = true;
              isValid = false;
          }

        }

       if(existImgfilename === '' &&  document.getElementById("banner_file").files.length > 0){
            if (!hasExtension('banner_file', ['.jpg', '.jpeg', '.png'])) {
                errmessage.bannerImageErr = "Only image files (.jpg, .jpeg, .png) are allowed";
                errmsg.bannerImageTxt = true;
                isValid = false;
            }
        }

        if (isValid) {
            setErrMessage({});
            setErrormsg({});
            return true;
          } else {
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            return false;
          }
        
    }
    const alertPopupclose = () =>{
        setVisibleAlert(false);
    }
    const alertPopupImgclose =()=>{
        setVisibleImageAlert(false);
    }
    const deletepdf = ()=>{
        setVisibleAlert(true);
    }
    const deleteImage = ()=>{
        setVisibleImageAlert(true);
    }
   
    const deleteFreepdf =() =>{
    setDeleteAlert(true);
    setExistFilename('');
    setVisibleAlert(false);
   
    }

    const deleteBanImage =() =>{
        setDeleteImageAlert(true);
        setBannerImage(null);
        setExistImgFilename('');
        setVisibleImageAlert(false);
       
    }
  
   const popupClose = (event) => {

        setVisibleAlertPdf(false);
        
}



return (<section className="dashboard-block">
   {alertPopup && ( 
   <div className='custom-alert-pop common_alert_popup' style={{display: "block"}}>
    <div className='custom-alert-container'>
            <div className='custom-alert-wrap'>
                <div className='custom-alert-header'>
                    <h4>Alert</h4>
                </div>
                <div className='custom-alert-body'>
                    <p>{alertMessage}</p>

                    <div className='alert-buttons'>
                        <a onClick={routeChange} className="popup-alert-btn button">OK</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    )}
    
 
        <Sidebar />

 
  <div className="dash-left">
   <Header/>
    <section className="cart-block">
    {!loading &&
    <div className="container">
    <h1 className="checkout-title">New Products</h1>

    
    {( isLoading ) ? (
                  <div className='loading-buffer'></div>
                ):(  
    
     <form method='post' className="addform editform freeprod-form freeproduct-fieldset form-container-xs" onSubmit={submitButton}>
    
       <div className="input-field col-1">
       <label>Title</label>
       <input type="text" value={inputFieldtitle} name="inputFieldtitle" onChange={eventhandlechange} className="form-control" placeholder="Title" /> 
       {errormsg.inptitletxt && <div style={{color:"red"}}>{errmessage.inputFieldtitleerr}</div>}
       </div>

       <div className="input-field col-1">
            <label>PDF</label>
            <span className="text-note"> (Maximum Upload File Size: 8 MB)</span>
            {existfilename  && 
            <div className='upld-file-pdf'>
                <div className='upld-pdf'>
                    <a href={BaseUrl+`${filepath}`} target='_blank' className='pdf-file'>
                        {/* {existfilename} */}
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </a>
                    <a className='title-tooltip title-up delete' data-title='Delete' onClick={deletepdf}><i className="fa fa-trash" aria-hidden="true"></i></a>  
                </div>
            </div> 
            }
            {existfilename === '' &&
            <input type="file" id="pdf_file" accept=".pdf" onChange={handleFileUpload} />
            }
              {errormsg.fileinptxt && <div className="error-msg">{errmessage.fileinputerr}</div>}

              
        </div>
            <div className="input-field col-1">
                    <label>Banner Image</label>
                    <span className="text-note"> (Maximum Upload File Size: 2 MB)</span>
                    {existImgfilename  && 
                        <div className='upld-img-file'>
                        
                            <div className='upld-img-show'>
                            <img src={BaseUrl+`${Imgfilepath}`} alt="Banner Preview"/>
                            <a className='title-tooltip title-up delete-img' data-title='Delete' onClick={deleteImage}><i className="fa fa-trash" aria-hidden="true"></i></a>  
                            </div>
                        </div>
                    }
                    {(existImgfilename === '' || existImgfilename === undefined || existImgfilename === null ) &&
                    <input type="file" id="banner_file" accept=".jpg,.jpeg,.png" onChange={handleFileUpload} />
                    }
                    
                    {errormsg.bannerImageTxt && <div className="error-msg">{errmessage.bannerImageErr}</div>}       
                        
                            
              </div>
              {/* Featured Checkbox */}
              <div className="input-field col-1">
                <label>Display on Main Page</label>
                <div className="check-box">
                <input type="checkbox" id='setImg' className='check-box' checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                <label for='setImg'>Yes</label>
                </div>

              </div>

              {/* Sorting Input */}
              <div className="input-field col-4">
                <label className='full-width'>Sort Order</label>
                <input type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} min="1" placeholder="Order (1 for top priority)" />
              </div>

                {/* Status Dropdown */}
              <div className="input-field col-1">
                <label className='full-width'>Status</label>
                <select className='col-4' value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>

              
        <button type='submit'>Submit</button> &nbsp;&nbsp;&nbsp;
        <button type="button" onClick={routeChange}>Cancel</button>
        </form>)}
        </div>

        }

       

       </section>
      <Footer/>
      </div>  
      {loading && <Loader />}
            {visibleAlert && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete PDF?</p>

                                <div className='alert-buttons'>

                                    <a onClick={deleteFreepdf.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}    


      {visibleAlertPdf === true &&
       <div className='custom-alert-pop' style={{display: "block"}}>
        <div className='custom-alert-container'>
            <div className='custom-alert-wrap'>
                <div className='custom-alert-header'>
                    <h4>Alert</h4>
                </div>
                <div className='custom-alert-body'>
                    <p>File size must be less than 8 MB.</p>

                    <div className='alert-buttons'>
                        <a onClick={popupClose} className="popup-alert-btn button">OK</a>
                    </div>
                </div>
            </div>
        </div>
       </div>}

       {visibleImageAlert && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete Banner Image?</p>

                                <div className='alert-buttons'>

                                    <a onClick={deleteBanImage.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={alertPopupImgclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}    


      {visibleAlertImage === true &&
       <div className='custom-alert-pop' style={{display: "block"}}>
        <div className='custom-alert-container'>
            <div className='custom-alert-wrap'>
                <div className='custom-alert-header'>
                    <h4>Alert</h4>
                </div>
                <div className='custom-alert-body'>
                    <p>File size must be less than 2 MB.</p>

                    <div className='alert-buttons'>
                        <a onClick={popupClose} className="popup-alert-btn button">OK</a>
                    </div>
                </div>
            </div>
        </div>
       </div>}      
    </section>

   );

}
export default Editfreeproductspdf;