import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Authenticated } from "./components/helper";
import "./components/assets/css/style.css";
import "./components/assets/css/responsive.css"
import reportWebVitals from './reportWebVitals';
import "./components/assets/css/font-awesome.min.css";


import Login from './components/login';
import Dashboard from './components/dashboard';
import Products from './components/products';
import Orders from './components/orders';
import Vendors from './components/vendors';
import Account from './components/account';
import Settings from './components/settings';
import Freeproducts from './components/freeproducts';
import AddFreeproducts from './components/freeproducts/addfreeproducts';
import EditFreeproducts from './components/freeproducts/editfreeproducts';
import Holiday from './components/holiday/';
import HolidayAdd from './components/holiday/holidayadd';
import HolidayEdit from './components/holiday/holidayedit';
import ForgotPassword from './components/account/Forgotpassword';
import Sociallinks from './components/settings/social-links';
import Stripepaymentkey from './components/settings/stripe-paymentkey';
import Changepassword from './components/settings/changepassword';
/*import Accountdetail from './components/account/accountdetail';*/
import SiteSettings from './components/settings/site-settings';
import Allergens from './components/allergens';
import AllergensAdd from './components/allergens/addallergens';
import AllergensEdit from './components/allergens/editallergens';
import Ingredients from './components/ingredients';
import IngredientsAdd from './components/ingredients/addingredient';
import IngredientsEdit from './components/ingredients/editingredient';
import Freeproductspdf from './components/freeproductspdf';
import AddFreeproductspdf from './components/freeproductspdf/addfreeproductspdf';
import EditFreeproductspdf from './components/freeproductspdf/editfreeproductspdf';
import ResetPassword from './components/account/ResetPassword';
import Stripepaymentlog from './components/settings/stripe-paymentlog';
import MasterEntry from './components/master-entry';
import MasterEntryAdd from './components/master-entry/addmaster';
import MasterEntryEdit from './components/master-entry/editmaster';
import PdfViewer from './components/products/pdfViewer';
import AddRoles from './components/role/addRoles';
import Roles from './components/role';
import EditRoles from './components/role/editRoles';
import AddUser from './components/account/addUser';
import Unauthorized from './components/common/Unauthorized';
import NotificationSettings from './components/settings/notification-setting';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route exact path="/resetpassword/:token/:userid" element={<ResetPassword />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="/" element={<Authenticated />} >

          <Route path="products" element={<Products />} />

          <Route path="orders" element={<Orders />} />
          <Route path="vendors" element={<Vendors />} />
          <Route path="account" element={<Account />} />
          <Route path="settings" element={<Settings />} />
          <Route path="freeproducts" element={<Freeproducts />} />
          <Route path="freeproducts/addfreeproducts" element={<AddFreeproducts />} />
          <Route path="freeproducts/editfreeproducts/:id" element={<EditFreeproducts />} />
          <Route path="holiday" element={<Holiday />} />
          <Route path="holiday/add" element={<HolidayAdd />} />
          <Route path="holiday/edit/:id" element={<HolidayEdit />} />

          <Route path="changepassword" element={<Changepassword />} />
          <Route path="social-links" element={<Sociallinks />} />
          <Route path="stripe-paymentkey" element={<Stripepaymentkey />} />
          <Route path="stripe-paymentlog" element={<Stripepaymentlog />} />
          <Route path="site-settings" element={<SiteSettings />} />
          <Route exact path="master-entry/:name" element={<MasterEntry />} />
          <Route path="master-entry/add/:name" element={<MasterEntryAdd />} />
          <Route path="master-entry/edit/:name/:id/" element={<MasterEntryEdit />} />
          <Route path="allergens" element={<Allergens />} />
          <Route path="allergens/add" element={<AllergensAdd />} />
          <Route path="allergens/edit/:id/" element={<AllergensEdit />} />
          <Route path="ingredients" element={<Ingredients />} />
          <Route path="ingredients/add" element={<IngredientsAdd />} />
          <Route path="ingredients/edit/:id/" element={<IngredientsEdit />} />
          <Route path="newproducts" element={<Freeproductspdf />} />
          <Route path="newproducts/add" element={<AddFreeproductspdf />} />
          <Route path="newproducts/edit/:id/" element={<EditFreeproductspdf />} />

          <Route path="PdfViewer" element={<PdfViewer />} />
          <Route path="role" element={<Roles />} />
          <Route path="role/addRoles" element={<AddRoles />} />
          <Route path="role/editRoles/:id/" element={<EditRoles />} />
          <Route path="account/adduser" element={<AddUser />} />
          <Route path='notification' element={<NotificationSettings/>} />
          <Route path='unauthorized' element={<Unauthorized />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
