import React, { Component } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer'; 
import DataTable from 'react-data-table-component';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar'; 
import Popup from '../common/popup';
import { http } from '../helper';
 



class Ingredients extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            searchTextVal:"",
            ingredientslist: [],
            templist :[],
            loading: true,
            popupView: "",
            ingredientsvalue : "",
            visibleAlert:false,
        };

        if(!sessionStorage.getItem("isLoggedIn")){
            window.location.href = '/';
        }
        if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
            {
              window.location.href = '/unauthorized';
            }

     }

    getIngredientslist() {
          var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }

        let URL = BaseUrl + "admin/ingredientlist";
        http.get(URL).then((response) => {
            const sortedData = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({ ingredientslist: sortedData, loading: false, templist:response.data.data});
        }).catch((err) => {
            console.log(err);
        })
    }

    viewIngredientsDetails(ingredients) {
        let popupResult = <Popup
            extClass="order-pop pop-container-sm"
            headMoreTxt={
                <>
                    <div className="order-top">
                        <div className="head">
                            <h4>Ingredients Details</h4>
                        </div>
                    </div>
                </>
            }
            bodyMoreTxt={
                <>
                    <div className="order-top">
                        
                        <div className="bottom vendor-details ingredientsdetails">
                            <p><span className="labeltxt">Title</span>: {ingredients.ingredientTitle}</p>
                           
                            {/* <p><span className="labeltxt">Status</span> - {holiday.holidayStatus}</p> */}
                            
                        </div>
                    </div>
                </>
            }
            closebtn={<><span onClick={() => this.setState({ popupView: "" })} className='close'></span></>}

           
        />;

        this.setState({ popupView: popupResult });

    }

    editIngredients=(ingredient)=>
    {
        window.location.href = `/ingredients/edit/${ingredient._id}`;
    }

    deleteIngredients = (value) =>
    {
        this.setState({visibleAlert:true,ingredientsvalue:value});
        
    }

    deleteIngredientsvalue = () =>
     {

        var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }


        let URL = BaseUrl + `admin/ingredient/${this.state.ingredientsvalue._id}`;  
        http.delete(URL).then(async (response) => {
                
                if(response.data.success === true){

                    window.location.reload();
                }

            
            }).catch((err) => {
                console.log(err);
                
            })  
        
    }
    alertPopupclose = () =>
    {
        this.setState({visibleAlert:false,ingredientsvalue:''});
    }

    componentDidMount() {
        this.getIngredientslist();
    }

    addIngredients = () =>
    {
        window.location.href = '/ingredients/add/';
    }

    handleReset=()=>{
        this.setState({searchTextVal:'',ingredientslist:this.state.templist});
     };

    handleChange = async(value) => {
        this.setState({searchTextVal:value});
        this.filterData(value);
      };
           

      filterData = async(value) => {

        let accoun = this.state.templist;
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.ingredientTitle.toString().toLowerCase().includes(lowercasedValue))
            {
              result.push(item);
            }
          })
          this.setState({ingredientslist:result});
        }else {
        this.setState({ingredientslist:accoun});
      }
      };


    render() {

        const columns = [
            {
                name: "Title",
                selector: row => row.ingredientTitle,
                sortable: true,
            },
           {
                name: "Actions",
                selector: row => <div style={{display:"flex"}}>
                    {/* <a className='button title-tooltip title-up' data-title='View Details' style={{color: "white"}} onClick={this.viewIngredientsDetails.bind(this, row)}><i class="fa fa-eye" aria-hidden="true"></i></a> */}
                    <a className='button title-tooltip title-up' data-title='Edit' style={{color: "white"}} onClick={this.editIngredients.bind(this, row)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                    <a className='button title-tooltip title-up' data-title='Delete' style={{color: "white"}} onClick={this.deleteIngredients.bind(this, row)}><i className="fa fa-trash" aria-hidden="true"></i></a>
                    {/* <button style={{color: "white"}} onClick={this.viewIngredientsDetails.bind(this, row)}>View Details</button> */}
                      {/* <button style={{color: "white"}} onClick={this.deleteHoliday.bind(this, row)}>DELETE</button> */}
                  
                    </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells
                    
                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells
                    
                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage:'',
        };

        return (
            <>
                <div className="dashboard-block">

                        <Sidebar />
                    
                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                        <div className="container">
                            <h1 className="checkout-title">Ingredients</h1>  

                            <div className="right-table-outer-wrap">
                            <div className="table-filter search-orders">
                              <div className='table-search-reset'>
                                <div className="table-search">
                                    <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Title" />
                                    
                                </div>
                                <button className='submitbtn button search-ord-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i className="fa fa-search"></i></button>
                                 
                                <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                              </div>
                             
                              <div className='add-ingredientbtn'>
                                <button className='addbtn' onClick={this.addIngredients}>Add</button>
                              </div>
                            </div>
                            
                            <div className="table_overflow">
                            {this.state.popupView}
                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, there is no ingredient list matching your keyword/s, try a new search.</div>}
                                        data={this.state.ingredientslist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                    />
                                    
                                    </div>
                                   
                    
                            </div>
                        </div>
                        </section>
                        <Footer />
                        
                    </div>
                </div>
                {this.state.visibleAlert === true && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete?</p>

                                <div className='alert-buttons'>

                                    <a onClick={this.deleteIngredientsvalue.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={this.alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
               
            </>
        );
    }
}

export default Ingredients;