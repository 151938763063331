import React, { Component } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
 
 
import DataTable from 'react-data-table-component';

import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
 
import Popup from '../common/popup';
import { http } from '../helper';



class Allergens extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            searchTextVal:"",
            allergenslist: [],
            templist :[],
            loading: true,
            popupView: "",
            allergensvalue:"",
            visibleAlert:false,
        };

        if(!sessionStorage.getItem("isLoggedIn")){
            window.location.href = '/';
        }
        if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
            {
              window.location.href = '/unauthorized';
            }

     }

    getAllergenslist() {
        let URL = BaseUrl + "admin/allergenlist";

        var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }
  
        http.get(URL).then((response) => {
            const sortedData = response.data.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            this.setState({ allergenslist: sortedData, loading: false, templist:response.data.data});
        }).catch((err) => {
            console.log(err);
        })
    }

    viewAllergensDetails(allergen) {
        let popupResult = <Popup
        extClass="order-pop pop-container-sm"
        headMoreTxt={
            <>
                <div className="order-top">
                    <div className="head">
                        <h4>Allergens Details</h4>
                    </div>
                </div>
            </>
        }
        bodyMoreTxt={
            <>
                <div className="order-top">
                    
                    <div className="bottom vendor-details ingredientsdetails">
                        <p><span className="labeltxt">Title</span>: {allergen.allergenTitle}</p>
                       
                        {/* <p><span className="labeltxt">Status</span> - {holiday.holidayStatus}</p> */}
                        
                    </div>
                </div>
            </>
        }
        closebtn={<><span onClick={() => this.setState({ popupView: "" })} className='close'></span></>}

       
    />;

        this.setState({ popupView: popupResult });

    }

    editAllergens=(allergens)=>
    {
        window.location.href = `/allergens/edit/${allergens._id}`;
    }

    deleteAllergens = (value) =>
    {
        this.setState({visibleAlert:true,allergensvalue:value});
        
    }

    deleteAllergensValue = () =>
    {

         var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
          }
  
            let URL = BaseUrl + `admin/allergen/${this.state.allergensvalue._id}`;  
            http.delete(URL).then(async (response) => {
                if(response.data.success === true){

                    window.location.reload();
                }

            
            }).catch((err) => {
                console.log(err);
                
            })  
        
    }
    alertPopupclose = () =>
    {
        this.setState({visibleAlert:false,allergensvalue:''});
    }

    componentDidMount() {
        this.getAllergenslist();
    }

    addAllergen = () =>
    {
        window.location.href = '/allergens/add/';
    }

    handleReset=()=>{
        this.setState({searchTextVal:'',allergenslist:this.state.templist});
     };

    handleChange = async(value) => {
        this.setState({searchTextVal:value});
        this.filterData(value);
      };
           

      filterData = async(value) => {

        let accoun = this.state.templist;
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length>0){
            accoun.map((item)=>{
            if(item.allergenTitle.toString().toLowerCase().includes(lowercasedValue))
            {
              result.push(item);
            }
          })
          this.setState({allergenslist:result});
        }else {
        this.setState({allergenslist:accoun});
      }
      };


    render() {

        const columns = [
            {
                name: "Title",
                selector: row => row.allergenTitle,
                sortable: true,
            },
           {
                name: "Actions",
                selector: row => <div style={{display:"flex"}}>
                    {/* <a className='button title-tooltip title-up' data-title='View Details' style={{color: "white"}} onClick={this.viewAllergensDetails.bind(this, row)}><i class="fa fa-eye" aria-hidden="true"></i></a> */}
                    <a className='button title-tooltip title-up' data-title='Edit' style={{color: "white"}} onClick={this.editAllergens.bind(this, row)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                    <a className='button title-tooltip title-up' data-title='Delete' style={{color: "white"}} onClick={this.deleteAllergens.bind(this, row)}><i className="fa fa-trash" aria-hidden="true"></i></a>
                    {/* <button style={{color: "white"}} onClick={this.viewAllergensDetails.bind(this, row)}>View Details</button> */}
                      {/* <button style={{color: "white"}} onClick={this.deleteHoliday.bind(this, row)}>DELETE</button> */}
                  
                    </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells
                    
                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells
                    
                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage:'',
        };

        return (
            <>
                <div className="dashboard-block">

                     <Sidebar />
                   
                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                        <div className="container">
                            <h1 className="checkout-title">Allergens</h1>  

                            <div className="right-table-outer-wrap adm-allergens">
                            <div className="table-filter search-orders">
                              <div className='table-search-reset'>
                                <div className="table-search">
                                    <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Title" />
                                    
                                </div>
                                <button className='submitbtn button search-ord-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i className="fa fa-search"></i></button>
                                 
                                <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                              </div>
                             
                              <div className='add-allergenbtn'>
                                <button className='addbtn' onClick={this.addAllergen}>Add</button>
                              </div>
                            </div>
                            
                            <div className="table_overflow">
                            {this.state.popupView}
                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, there is no allergen listing matching your keyword/s, please try a new search.</div>}
                                        data={this.state.allergenslist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions }
                                    />
                                    
                                    </div>
                                   
                    
                            </div>
                        </div>
                        </section>
                        <Footer />
                        
                    </div>
                </div>
                {this.state.visibleAlert === true && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to delete?</p>

                                <div className='alert-buttons'>

                                    <a onClick={this.deleteAllergensValue.bind(this)} className="popup-alert-btn button" >OK</a>
                                    <a onClick={this.alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        );
    }
}

export default Allergens;