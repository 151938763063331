import React, { Component, useState, useEffect } from 'react'
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import ShowAlert from '../common/Showalert';
import { useParams } from 'react-router-dom';
const MasterEntryAdd = () => {

    const { name } = useParams();
    const [MasterValue, setMasterValue] = useState([]);
    const [MasterCategory, setMasterCategory] = useState([]);
    const [datalist, setDataList] = useState([]);
    const [errormsg,setErrormsg] = useState({});
    const [errors, setErrors] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
    const [alertMessage,setAlertMessage] = useState([]);
    
    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';
   
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_masterentry'))
        {
          window.location.href = '/unauthorized';
        }
    const routeChange = () =>{ 
        window.location.href=`/master-entry/${name}`;
    }

    useEffect(()=>{
          var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }
        setMasterCategory((name === 'productgroup')?'Product Group':(name === 'suitablefor')?'Suitable For':'Tax Code');
        let URL = BaseUrl + `admin/product/masterentries/${name}`;
        http.get(URL).then((response) => {
            let responseData = response.data.data;
                     
            setDataList(responseData);
        }).catch((err) => {
            console.log(err);
        })


    },[])
    const btnsubmit = (e) =>
    {
        e.preventDefault();
        
        const isValid = formvalidation();
 
      
          
       if(isValid)
       {
        let mastervalue = MasterValue.trim();
        let payload = {
            MasterCategory: name,
            MasterValue   : mastervalue,

         }
         
         const isFound = datalist.some(element => {
            if (element.MasterValue.toLowerCase() === mastervalue.toLowerCase()) {
              return true;
            }
        
            return false;
          });
        if(isFound)
        {
            setAlertErrorPopup(true);
            setAlerterrMessage(`This data already exists! Please enter an unique value.`); 

        }
        else
        {
         var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }
        let URL = BaseUrl + "admin/product/addmaster/";
        http.post(URL, payload).then(async (response) => {
               
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage(`Value added successfully.`); 
                }
            }).catch((err) => {
            console.log(err);
            
            })
        }
       }
        
    }

    const formvalidation = () =>
    {
        const errmsg = {};
        let isValid = true;

        if (!MasterValue.trim()) {
            errmsg.MasterValue = "Master Value is required";
            isValid = false;
        }

        setErrors(errmsg);
        return isValid;
    }

  const handleOnChange = (e) =>{
    const re = /^[a-zA-Z0-9- ]*$/;

    if(e.target.name ==="MasterValue")
    {
        if (e.target.value === '' || re.test(e.target.value)) {
            setMasterValue(e.target.value);
        }

    }
    const { name, value } = e.target;

    setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
    }));
  }
  return (
    
    <section className="dashboard-block">
        { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    <Sidebar />
   
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container account-details">

         <div className="right-table-outer-wrap">
            <h1 className="checkout-title">Add {MasterCategory}</h1>
            <div className='form-field freeproduct-fieldset masterentry-fieldset form-container-xs'>
                        <form className='client-detail-form'>
                            <div className='form-field'>
                                <label for='name'>{MasterCategory}</label>
                                <input type='text' name='MasterValue' value={MasterValue} onChange={handleOnChange} />
                                {errors.MasterValue && <div style={{color:"red"}}>{errors.MasterValue}</div>}

                            </div>
                           
                            <div className='btnfield'>
                            <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" onClick={routeChange}>Cancel</button>
                            </div>
                           
                            
                        </form>
                    </div>
           </div>
         </div>
         <Footer/> 
        </section>
       
            </div>
          
        </section>
  )
}

export default MasterEntryAdd;