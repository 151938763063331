import React, { Component } from 'react';

class Showalert extends Component {

    constructor(props){
        super(props)
        this.state = {
        }
    } 


	popupClose(){

	   var elems = document.getElementsByClassName('custom-alert-pop');
        
        for (var i=0;i<elems.length;i+=1){
          elems[i].style.display = 'none';
        }

        // var reload = localStorage.getItem('paynow_order_alert');
        // if(reload == 'Yes'){
        //   localStorage.removeItem('paynow_order_alert');
        //   window.location.reload();
        // }

         window.location.reload();
	}

    render() {
         var elems = document.getElementsByClassName('custom-alert-pop');
            for (var i=0;i<elems.length;i+=1){
              elems[i].style.display = 'block';
            }
        return( <> {<div className='custom-alert-pop' style={{display: "block"}}>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>{this.props.title}</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>{this.props.message}</p>

                                <div className='alert-buttons'>
                                    <a onClick={this.popupClose.bind(this)} className="popup-alert-btn button">OK</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> }

       </> );

    }

}

export default Showalert;