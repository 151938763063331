import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';
import { http } from '../helper';

const Sociallinks = () =>{

    const [youtubelink, setYoutubeLink] = useState([]);
    const [twitterlink, setTwitterLink] = useState([]);
    const [linkedinlink, setLinkedinLink] = useState([]);
    const [instagramlink, setInstagramLink] = useState([]);
    const [facebooklink, setFacebookLink] = useState([]);
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);

    if(!sessionStorage.getItem("isLoggedIn")){
        window.location.href = '/';
    }

    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
        {
          window.location.href = '/unauthorized';
        }

    
    useEffect(()=>{

        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }

        let URL = BaseUrl + "admin/settings";
        http.get(URL).then((response) => {
            let responseData = response.data.data;
            if(response.data.success === true)
            {
                setYoutubeLink(responseData[0].youtubeURL);
                setTwitterLink(responseData[0].twitterURL);
                setLinkedinLink(responseData[0].linkedinURL);
                setFacebookLink(responseData[0].facebookURL);
                setInstagramLink(responseData[0].instagramURL);
                setAlertPopup(false); 
            }
                    
        }).catch((err) => {
            console.log(err);
            
        })  
    },[])

    const btnsubmit = (e) =>
    {
        e.preventDefault();
         
        setAlertPopup(false);
        
          let payload = {
              twitterURL   : twitterlink.trim(),
              facebookURL  : facebooklink.trim(),
              youtubeURL   : youtubelink.trim(),
              instagramURL : instagramlink.trim(),
              linkedinURL  : linkedinlink.trim(),
           }

            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
                loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            }

           let URL = BaseUrl + "admin/settings";
           http.post(URL, payload).then(async (response) => {
                 let responseData = response.data.data[0];
                 if (response.data.success === true) {
                    setYoutubeLink(responseData.youtubeURL);
                    setTwitterLink(responseData.twitterURL);
                    setLinkedinLink(responseData.linkedinURL);
                    setFacebookLink(responseData.facebookURL);
                    setInstagramLink(responseData.instagramURL);
                    setAlertPopup(true);
                    setAlertMessage('Social links have been updated successfully'); 
                    //window.location = '/social-links';
                   }
            }).catch((err) => {
               console.log(err);
               
            })
        //}
    }

    const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        
        if(youtubelink == '' )
        {
            errmessage.youtubelinkerr = "Youtube link is Required";
            errmsg.ytube = true;
            isValid = false;
        }
       
        if(twitterlink == '' )
        {
            errmessage.twitterlinkerr = "Twitter link is Required";
            errmsg.twitt = true;
            isValid = false;
        }
        if(linkedinlink == '' )
        {
            errmessage.linkedinlinkerr = "Linked In link is Required";
            errmsg.linked = true;
            isValid = false;
        }
        if(instagramlink == '' )
        {
            errmessage.instagramlinkerr = "Instagram link is Required";
            errmsg.insta = true;
            isValid = false;
        }
        if(facebooklink == '' )
        {
            errmessage.facebooklinkerr = "Facebook link is Required";
            errmsg.fbook = true;
            isValid = false;
        }

        if(isValid)
        {
            errmessage.youtubelinkerr = '';
            errmessage.twitterlinkerr = '';
            errmessage.linkedinlinkerr='';
            errmessage.instagramlinkerr = '';
            errmessage.facebooklinkerr='';
            errmsg.ytube = false;
            errmsg.twitt = false;
            errmsg.linked = false;
            errmsg.insta = false;
            errmsg.fbook = false;
            setErrMessage(errmessage);
            return isValid;
        }
        else{
            
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            
            return isValid;
        }

       
        
    }

  const handleOnChange = (e) =>{
    if(e.target.name ==="youtubelink")
    {
        setYoutubeLink(e.target.value);
        if(errmessage.youtubelinkerr != '')
        {
           errormsg.ytube = false;
        }
        
        setErrormsg(errormsg);
    }
    if(e.target.name ==="twitterlink")
    {
      
        setTwitterLink(e.target.value);
        if(errmessage.twitterlinkerr != '')
        {
           errormsg.twitt = false;
        }
        
        setErrormsg(errormsg);
    }
    if(e.target.name ==="linkedinlink")
    {
        setLinkedinLink(e.target.value);
        if(errmessage.linkedinlinkerr != '')
        {
           errormsg.linked = false;
        }
        
        setErrormsg(errormsg);
    }
    if(e.target.name ==="instagramlink")
    {
       setInstagramLink(e.target.value);
       if(errmessage.instagramlinkerr != '')
       {
          errormsg.insta = false;
       }
       
       setErrormsg(errormsg);
    }
    if(e.target.name ==="facebooklink")
    {
       setFacebookLink(e.target.value);
       if(errmessage.facebooklinkerr != '')
       {
          errormsg.fbook = false;
       }
       
       setErrormsg(errormsg);
    }
    setAlertPopup(false);
  }

    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
   
            <Sidebar />
    
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container">
            <h1 className="checkout-title">Social Links</h1>
            
            <div className="right-table-outer-wrap">
                <div className="form-field freeproduct-fieldset social-links-fieldset form-container-xs">
                    
                <div className='input-field col-1'> 
                    <label for="youtube">Youtube</label>
                    <input type='text' name="youtubelink" value={youtubelink} onChange={handleOnChange}/>
                    {errormsg.ytube && <div style={{color:"red"}}>{errmessage.youtubelinkerr}</div> }
                </div>
                <div className='input-field col-1'> 
                    <label for="twitter">Twitter</label> 
                    <input type='text' name="twitterlink" value={twitterlink} onChange={handleOnChange}/>
                    {errormsg.twitt && <div style={{color:"red"}}>{errmessage.twitterlinkerr}</div> }
                </div>
                <div className='input-field col-1'>     
                    <label for="linkedin">LinkedIn</label> 
                    <input type='text' name="linkedinlink" value={linkedinlink} onChange={handleOnChange}/>
                    {errormsg.linked && <div style={{color:"red"}}>{errmessage.linkedinlinkerr}</div> }
                </div>

                <div className='input-field col-1'> 
                    <label for="instagram">Instagram</label> 
                    <input type='text' name="instagramlink" value={instagramlink} onChange={handleOnChange}/>
                    {errormsg.insta && <div style={{color:"red"}}>{errmessage.instagramlinkerr}</div> }
                </div>
                <div className='input-field col-1'>  
                   <label for="facebook">Facebook</label> 
                    <input type='text' name="facebooklink" value={facebooklink} onChange={handleOnChange}/>
                    {errormsg.fbook && <div style={{color:"red"}}>{errmessage.facebooklinkerr}</div> }
                </div>
                   <div className='btnfield'>
                    <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                    </div>
          
               
            </div>
           </div>
         </div>

        </section>
        <Footer/>
          
            </div>
           
        </section>);

}
export default Sociallinks;