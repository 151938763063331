import moment from 'moment-timezone';

export const dateformatwithuktime = (datestring)=>
{
    const defaultTimezone = moment.tz.guess(true);
    var b = moment.tz(datestring, defaultTimezone);
    return b.format('DD/MM/YYYY HH:mm:ss');

};

export const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string
    .split(/(\s|-)/)  // Split by space or hyphen, keeping the delimiter
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())  // Capitalize each word
    .join('');  // Join back the words including the delimiters
  };