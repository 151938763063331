import React, { useEffect, useState } from 'react';
import { http } from '../helper';

const PdfViewer = () => {
    const [loading, setLoading] = useState(true);
    const [pdfDataUrl, setPdfDataUrl] = useState('');
    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';

    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_products'))
        {
          window.location.href = '/unauthorized';
        }
    useEffect(() => {
        const pdfUrl = sessionStorage.getItem('pdfUrl');
        const authtoken = sessionStorage.getItem('token');
     

        const fetchPdf = async () => {
            try {
                const response = await http.get(pdfUrl, {
                    responseType: 'arraybuffer'                     
                });
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const dataUrl = window.URL.createObjectURL(blob);
                setPdfDataUrl(dataUrl);
                setLoading(false); 
                
                
            } catch (error) {
                console.error('Error fetching PDF:', error);
                
                if (error.response) {   
                    if(error.response.status === 401){
                        sessionStorage.clear();
                        window.location.href='/';
                    }
                }
                setLoading(false);
            }
        };

        fetchPdf();
    },[]);

    
   
    if (loading) {
        return <div className="loading">Loading PDF...</div>; // Show loading message
    } 
    return (
        <div className='pdf-container'  style={{ width: '100%', height: '100vh' }}>
       
     
<iframe
        src={pdfDataUrl}
        title="PDF Viewer"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
      />
            
            
        </div>    );
};

export default PdfViewer;
