import React, { useState,useEffect} from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer'; 
import { http } from '../helper';
import ShowAlert from '../common/Showalert';
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import { menuPermissions } from '../account/menupermissions';

const AddRoles=() => {
    const [isLoading, setIsLoading] = useState(true);
    const [datalist, setDataList] = useState([]);
    const [roleName,setRoleName] = useState('');
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [errors, setErrors] = useState({});
    const [alertMessage,setAlertMessage] = useState([]);
    const [alertPopup,setAlertPopup] = useState(false);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
       
  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
  }

  useEffect(()=>{
    fetchData(); 
},[])

  const fetchData = async () => {
    setIsLoading(true);
    try {
    var loginDetails = {};
    if (sessionStorage.getItem('authData')) {
        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
    }       
let URL = BaseUrl + `admin/getRoles`;
const response = await http.get(URL)
    let responseData = response.data.data;
    if(response.data.success === true)
    {
        setDataList(responseData);
        
    }
            
 }catch(err) {
    console.log(err);
    
 }finally{
    setIsLoading(false);
 }
};
    const handlePermissionChange = (e) => {
        const { value, checked } = e.target;
        setSelectedPermissions((prevPermissions) =>
          checked ? [...prevPermissions, value] : prevPermissions.filter((perm) => perm !== value)
        );
          // Clear error message for permissions
    setErrors((prevErrors) => ({
        ...prevErrors,
        permissions: '',
      }));
      };
      const routeChange = () =>{ 
        window.location.href=`/role/`;
    }

    const formvalidation = () =>
    {        
        const newErrors = {};
        let isValid = true;

        if (roleName.trim() === '') {
            newErrors.roleName = "Role Name is Required";
            isValid = false;
        }
        if (selectedPermissions.length === 0) {
            newErrors.permissions = "At least one permission must be selected";
            isValid = false;
        }


        setErrors(newErrors);
        return isValid;
    
        
    }
    const btnsubmit=(e)=>
    {
        e.preventDefault();
        const isValid = formvalidation();
        if(isValid)
       {
          let payload = {
            roleName: roleName,
            permissions  : selectedPermissions ,

         }
         
         const isFound = datalist.some(element => {
            if (element.roleName.toLowerCase() === roleName.toLowerCase()) {
              return true;
            }
        
            return false;
          });
        if(isFound)
        {
            setAlertErrorPopup(true);
            setAlerterrMessage(`This Role Name has already been added`); 

        }
        else
        {
         var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }
        let URL = BaseUrl + "admin/addRole/";
        http.post(URL, payload).then(async (response) => {
                let responseData = response.data.data;
                console.log(response.data.success,responseData.roleName);
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage(`Role and Permissions have been added successfully!`); 
                }
            }).catch((err) => {
            console.log(err);
            
            })
        }
       }
        
    }
   
    return (
    
        <section className="dashboard-block">
            { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
    {alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
       
                <Sidebar />
        
        <div className="dash-left">
        <Header/>
            <section className="cart-block">
             <div className="container account-details">
                <h1 className="checkout-title">Add Role</h1>

                <div className="right-table-outer-wrap">
                <div className='form-field freeproduct-fieldset addroles-fieldset form-container-xs'>
                            <form className='client-detail-form'>
                                <div className='input-field'>
                                    <label for='name'>Role Name</label>
                                    <input type='text' name='roleName' value={roleName} onChange={(e) => {setRoleName(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        roleName: '',
                      }));
                    }} />
                                    {errors.roleName && <div style={{color:"red"}}>{errors.roleName}</div>}
    
                                </div>
                                <div className='input-field'>
                                    <label for='name'>Permissions</label>
                                    <div className='custom_check-box'>
                                    {menuPermissions.map((permission,index) => (
                                     <div className='check-box'>
                                    <input 
                                        id={`chkbox${index}`}
                                        type="checkbox"
                                        value={permission.value}
                                        checked={selectedPermissions.includes(permission.value)}
                                        onChange={handlePermissionChange}
                                    />
                                    <label for={`chkbox${index}`}>{permission.name}</label>
                                     </div>
                                ))}
                                </div>
                                {errors.permissions && <p style={{ color: 'red' }}>{errors.permissions}</p>}
                                </div>
                               
                                <div className='btnfield'>
                                <button type="submit" name="btnsubmit" onClick={btnsubmit}>Add Role</button>
                                &nbsp;&nbsp;&nbsp;
                                <button type="button" onClick={routeChange}>Cancel</button>
                                </div>
                               
                                
                            </form>
                        </div>
               </div>
             </div>
             <Footer/> 
            </section>
           
                </div>
              
            </section>
      )
}

export default AddRoles;
