import React, { useState, useEffect,useRef } from 'react'
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
 
import { http } from "../helper";
 
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import ShowAlert from '../common/Showalert';
//import { menuPermissions } from './menupermissions';

const AddUser = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [adminName, setAdminName] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    const [adminPassword, setAdminPassword] = useState('');
    const [admincPassword, setAdmincPassword] = useState('');
    const [showadminPassword, setShowAdminPassword] = useState(false);  
    const [showadmincPassword, setShowAdmincPassword] = useState(false);  
    const [adminRoles,setAdminRoles] = useState({});
    const [roles,setRoles]=useState([]);
    const [users,setUsers]=useState([]);
    const [errors, setErrors] = useState({});
    
    const [alertPopup,setAlertPopup] = useState(false);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
    const [alertMessage,setAlertMessage] = useState([]);
    const [validationMessage, setValidationMessage] = useState('');
    const [passwordFocused, setPasswordFocused] = useState(false);
    const [criteria, setCriteria] = useState({
      length: false,
      upperCase: false,
      lowerCase: false,
      number: false,
      specialChar: false
    });
    

    // const activeRef = useRef(null);
    const roleRef = useRef(null);
    const routeChange = () =>{ 
        window.location.href=`/account`;
    }
    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_users'))
        {
          window.location.href = '/unauthorized';
        }

    useEffect(()=>{
        fetchroles();
        fetchUsers();
    },[]);

    const validatePassword = (password) => {
        const lengthCriteria = /.{8,}/;
        const upperCaseCriteria = /[A-Z]/;
        const lowerCaseCriteria = /[a-z]/;
        const numberCriteria = /[0-9]/;
        const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>_\[\]-]/;
    
        setCriteria({
          length: lengthCriteria.test(password),
          upperCase: upperCaseCriteria.test(password),
          lowerCase: lowerCaseCriteria.test(password),
          number: numberCriteria.test(password),
          specialChar: specialCharacterCriteria.test(password)
        });
    
        let message = 'Password must include: ';
        let isValid = true;
    
        if (!lengthCriteria.test(password)) {
          message += 'at least 8 characters, ';
          isValid = false;
        }
        if (!upperCaseCriteria.test(password)) {
          message += 'one uppercase letter, ';
          isValid = false;
        }
        if (!lowerCaseCriteria.test(password)) {
          message += 'one lowercase letter, ';
          isValid = false;
        }
        if (!numberCriteria.test(password)) {
          message += 'one number, ';
          isValid = false;
        }
        if (!specialCharacterCriteria.test(password)) {
          message += 'one special character, ';
          isValid = false;
        }
    
        if (isValid) {
          setValidationMessage('Password is strong.');
        } else {
          setValidationMessage(message.slice(0, -2) + '.');
        }
      };
      const handlePasswordFocus=()=> {
        setPasswordFocused(true);
       }
       
       const handlePasswordBlur=()=> {
         setPasswordFocused(false);
       }

    const fetchroles=async()=>{
        setIsLoading(true);
        try {
            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
                loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            }
           
            let URL = BaseUrl + `admin/getRoles`;
            const response = await http.get(URL)
                let responseData = response.data.data;
                if (response.data.success) {
                    //const { roles} = responseData;
                    setRoles(responseData);
        
                }
            }catch(err) {
                console.log(err);
            }
            finally{
                setIsLoading(false);
             }
        };

        const fetchUsers=async()=>{
            setIsLoading(true);
            try {
                var loginDetails = {};
                if (sessionStorage.getItem('authData')) {
                    loginDetails = JSON.parse(sessionStorage.getItem('authData'));
                }
               
                let URL = BaseUrl + `admin/all`;
                const response = await http.get(URL)
                    let responseData = response.data.data;
                    if (response.data.success) {
                        //const { roles} = responseData;
                        setUsers(responseData);
            
                    }
                }catch(err) {
                    console.log(err);
                }
                finally{
                    setIsLoading(false);
                 }
            };
     
    const AddSubmit = (e) =>
    {
        e.preventDefault();
        
        const isValid = formvalidation();
   
          
       if(isValid)
       {
       
         let payload = {
            adminName: adminName,
            adminEmail : adminEmail,
            adminPassword:adminPassword,
            // adminStatus: adminStatus,
            adminRole : adminRoles,
         }
         
         const isFound = users.some(element => {
            if (element.adminEmail === adminEmail) {
              return true;
            }
        
            return false;
          });
        if(isFound)
        {
            setAlertErrorPopup(true);
            setAlerterrMessage(`This user have been added already`); 

        }
        else
        {
         var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }
        let URL = BaseUrl + "admin/register/";
        http.post(URL, payload).then(async (response) => {
                
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage(`User have been added successfully`); 
                }
            }).catch((err) => {
            console.log(err);
            
            })
        }
       }
        
    }

    const formvalidation = () =>
    {
        const errmsg = {};
        let isValid = true;

        if (!adminName.trim()) {
            errmsg.adminName = "Admin Name is required";
            isValid = false;
        }
        if (!adminEmail.trim()) {
            errmsg.adminEmail = "Admin Email is required";
            isValid = false;
        } else if (!validateEmail(adminEmail.trim())) {
            errmsg.adminEmail = 'Invalid email address';
            isValid = false;
          }
        if (!adminPassword.trim()) {
            errmsg.adminPassword = "Password is required";
            isValid = false;
        }else if (!admincPassword.trim()) {
            errmsg.admincPassword = "Confirm Password is required";
            isValid = false;
        }else if(adminPassword != admincPassword)
        {
            errmsg.admincPassword = 'Password and Confirm Password should be equal';
            isValid = false;
        }
        else{
              validatePassword(adminPassword);
                if (validationMessage !== 'Password is strong.') {
                  // errmessage.npassworderr = this.state.validationMessage;
                  // errmsg.npass = true;
                  setPasswordFocused(true);
                  isValid = false;
                }
            }
        if (typeof adminRoles !== 'string' || !adminRoles.trim()) {
            errmsg.adminRoles = "Admin Role is required";
            isValid = false;
        }

        setErrors(errmsg);
        return isValid;
    }

    const validateEmail = (email) => {
      //var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
      var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
        return emailRegex.test(email);
      };

  const handleValue = (e) =>{
    const re = /^[a-zA-Z][a-zA-Z ]*$/;

    if(e.target.name ==="adminName")
    {
       // if (e.target.value === '') {
            setAdminName(e.target.value);
      //  }

    }
    if(e.target.name ==="adminEmail")
    {
        setAdminEmail(e.target.value);
    }
    if(e.target.name ==="adminPassword")
    {
        validatePassword(e.target.value.replace(/\s+/g, ''));
        setAdminPassword(e.target.value);
    }
    if(e.target.name ==="admincPassword")
    {
        setAdmincPassword(e.target.value);
    }
    
    if(e.target.name ==="adminRoles")
        {
             setAdminRoles(e.target.value);
        }
    const { name, value } = e.target;

    setErrors(prevErrors => ({
        ...prevErrors,
        [name]: ''
    }));
  }
 /*Added by K2b Sangeetha for View Password icon*/
  const togglePasswordVisibility = () => {
    setShowAdminPassword(!showadminPassword); // Toggle visibility for Confirm New Password
  };
  const toggleCnPasswordVisibility = () => {
    setShowAdmincPassword(!showadmincPassword); // Toggle visibility for Confirm New Password
  };
   /*Added by K2b Sangeetha for View Password icon*/
  
   return (
    
    <section className="dashboard-block">
    { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
     <Sidebar />
   
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container account-details">
            <h1 className="checkout-title">Add User</h1>

            <div className="right-table-outer-wrap adm-add-users">
            <div className='form-field freeproduct-fieldset masterentry-fieldset form-container-xs'>
            <form className="form-field bottom addaccount" onSubmit={(e)=>AddSubmit(e)}>
                            <div className="input-field">
                            <span className="labeltxt">Name</span>
                                <input type='text'  name="adminName" value={adminName} onChange={handleValue} />
                                {errors.adminName && <div className='error-msg'>{errors.adminName}</div>}

                            </div>
                           
                            <div className="input-field">
                            <span className="labeltxt">Email Address</span>
                                <input type='text'  name="adminEmail" value={adminEmail} onChange={handleValue} />
                                {errors.adminEmail && <div className='error-msg'>{errors.adminEmail}</div>}
                            </div>

                            <div className="input-field">
                            <span className="labeltxt">Password</span>
                                <input type={showadminPassword ? 'text' : 'password'}  name="adminPassword" value={adminPassword} onChange={handleValue} onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur} />
                                <span className='icon-eye' onClick={togglePasswordVisibility}>
                                <i
                                className={`fa ${showadminPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                ></i>
                                </span>
                                {errors.adminPassword && <div className='error-msg'>{errors.adminPassword}</div>}
                                {passwordFocused && adminPassword && (<div className='chg-pwd-lgd'>
                                <ul className='chg-pwd-list'>
                                <li className={ criteria.length ? 'checked' : 'unchecked' }>At least 8 characters</li>
                                <li className={ criteria.upperCase ? 'checked' : 'unchecked' }>One uppercase letter</li>
                                <li className={ criteria.lowerCase ? 'checked' : 'unchecked' }>One lowercase letter</li>
                                <li className={ criteria.number ? 'checked' : 'unchecked' }>One number</li>
                                <li className={ criteria.specialChar ? 'checked' : 'unchecked'}>One special character</li>
                                </ul>

                            </div>)}
                            </div>

                            <div className="input-field">
                            <span className="labeltxt">Confirm Password</span>
                                <input type={showadmincPassword ? 'text' : 'password'}  name="admincPassword" value={admincPassword} onChange={handleValue} />
                                <span className='icon-eye' onClick={toggleCnPasswordVisibility}>
                                <i
                                className={`fa ${showadmincPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                                ></i>
                                </span>
                                {errors.admincPassword && <div className='error-msg'>{errors.admincPassword}</div>}
                            </div>
                           
                            {/* <div className="input-field">
                            <span className="labeltxt">Status</span>
                                <select name="adminStatus" ref={activeRef}  onChange={handleValue}>
                                    <option value="">Select</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                                {errors.adminStatus && <div className='err-msg'>{errors.adminStatus}</div>}
                            </div> */}
                           
                            
                            <div className="input-field">
                            <span className="labeltxt">Roles</span>
                               <select name='adminRoles' onChange={handleValue} ref={roleRef}>
                                    <option value="">Select</option>
                                    {roles.map(code => (
                                    <option key={code._id} value={code._id}>{code.roleName}</option>
                                ))}
                               </select>
                               {errors.adminRoles && <div className='error-msg'>{errors.adminRoles}</div>}
                            </div>
                            
                           
                            <div className='btnfield'>
                                <button type="submit" name="btnsubmit">Add User</button>
                                &nbsp;&nbsp;&nbsp;
                                <button type="button" onClick={routeChange}>Cancel</button>
                                </div>
                                  
                        </form>
                    </div>
           </div>
         </div>
         <Footer/> 
        </section>
       
            </div>
          
        </section>
  )
}

export default AddUser