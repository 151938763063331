import React, { useEffect, useState, memo } from "react";
import { Pie } from "react-chartjs-2";
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);
export const PieChart = memo(({ }) => {
    var currentTime = new Date();

    const [filter, setFilter] = useState(0);

    const [paylaterOrders, setPaylaterOrders] = useState(0);
    const [onlinePaymentOrders, setOnlinePaymentOrders] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const [chartData, setChartData] = useState({
        labels: ['Online Payment Orders', 'Pay Later Orders'],
        datasets: [
            {
                label: "Orders",
                data: [onlinePaymentOrders, paylaterOrders],
                backgroundColor: [
                    "rgba(252,194,84,1)",
                    "#f4ecec",

                ],
                borderColor: "black",
                borderWidth: 2
            }
        ]
    });

    useEffect(() => {

        setChartData({
            labels: ['Online Payment', 'Pay Later'],
            datasets: [
                {
                    label: "Orders",
                    data: [onlinePaymentOrders, paylaterOrders],
                    type: 'doughnut',
                    backgroundColor: [
                        "#a1e8eb",
                        "rgba(195,183,248,1)",
                    ],
                    borderColor: "#e6e6e6",
                    borderWidth: 1
                }
            ]
        });
    }, [onlinePaymentOrders, paylaterOrders])

    useEffect(() => {

        let URL = BaseUrl + "admin/paymentorders";
        http.post(URL, { filter: parseInt(filter) }).then((response) => {
            setIsLoading(false);
            setOnlinePaymentOrders(response?.data?.data?.onlinePaymentOrders);
            setPaylaterOrders(response?.data?.data?.payLaterOrders);

        }).catch((err) => {
            console.log(err);
        })
    }, [filter]);


    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
    };

    const legendMargin = {
        id: "legendMargin",
        beforeInit: function (chart) {
          const fitValue = chart.legend.fit;
          chart.legend.fit = function fit() {
            fitValue.bind(chart.legend)();
            return (this.height += -10);
          };
        }
      };
      let loadingbuffer = isLoading ? 'loading-buffer':'';
    return (

        <div className={`chart-container chart-order-count ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <h2 style={{ textAlign: "left" }}>Orders Count</h2>
                <div className="chart-fltr">
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                        
                    ><option value={0}>All</option>
                        <option value={1}>Last 30 days</option>
                        <option value={3}>Last 3 months</option>
                        <option value={6}>Last 6 months</option>

                    </select>}
                </div>
            </div>
            {chartData && <Pie
                data={chartData}
                plugins={[legendMargin]}
                options={{
                    plugins: {
                        title: {
                            display: false,

                        },
                        legend: {
                            display: true,
                            align: 'left',
                            position: 'bottom',
                            labels: {
                                color: "#969696",
                                font: {
                                    size: 12
                                },
                                padding: 20,
                            },
                        },
                        tooltip: {
                            titleColor: 'rgba(220, 222, 226, 1)',
                            bodyColor: 'rgba(220, 222, 226, 1)'
                        }
                    }
                }}
            />}
        </div>
    );
});