import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Header from "../common/layout/header";
import Footer from "../common/layout/footer";

import Popup from "../common/popup";

import Sidebar from "../common/sidebar/index";
import CommonAlert from "../common/Commonalert";
import moment from "moment";
import { http } from "../helper";
import EditAccountDetails from "./editAccountdetail";

import { Link, Navigate } from "react-router-dom";
import { BaseUrl } from "../common/config";
import { capitalizeFirstLetter,dateformatwithuktime } from "../utilityfunctions";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      activeTab: 0,
      accountData: [],
      searchTextVal: "",
      templist: [],
      editaccDet: false,
      accounDet: "",
      loading: true,
      alertPopup: false,
      alertMessage: "",
      roleName: JSON.parse(sessionStorage.getItem("authData"))
        ? JSON.parse(sessionStorage.getItem("authData")).adminRole.roleName
        : "",
      rolePermissions: JSON.parse(sessionStorage.getItem("authData"))
        ? JSON.parse(sessionStorage.getItem("authData")).adminRole.permissions
        : "",
    };

    if (!sessionStorage.getItem("isLoggedIn")) window.location.href = "/";

    this.toggleModel = this.toggleModel.bind(this);

    this.activateTab = this.activateTab.bind(this);
  }

  activateTab(index) {
    this.setState((prev) => ({
      activeTab: prev.activeTab === index ? -1 : index,
    }));
  }

  getAccounts() {
    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    let URL = BaseUrl + "admin/all";
    http
      .get(URL)
      .then((response) => {
        
        if (this.state.rolePermissions.includes("manage_users")) {
          this.setState({
            accountData: response.data.data,
            templist: response.data.data,
            loading: false,
          });
        } else {
          const accdata = response.data.data.filter(
            (acc) => acc.adminEmail === loginDetails.adminEmail
          );
          this.setState({
            accountData: accdata,
            templist: accdata,
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleModel() {
    this.setState({
      visible: !this.state.visible,
    });
  }

  editAccountDetails = (accoun) => {
    this.setState({ editaccDet: true, accounDet: accoun });
  };

  editclosepopup = (alrtpopup, alrtmsg) => {
    this.setState({
      editaccDet: false,
      alertPopup: alrtpopup,
      alertMessage: alrtmsg,
    });
  };
  editAccDetails = (formData) => {
    let URL = BaseUrl + "admin/editadminaccount/" + formData.id;

    this.setState({ editProdDet: false, loading: true });

    var loginDetails = {};
    if (sessionStorage.getItem("authData")) {
      loginDetails = JSON.parse(sessionStorage.getItem("authData"));
    }

    //const data = new FormData();
    http
      .put(URL, formData)
      .then((response) => {
        let responseData = response.data.success;
        if (responseData) {
          this.setState({
            alertPopup: true,
            alertMessage: "Account Details Updated Successfully",
            loading: false,
          });
        }
        this.setState({ editaccDet: false, loading: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  componentDidMount() {
    document.body.classList.add("split");
    this.getAccounts();
  }

  addUser = () => {
    window.location.href = "/account/adduser";
  };

 
  handleReset = () => {
    this.setState({ searchTextVal: "", accountData: this.state.templist });
  };

  handleChange = async (value) => {
    this.setState({ searchTextVal: value });
    this.filterData(value);
  };
  filterData = async (value) => {
    let accoun = this.state.templist;

    const lowercasedValue = value.toLowerCase().trim();
    let result = [];
    if (lowercasedValue.length > 0) {
      accoun.map((item) => {
        if (
          item.adminName.toString().toLowerCase().includes(lowercasedValue) ||
          item.adminEmail.toString().toLowerCase().includes(lowercasedValue) ||
          item.adminRole.roleName.toString().toLowerCase().includes(lowercasedValue)           
        ) {
          result.push(item);
        }
      });
      this.setState({ accountData: result });
    } else {
      this.setState({ accountData: accoun });
    }
  };

  render() {
    const columns = [
      {
        name: "Name",
        selector: (row) => row.adminName,
        sortable: true,
      },
      {
        name: "Email Address",
        selector: (row) => row.adminEmail,
        sortable: true,
      },
      {
        name: "Status",
        selector: (row) => capitalizeFirstLetter(row.adminStatus),
        sortable: true,
      },
      {
        name: "Role Name",
        selector: (row) => row.adminRole.roleName,
        sortable: true,
      },
      {
        name: "Created On",
        selector: (row) => dateformatwithuktime(row.createdAt),
        sortable: true,
      },
      {
        name: "Modified On",
        selector: (row) => dateformatwithuktime(row.updatedAt),
        sortable: true,
      },
    ];

    // Conditionally add the Actions column if the user has 'manage_users' permission
    if (
      this.state.rolePermissions.includes("manage_users") ||
      columns.some((col) =>
        col.selector({
          adminEmail: JSON.parse(sessionStorage.getItem("authData")).adminEmail,
        })
      )
    ) {
      columns.push({
        name: "Actions",
        selector: (row, index) => (
          <div style={{ display: "flex" }} className="action-btns">
            <a
              className="button title-tooltip title-up"
              data-title="Edit Details"
              style={{ color: "white" }}
              onClick={() => this.editAccountDetails(row)}
            >
              <i className="fa fa-pencil" aria-hidden="true"></i>
            </a>
          </div>
        ),
        sortable: false,
      });
    }

    const customStyles = {
      rows: {
        style: {
          minHeight: "25px",
        },
      },
      headCells: {
        style: {
          paddingLeft: "8px", // override the cell padding for head cells
          paddingRight: "8px",
        },
      },
      cells: {
        style: {
          paddingLeft: "8px", // override the cell padding for data cells
          paddingRight: "8px",
        },
      },
    };

    const { panels } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        {this.state.alertPopup == true && (
          <CommonAlert title="Alert" message={this.state.alertMessage} />
        )}
        <div className="dashboard-block">
          <Sidebar />
          <div className="dash-left">
            <Header />

            <section className="cart-block">
              <div className="container">
              <h1 className="checkout-title">Users</h1>  
                <div className="right-table-outer-wrap">
                  <div className="table-filter search-orders">
                    <div className="table-search-reset">
                      <div className="table-search">
                        <input
                          type="text"
                          value={this.state.searchTextVal}
                          onChange={(e) => this.handleChange(e.target.value)}
                          className="form-control"
                          placeholder="Search by Name, Email Address, Role Name"
                        />
                      </div>
                      <button
                        className="submitbtn button search-holi-btn"
                        onClick={(e) =>
                          this.filterData(this.state.searchTextVal)
                        }
                      >
                        <i className="fa fa-search"></i>
                      </button>

                      <button
                        className="resetbtn"
                        type="reset"
                        onClick={this.handleReset}
                      >
                        Reset
                      </button>
                    </div>

                    {this.state.rolePermissions.includes("manage_users") && (
                      <div className="add-userbtn">
                        <button className="addbtn" onClick={this.addUser}>
                          <i className="fa fa-plus"></i> Add User </button>
                      </div>
                    )}
                  </div>

                  <div className="table_overflow admin-account-table">
                    <DataTable
                      columns={columns}
                      data={this.state.accountData}
                      customStyles={customStyles}
                      progressPending={this.state.loading}
                    />
                  </div>

                  
                </div>
              </div>
            </section>

            <Footer />
          </div>
          {this.state.editaccDet && (
                    <EditAccountDetails
                      editAccdet={this.state.accounDet}
                      editloading={this.state.loading}
                      popupclose={this.editclosepopup}
                      editAccounDetails={this.editAccDetails}
                    />
                  )}
                  {this.state.visible ? (
                    <>
                      <Popup
                        title="Admin Users"
                        closebtn={
                          <>
                            <span
                              onClick={() => this.setState({ visible: false })}
                              className="close"
                            ></span>
                          </>
                        }
                        bodyMoreTxt={
                          <>
                            <div className="pro-form">
                              <div className="pro-row">
                                <label>
                                  First Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your first name"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  Last Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your last name"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  Email Address <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your mail address"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  Contact number <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your contact number"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  User Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your user name"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  Password <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your password"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  Retype your password <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter your user name"
                                />
                              </div>
                              <div className="pro-row">
                                <label>
                                  Status <span>*</span>
                                </label>
                                <select className="form-control">
                                  <option>Active</option>
                                  <option>In Active</option>
                                </select>
                              </div>
                              <div className="pro-row flx-bottom">
                                <button className="light-btn button">
                                  Submit
                                </button>
                                <button
                                  onClick={() =>
                                    this.setState({ visible: false })
                                  }
                                  className="dark-btn button"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </>
                        }
                      />
                    </>
                  ) : (
                    <></>
                  )}
        </div>
      </>
    );
  }
}

export default Account;
