import React, {useState, useEffect, useRef} from "react";
import { Form, Link } from "react-router-dom";
import Logo from '../../assets/images/logo.png';
import styles from '../Layout.module.css';
import { BaseUrl,frontendURL } from '../config';
import twittericon from '../../assets/images/twitter-wt.png';
import { http } from '../../helper';

function Footer() {

        const [height, setHeight] = useState(0);
        const [visible, setVisible] = useState(false);
        const [settingsList, setsettingsList] = useState([]);
        const heightRef = useRef();
        const getSize = () => {
            let newHeight = heightRef.current.clientHeight;
            setHeight(newHeight);
          };
        useEffect(() => {
            getSize();
            window.addEventListener("resize", getSize);
            return () => {
                window.removeEventListener("resize", getSize);
              };
        },[])
        useEffect(() => {
            window.addEventListener("resize", getSize);
            getSettings();  
          },[]);
        
          useEffect(() => {
            document.body.style.setProperty('--padBottom', height+'px');
          }, [height]);
       

        var currentDate = new Date();

        function scrollTop(){

            window.scroll({
             top: 0,  
             behavior: 'smooth' 
            });
        }

         const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;
            if (scrolled > 300){
              setVisible(true)
            } 
            else if (scrolled <= 300){
              setVisible(false)
            }
          };

          useEffect(() => {
            window.addEventListener("scroll", toggleVisible);
            return () => {
              window.removeEventListener("scroll", toggleVisible);
            };
          }, []);
         
         function getSettings(){

            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
                loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            }

            
            let URL = '';
            if (loginDetails && loginDetails.token) {
                
                URL = BaseUrl + "admin/settings";
            }
            else{
                URL = BaseUrl + "admin/settingswithoutauth";
            }
             
            
            http.get(URL).then(async (response) => {
               let responseData = response.data.data[0];
               if(responseData !==''){
                   setsettingsList(responseData);
                   sessionStorage.setItem('minimuOrderAmount',responseData.minimumOrderAmount);
              }
              }).catch((err) => {
                if (err.response) {   
                    if(err.response.status === 401){
                       if(err.response.data.message === 'jwt expired')
                       {
                        sessionStorage.clear();
                        window.location.href='/';
                       }                        
        
                    }
                }
               console.log(err);
              });
            }

        return(
            <>
           { (window.location.hostname === 'dev-tugoadmin.ezzysales.com')?
            (
             <div className='dev-portal-tag'>
                <p>Tugo Admin - Development Portal</p>                
            </div>
            ):''
           }
                <footer ref={heightRef}>
                    <div className="sm-container">
                        
                        
                        <div className={styles.footerWrap}>
                            <div className={styles.footerLeft}>
                                <div className={styles.footLogo}>
                                    <Link to="/products" className={styles.logo}>
                                        <img src={Logo} alt="Tugo" />
                                    </Link>
                                </div>
                                <div className={styles.footAdr}>
                                    <p>Tugo Towers,<br></br> 14 Somerville Court,<br></br> Adderbury,<br></br> OX17 3SN</p>
                                </div>
                                <div className={styles.footLinks}>
                                    <ul className={styles.footnav}>
                                        <li>
                                            <a target="_blank" href={`${frontendURL}product/pizza`}>Pizza</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href={`${frontendURL}product/pasta-rice-noodle-sauces`}>Pasta, Rice & Noodle Sauces</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href={`${frontendURL}product/burrito`}>Burrito</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href={`${frontendURL}productinfo`}>Products Info</a>
                                        </li>
                                    </ul>
                                    <ul className={styles.footnav}>
                                        <li>
                                            <a target="_blank" href="https://www.tugo.co.uk/cookies" >Cookies</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://www.tugo.co.uk/privacy-policy">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://www.tugo.co.uk/terms-and-conditions">Terms & Conditions</a>
                                        </li>
                                        <li>
                                            <a target="_blank" href="https://www.tugo.co.uk/contact-us">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={styles.footerRight}>
                                <span className={styles.link}>
                                <a href="tel: 01295 367351"><i className="fa fa-phone" aria-hidden="true"></i> 01295 367351</a>
                                </span>
                               {/* Mail address changed by k2b Sangeetha */}
                                <span className={styles.link}>
                                <a href="mailto: hello@tugo.co.uk"><i className="fa fa-envelope" aria-hidden="true"></i> hello@tugo.co.uk</a>
                                </span>
                                {/* Mail address changed by k2b Sangeetha */}
                            </div>
                        </div>

                        <div className={styles.footerCopy}>
                            <div className={styles.CopyTxt}>

                             {settingsList !== '' ? <p> {settingsList.copyrightText} </p> :  <p>© Copyright {currentDate.getFullYear()} Tugo. All rights reserved.</p>}


                                
                            </div>
                            <div className={styles.footSocial}>
                            {settingsList !== '' && Object.keys(settingsList).length > 0 && 
                                <ul>
                                {settingsList.linkedinURL!=="" && <li><a href={settingsList.linkedinURL} target="_blank"><i className="fa fa-linkedin"></i></a></li>}
                                {settingsList.instagramURL!=="" && <li><a href={settingsList.instagramURL} target="_blank"><i className="fa fa-instagram"></i></a></li>}
                                {settingsList.facebookURL!=="" &&  <li><a href={settingsList.facebookURL} target="_blank"><i className="fa fa-facebook"></i></a></li>}
                                {settingsList.youtubeURL!=="" &&   <li><a href={settingsList.youtubeURL} target="_blank"><i className="fa fa-youtube-play"></i></a></li>}
                                {settingsList.twitterURL!=="" && <li className="twitter-icon"><a href={settingsList.twitterURL} target="_blank">
                                    <img src={twittericon}/>
                                    {/* <i className="fa fa-twitter"></i> */}
                                    </a></li>}
                                </ul>
                              }
                            </div>
                        </div>

                    </div>

                     <div className= {visible ? "scrolltop show" : "scrolltop"} id="scrollbutton" onClick={scrollTop}>
                      <a className="disbl_href_action"><span>
                      <i className="fa fa-angle-double-up ars"></i>
                      <i className="fa fa-angle-up ars1"></i>
                      <i className="fa fa-angle-up ars2"></i></span>
                      </a>
                    </div>

                </footer>
            </>
        );

}
export default Footer;