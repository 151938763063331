import React, { useState,useEffect} from 'react';
import { BaseUrl } from '../common/config';
import Popup from "../common/popup";
import { http } from '../helper';

const ChangePassword =(props)=>{
const [npassword,setNpassword] = useState([]);
const [cnpassword,setCNpassword] = useState([]);
const [errmessage, setErrMessage] = useState({});
const [errormsg,setErrormsg] = useState({});
const [showCnPassword, setShowCnPassword] = useState(false);  
const [shownPassword, setShownPassword] = useState(false);  
  
const [validationMessage, setValidationMessage] = useState('');
const [passwordFocused, setPasswordFocused] = useState(false);
const [criteria, setCriteria] = useState({
  length: false,
  upperCase: false,
  lowerCase: false,
  number: false,
  specialChar: false
});

const changepwdsubmit = (e) =>{
  e.preventDefault();
  const isValid = formvalidation();
  if(isValid)
  {   
    let URL = BaseUrl + "admin/vendor/changepassword";
    let payload = {
        npassword: npassword,
        cnpassword: cnpassword,
        emailaddress: props.emailadd,
        accountCode: props.accCode,
      
       }
       
       
    var loginDetails = {};
    if (sessionStorage.getItem('authData')) {
        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
    }   
    http.post(URL,payload).then((response) => {
       
       
        if (response.data.success === true) {
            
                props.popupclose(true,'Password has been changed successfully!');
          
        }
      
    }).catch((err) => {
        console.log(err);
        if (err.response) {   
            if(err.response.status === 400){
                const apierror = {};
                apierror.pwderr = err.response.data.message;
                setErrMessage(apierror);

            }
            props.popupclose(true,'not has been changed successfully!');
          
           
        }
        
    })  
   
  }
}
const validatePassword = (password) => {
    const lengthCriteria = /.{8,}/;
    const upperCaseCriteria = /[A-Z]/;
    const lowerCaseCriteria = /[a-z]/;
    const numberCriteria = /[0-9]/;
    const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>_\[\]-]/;

    setCriteria({
      length: lengthCriteria.test(password),
      upperCase: upperCaseCriteria.test(password),
      lowerCase: lowerCaseCriteria.test(password),
      number: numberCriteria.test(password),
      specialChar: specialCharacterCriteria.test(password)
    });

    let message = 'Password must include: ';
    let isValid = true;

    if (!lengthCriteria.test(password)) {
      message += 'at least 8 characters, ';
      isValid = false;
    }
    if (!upperCaseCriteria.test(password)) {
      message += 'one uppercase letter, ';
      isValid = false;
    }
    if (!lowerCaseCriteria.test(password)) {
      message += 'one lowercase letter, ';
      isValid = false;
    }
    if (!numberCriteria.test(password)) {
      message += 'one number, ';
      isValid = false;
    }
    if (!specialCharacterCriteria.test(password)) {
      message += 'one special character, ';
      isValid = false;
    }

    if (isValid) {
      setValidationMessage('Password is strong.');
    } else {
      setValidationMessage(message.slice(0, -2) + '.');
    }
  };
  const handlePasswordFocus=()=> {
    setPasswordFocused(true);
   }
   
   const handlePasswordBlur=()=> {
     setPasswordFocused(false);
   }
const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        
        if(npassword == '' )
        {
            errmessage.npassworderr = "This field is required";
            errmsg.npass = true;
            isValid = false;
        }
        else {
            validatePassword(npassword);
            if (validationMessage !== 'Password is strong.') {
              // errmessage.npassworderr = validationMessage;
              // errmsg.npass = true;
              setPasswordFocused(true);
              isValid = false;
            }
          }

 
        if(cnpassword == '' )
        {
            errmessage.cnpassworderr = "This field is required";
            errmsg.cnpass = true;
            isValid = false;
        }
        if(cnpassword != '')
        {
            if(cnpassword !== npassword)
            {
                errmessage.cnpassworderr = "New password and Confirm New Password should be same";
                errmsg.cnpass = true;
                isValid = false;
            }
        }
        if(isValid)
        {
            errmessage.npassworderr = '';
            errmessage.cnpassworderr='';
            errmsg.cnpass = false;
            errmsg.npass = false;
            errmsg.cnpass = false;
            setErrMessage(errmessage);
            return isValid;
        }
        else{
            
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            
            return isValid;
        }

       
        
    }

 

const handlepass = (e) =>
{
    if(e.target.name === 'npassword')
    {
        setNpassword(e.target.value.replace(/\s+/g, ''));
        validatePassword(e.target.value.replace(/\s+/g, ''));
        
        if(errmessage.npassworderr != '')
        {
           errormsg.npass = false;
        }
        
        setErrormsg(errormsg);
    }

    
    if(e.target.name === 'cnpassword')
    {
        setCNpassword(e.target.value.replace(/\s+/g, ''));
        if(errmessage.cnpassworderr != '')
        {
           errormsg.cnpass = false;
        }
        
        setErrormsg(errormsg);
    }
    
}
 /*Added by K2b Sangeetha for View Password icon*/
const toggleCnPasswordVisibility = () => {
    setShowCnPassword(!showCnPassword); // Toggle visibility for Confirm New Password
  };
  const togglenPasswordVisibility = () => {
    setShownPassword(!shownPassword); // Toggle visibility for Confirm New Password
  };
   /*Added by K2b Sangeetha for View Password icon*/

    return (
     <Popup
    extClass="changepassword-pop"
    headMoreTxt={
        <>
            <div className="order-top">
            <div className="head">
                    <h4>Change Password - {props.accCode}</h4>
                </div>
            </div>
        </>
    }
    bodyMoreTxt={
        <>
            <div className="order-top">
                              
                <div className="bottom vendor-details change-pwd">
                    <div className='input-field'>
                    <span className="labeltxt">New Password </span> 
                    <input type={shownPassword ? 'text' : 'password'} name="npassword" value={npassword} autoComplete="off" onChange={handlepass} onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur}/>
                    {errormsg.npass && <div className='error-msg'>{errmessage.npassworderr}</div> }
                    {passwordFocused && npassword && (<div className='chg-pwd-lgd'>
                        <ul className='chg-pwd-list'>
                          <li className={ criteria.length ? 'checked' : 'unchecked' }>At least 8 characters</li>
                          <li className={ criteria.upperCase ? 'checked' : 'unchecked' }>One uppercase letter</li>
                          <li className={ criteria.lowerCase ? 'checked' : 'unchecked' }>One lowercase letter</li>
                          <li className={ criteria.number ? 'checked' : 'unchecked' }>One number</li>
                          <li className={ criteria.specialChar ? 'checked' : 'unchecked'}>One special character</li>
                        </ul>

                    </div>)}
                    <span className='icon-eye' onClick={togglenPasswordVisibility}>
                        <i
                        className={`fa ${shownPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                        ></i>
                    </span>
                   
                   </div>
                   <div className='input-field'>
                   <span className="labeltxt">Confirm New Password </span> 
                    <input  type={showCnPassword ? 'text' : 'password'} autoComplete="new-password"  className={showCnPassword ? 'hidden' : ''}   name="cnpassword" value={cnpassword}  onChange={handlepass}/>
                    {errormsg.cnpass && <div className='error-msg'>{errmessage.cnpassworderr}</div> }
                    <span className='icon-eye' onClick={toggleCnPasswordVisibility}>
                        <i
                        className={`fa ${showCnPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                        ></i>
                    </span>
                    </div>
                    
                    <div className='btn-field'>
                        <button onClick={changepwdsubmit}>Submit</button>
                    </div>
                </div>

               
            </div>
        </>
    }
    closebtn={<><span className='close' onClick={(e)=>props.popupclose(e,false,'')}></span></>}
    
   
/>
    );

 
}
export default ChangePassword;