import React, { useState, Component } from 'react';
import {http} from '../helper';
 
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import CommonAlert from '../common/Commonalert';
import { BaseUrl } from '../common/config';
import Loader from '../common/loader';

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    const queryParameters = window.location.pathname.split('/');
    this.state = {
      newpassword: "",
      confirmpassword: "",
      loginStatus: "",
      loginMessage: "",
      isLoading: true,
      token:queryParameters['2'],
      userId: queryParameters['3'],
      alertPopup:false,

      tokenexpiry:false,
      validationMessage: '',
      errmessage:'',
      errmsg:'',
      criteria: {
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false
      },
      passwordFocused: false,
      showNewPassword:false,
      showConfirmPassword: false,
    };

    this.newPassword = this.newPassword.bind(this);
    this.passwordChange = this.passwordChange.bind(this);
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this);
    this.handlePasswordBlur = this.handlePasswordBlur.bind(this);
  }

  componentDidMount() {
    this.resetPassword();
  }
  validatePassword = (password) => {
    const lengthCriteria = /.{8,}/;
    const upperCaseCriteria = /[A-Z]/;
    const lowerCaseCriteria = /[a-z]/;
    const numberCriteria = /[0-9]/;
    const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>_\[\]-]/;

    const criteria = {
      length: lengthCriteria.test(password),
      upperCase: upperCaseCriteria.test(password),
      lowerCase: lowerCaseCriteria.test(password),
      number: numberCriteria.test(password),
      specialChar: specialCharacterCriteria.test(password)
    };

    this.setState({ criteria });

    let message = 'Password must include: ';
    let isValid = true;

    if (!criteria.length) {
      message += 'at least 8 characters, ';
      isValid = false;
    }
    if (!criteria.upperCase) {
      message += 'one uppercase letter, ';
      isValid = false;
    }
    if (!criteria.lowerCase) {
      message += 'one lowercase letter, ';
      isValid = false;
    }
    if (!criteria.number) {
      message += 'one number, ';
      isValid = false;
    }
    if (!criteria.specialChar) {
      message += 'one special character, ';
      isValid = false;
    }

    if (isValid) {
      this.setState({ validationMessage: 'Password is strong.' });
    } else {
      this.setState({ validationMessage: message.slice(0, -2) + '.' });
    }
  };

  resetPassword(){
    var loginDetails = {};
    if (sessionStorage.getItem('authData')) {
       loginDetails = JSON.parse(sessionStorage.getItem('authData'));
    }
    let URL = BaseUrl + "admin/passwordtokencheck";
    let payload = {
      userId: this.state.userId,
      token: this.state.token,
    }
    http.post(URL, payload).then((response) => {
      if (response.data.success === true) {
          this.setState({tokenexpiry:false});
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
    if (error.response.status === 400){
      this.setState({ tokenexpiry:true,isLoading: false });
    }
    })
  }

  newPassword(event) {
    this.setState({
      newpassword: event.target.value
    });
    this.validatePassword(event.target.value.replace(/\s+/g, ''));
    this.setState({ loginStatus: "", loginMessage: "", alertPopup: false,errmessage: { ...this.state.errmessage, npassworderr: "" },
      errmsg: {}});
  }

  handlePasswordFocus() {
    this.setState({ passwordFocused: true });
  }

  handlePasswordBlur() {
    this.setState({ passwordFocused: false });
  }

  passwordChange(event) {
    this.setState({
      confirmpassword: event.target.value
    });
    this.setState({ loginStatus: "", loginMessage: "", alertPopup:false,errmessage: { ...this.state.errmessage, cnpassworderr: "" },
      errmsg: {} });
  }
  formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        
        if(this.state.newpassword == '' )
        {
            errmessage.npassworderr = "New Password is Required";
            errmsg.npass = true;
            isValid = false;
        } else {
            this.validatePassword(this.state.newpassword);
            if (this.state.validationMessage !== 'Password is strong.') {
              // errmessage.npassworderr = this.state.validationMessage;
              // errmsg.npass = true;
              this.setState({passwordFocused:true});
              isValid = false;
            }
          }
       
        if(this.state.confirmpassword == '' )
        {
            errmessage.cnpassworderr = "Confirm New Password is Required";
            errmsg.cnpass = true;
            isValid = false;
        }
        if(this.state.confirmpassword != '')
        {
            if(this.state.confirmpassword !== this.state.newpassword)
            {
                errmessage.cnpassworderr = "New password and Confirm New Password should be same";
                errmsg.cnpass = true;
                isValid = false;
            }
        }

            this.setState({errmessage,errmsg});
            return isValid;
      
    }

  resetOnSubmit = (event) => {
    event.preventDefault();
    const isValid = this.formvalidation();
    if(isValid)
      {

    this.setState({ alertPopup:false });
    var loginDetails = {};
    if (sessionStorage.getItem('authData')) {
       loginDetails = JSON.parse(sessionStorage.getItem('authData'));
    }
    let URL = BaseUrl + "admin/resetpassword";
    if (this.state.confirmpassword === this.state.newpassword) {
      let payload = {
        userId: this.state.userId,
        token: this.state.token,
        password: this.state.confirmpassword,

      }
      this.setState({ isLoading: true });
      http.post(URL, payload).then((response) => {
        if (response.data.success === true) {
            this.setState({alertPopup:true, loginMessage: 'Password Reset Successfully'});
            // window.location = '/';
        }
        this.setState({ isLoading: false });
      }).catch((error) => {
      if (error.response.status === 400){
        this.setState({ loginMessage: error.response.data.message, alertPopup:true, isLoading: false });
      }else{

       this.setState({ isLoading: false, alertPopup:false });
      }
      })
    }
    else {
      this.setState({ loginStatus: 401, alertPopup:false });
      this.setState({ loginMessage: "Password doesn't match", alertPopup:false });
    }
  }
  }
  
  toggleNewPasswordVisibility = () => {
    this.setState((prevState) => ({
      showNewPassword: !prevState.showNewPassword
    }));
  };
  toggleConfirmPasswordVisibility = () => {
    this.setState((prevState) => ({
      showConfirmPassword: !prevState.showConfirmPassword
    }));
  }
  
  render() {
    const { validationMessage, criteria, passwordFocused,newpassword,showNewPassword,showConfirmPassword } = this.state;
    
    return (
      <>
       {this.state.alertPopup === true && ( <CommonAlert title='Alert' message={this.state.loginMessage}/> )}
       <div className='login-page' >
        <Header />
       { !this.state.isLoading && this.state.tokenexpiry ? (
          <div className='container'>
            <div className='reset-pass-container'>
            <h2>Reset password link is expired </h2>
            <div className='reset-pass-tokenerr'>
            <p><i className="fa fa-exclamation-triangle"></i> Please visit 'Forgot or Reset Password' page again to have a new token generated</p>
            </div>
            <div className="back-to-cat">
                <a className="back-btn" href="/"><i className="fa fa-arrow-left"></i> Back to Home</a>
            </div>         
          </div>
          </div>):
       
       (!this.state.isLoading && <div className="login">
          <div className="form">
            <form className="login-form" onSubmit={this.resetOnSubmit}>
              <h2>Reset Password</h2>
              {(this.state.loginStatus) ? (this.state.loginStatus === 401 ? <p className='err-msg'>{this.state.loginMessage}</p> : "") : ""}
              <div className='input-field col-1'>
                <input type={showNewPassword  ? 'text' : 'password'} id="accountEmail1" value={this.state.newpassword} onChange={this.newPassword} placeholder="New Password" onFocus={this.handlePasswordFocus}
                    onBlur={this.handlePasswordBlur}  />
                {this.state.errmsg.npass && <div className='error-msg'>{this.state.errmessage.npassworderr}</div> }
                {passwordFocused && newpassword && (<div className='chg-pwd-lgd'>
                  <ul className='chg-pwd-list'>
                  <li className={ criteria.length ? 'checked' : 'unchecked' }>At least 8 characters</li>
                  <li className={ criteria.upperCase ? 'checked' : 'unchecked' }>One uppercase letter</li>
                  <li className={ criteria.lowerCase ? 'checked' : 'unchecked' }>One lowercase letter</li>
                  <li className={ criteria.number ? 'checked' : 'unchecked' }>One number</li>
                  <li className={ criteria.specialChar ? 'checked' : 'unchecked'}>One special character</li>
                  </ul>

               </div>)}
               <span className='icon-eye' onClick={this.toggleNewPasswordVisibility}>
                  <i
                  className={`fa ${showNewPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                  ></i>
                </span>
              </div>
              <div className='input-field col-1'>          
              <input type={showConfirmPassword ? 'text' : 'password'} id="accountEmail" value={this.state.confirmpassword} placeholder="Confirm Password" onChange={this.passwordChange} />
              {this.state.errmsg.cnpass && <div className='error-msg'>{this.state.errmessage.cnpassworderr}</div> }
              <span className='icon-eye' onClick={this.toggleConfirmPasswordVisibility}>
                  <i
                  className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                  ></i>
                </span>
              </div>
              <button type='submit'>Submit</button>
            </form>

          </div>
        </div>
        
       )}
        {this.state.isLoading && <Loader propsClassName="load-lag" />}
   
        <Footer />
        </div>
      </>
    )
  }
}



export default ResetPassword;