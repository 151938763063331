import React, { Component,useEffect,useState } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import moment from 'moment';


const Holidayedit =()=>
{
  
  const [holidayDate,setHolidayDate] = useState(new Date());
  const [holidayTitle,setHolidayTitle] = useState([]);
  const [holidaylist,setHolidaylist] = useState([]);
  const [errmessage, setErrMessage] = useState({});
  const [holidaystatus,setHolidayStatus] = useState([]);
 
  const params = useParams();
 

 if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
}
if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }
const routeChange = (e) =>{ 
    e.preventDefault();
    window.location.href='/holiday';
}
useEffect(()=>
{
    let URL = BaseUrl + `holiday/${params.id}`;
    http.get(URL).then((response) => {
        
        let responseData = {
        "title": "christmas",
        "holidaydate": "2023-03-29T05:58:51.669Z",
        "description": "Christmas Eve"};
       
        setHolidaylist(responseData);
        setHolidayTitle(responseData.title);
       
        setHolidayDate(responseData. moment(holidayDate).format('DD-MM-YYYY'));
        
    }).catch((err) => {
        console.log(err);
        
    })  

},[])

  const submitButton = (e) => {

    e.preventDefault();
    const isValid = formvalidation();

    if(isValid){
        let URL = BaseUrl +"holiday/add";
        const payload = {
                title: holidayTitle,
                date: moment(holidayDate).format('YYYY/MM/DD'),
                //description:description
                holidayStatus : holidaystatus,
            };
   
            http.post(URL,payload).then((response)=>{   
           
            if (response.data.success === true) {
                window.location = '/holiday';
              }
   
        }).catch((error)=>{
   
   
         
          console.log(error)
      
        });

    }
  
}

const formvalidation = () =>
{
    
    const errmessage = {};
    let isValid = true;
    
    if(holidayTitle == '' )
    {
        errmessage.holidayTitleerr = "Title is Required";
        isValid = false;
    }

    if(holidayDate == '')
    {
        errmessage.holidayDateerr = "Date is Required";
        isValid = false;
    }

   
    setErrMessage(errmessage);
    return isValid;
}

   const handleChange = (e) => {

       

        if(e.target.name == 'holidayTitle')
        {
            setHolidayTitle(e.target.value);
        }
        if(e.target.name == 'sltstatus')
        {
            setHolidayStatus(e.target.value);
        }
};

 
  return (
    <>
        <div className="dashboard-block">
            
                <Sidebar />
           
            <div className="dash-left">
                <Header />
                <section className="cart-block">
                <div className="container">
                    <h1 className="checkout-title">Holidays Edit</h1>  

                    <div className="right-table-outer-wrap">
                     <div className='myact-wrap'>
                        <div className='client-details'>
                            <div className='client-detail-bx'>
                                <form className='client-detail-form' onSubmit={submitButton}>
                                    <div className='form-field'>
                                        <label htmlFor='name'>Title</label>
                                        <input type="text" onChange={handleChange} value={holidayTitle} name='holidayTitle' />
                                        <div style={{color:"red"}}>{errmessage.holidayTitleerr}</div> 
                                    </div>
                                    <div className='form-field'>
                                        <label htmlFor='accountcode'>Date</label>
                                       <DatePicker
                                        selected={holidayDate}
                                        onChange={(date) => setHolidayDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        disablePast={true}
                                        minDate={new Date()}
                              
                                     />
                                     <div style={{color:"red"}}>{errmessage.holidayDateerr}</div> 
                                    </div>
                                
                                <div className='form-field'>
                                    <label>Select Status</label>
                                    <select name="sltstatus" onChange={handleChange}>
                                        <option>Select Status</option>
                                        <option value={'A'}>Active</option>
                                        <option value={'I'}>Inactive</option>
                                    </select>
                                    </div>

                                 <div>
                                 <button className="submitbtn" type="submit">Submit</button>
                                  
                                  <button className="submitbtn" onClick={routeChange}>Cancel</button>
                                 </div>

            

                           </form>
                            </div>
                        </div>
                      </div>

                     </div>
                     </div>
                </section>

                <Footer />
            </div>
     
        </div>
    </>
);


}
export default Holidayedit;

  

   