import axios from 'axios';
import { Navigate, Outlet } from "react-router-dom";
import { BaseUrl } from '../common/config';


export const Authenticated = () => {

  return sessionStorage.getItem("isLoggedIn") ? <Outlet /> : <Navigate to="/" />;
}



const instance = axios.create({
  baseURL: `${BaseUrl}`,
});

instance.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token") || "";
    config.headers = {
      ...config.headers,
      "Auth-Token": token,
      'Content-Type': 'application/json',
    };

    return config;
  },
  (error) => Promise.reject(error)
);

instance.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry && originalRequest.url !== '/admin/refresh') {
      originalRequest._retry = true
      let payload = {
        uuid: sessionStorage.getItem("uuid"),
      }
      return instance
        .post('/admin/refresh', payload)
        .then(res => {
          if (res.status === 200) {
            sessionStorage.setItem("token", res.data.data.token);
            originalRequest.headers['Auth-Token'] = `Bearer ${res.data.data.token}`;
            return instance(originalRequest);
          }
        })

    }
    else if (originalRequest.url === '/admin/refresh') {
      sessionStorage.clear();
      window.location.href = "/"
    }
    return Promise.reject(error)
  }
)

export const http = instance;