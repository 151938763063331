import React, { useState,useEffect,useRef} from 'react';
import { BaseUrl } from '../common/config';
import { http } from "../helper";
import Popup from "../common/popup";
 
const editAccountDetails=(props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [formData, setFormData] = useState({
        id:props.editAccdet._id,
        adminName:props.editAccdet.adminName,
        adminEmail: props.editAccdet.adminEmail || '',
        adminStatus: props.editAccdet.adminStatus || '',
        adminRole: props.editAccdet.adminRole._id || '',
    });
    const [roles,setRoles]=useState([]);
    const activeRef = useRef(null);
    const roleRef = useRef(null);

     const [errors, setErrors] = useState({});
     const [errorField, setErrorField] = useState(null);
     useEffect(()=>{
        fetchData(); 
    },[])

    useEffect(() => {
        if (!isLoading) {
            if (activeRef.current) {
                activeRef.current.value = formData.adminStatus;
            }
            if (roleRef.current) {
                roleRef.current.value = formData.adminRole;
            }
        }
    }, [formData, isLoading]);

     const fetchData = async () => {
        setIsLoading(true);
        
        try {
        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }       
      
    let URL = BaseUrl + `admin/getRoles`;
    const response = await http.get(URL)
        let responseData = response.data.data;
        if (response.data.success) {
            //const { roles} = responseData;
            setRoles(responseData);

        }
                
     }catch(err) {
        console.log(err);
        
     }finally{
        setIsLoading(false);
     }
    };


    const handleValue = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        // Clear error message for the corresponding field
        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: ''
        }));
    };
    const validateEmail = (email) => {
        //var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.[a-zA-Z]{2,}$/;
        var emailRegex = /^[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
          return emailRegex.test(email);
        };
    

    const editSubmit=(e)=>{
        e.preventDefault(); // Prevent default form submission behavior

        const isValid = formvalidation();
        if(isValid)
        {   
            const trimmedFormData = Object.keys(formData).reduce((acc, key) => {
                acc[key] = formData[key].trim(); // Trim each form field's value
                return acc;
            }, {});
           props.editAccounDetails(trimmedFormData);
        }
        else {
            // Focus on the first field with an error message
           setErrorField(Object.keys(errors)[0]);
        }
    }
    
    const formvalidation = () =>
    {        
        const newErrors = {};
        let isValid = true;

        if (formData.adminName.trim() === '') {
            newErrors.adminName = "Admin Name is required";
            isValid = false;
        }
        if (!formData.adminEmail.trim()) {
            newErrors.adminEmail = "Admin Email is required";
            isValid = false;
        } else if (!validateEmail(formData.adminEmail.trim())) {
            newErrors.adminEmail = 'Invalid email address';
            isValid = false;
          }

        if (formData.adminStatus.trim() === '') {
            newErrors.adminStatus = "Status is Required";
            isValid = false;
        }

        // if (formData.PrdIngred.trim() === '') {
        //     newErrors.PrdIngred = "Ingerdients is Required";
        //     isValid = false;
        // }
        // if (formData.PrdAllerg.trim() === '') {
        //     newErrors.PrdAllerg = "Allergens is Required";
        //     isValid = false;
        // }
      

        setErrors(newErrors);
        return isValid;
    
        
    }
   
  return (
    <Popup
            extClass="order-pop info-poup"
            headMoreTxt={
                <>
                    <div className="order-top">
                    <div className="head">
                    <h4>Edit Account Details</h4>
                    </div>
                   
                    </div>
                </>
            }
            bodyMoreTxt={
                ( isLoading || props.editloading) ? (
                    <div className='loading-buffer'></div>
                  ):( 
                        <form className="form-field bottom account-details" onSubmit={(e)=>editSubmit(e)}>
                            <div className="input-field">
                            <span className="labeltxt">Name</span>
                                <input type='text'  name="adminName" value={formData.adminName} onChange={handleValue} />
                                {errors.adminName && <div className='error-msg'>{errors.adminName}</div>}

                            </div>
                           
                            <div className="input-field">
                            <span className="labeltxt">Email Address</span>
                                <input type='text'  name="adminEmail" value={formData.adminEmail} onChange={handleValue} />
                                {errors.adminEmail && <div className='error-msg'>{errors.adminEmail}</div>}
                            </div>
                           
                            <div className="input-field">
                            <span className="labeltxt">Status</span>
                                <select name="adminStatus" ref={activeRef}  onChange={handleValue}>
                                    <option value="">Select</option>
                                    <option value="active">Active</option>
                                    <option value="inactive">InActive</option>
                                </select>
                                {errors.adminStatus && <div className='error-msg'>{errors.adminStatus}</div>}
                            </div>
                           
                            
                            <div className="input-field">
                            <span className="labeltxt">Roles</span>
                               <select name='adminRole' ref={roleRef} onChange={handleValue}>
                                    <option value="">Select</option>
                                    {roles.map(code => (
                                    <option key={code._id} value={code._id}>{code.roleName}</option>
                                ))}
                               </select>
                            </div>
                            
                           

                            <div className="btn-field btn-center"><button type="submit">Submit</button>&nbsp;&nbsp;<button onClick={(e)=>props.popupclose(e,false,'')}>Cancel</button></div>          
                        </form>
                  )
            }
                              
            closebtn={<><span onClick={(e)=>props.popupclose(e,false,'')} className='close'></span></>}

           
        />
    
  );
}

export default editAccountDetails
