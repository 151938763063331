import React, { Component, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper';
 
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import ShowAlert from '../common/Showalert';

const Editallergen=()=> {
  
    const [allergens_txt, setAllergens_Txt] = useState([]);
    const [preallergens_txt, setPreAllergens_Txt] = useState([]);
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
    const [allergenslist, setAllergenslist] = useState([]);
    const params = useParams();

    const routeChange = () =>{ 
        window.location.href='/allergens'
    }

    
  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
  }
  if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }
    useEffect(()=>
    {
     var loginDetails = {};
       if (sessionStorage.getItem('authData')) {
          loginDetails = JSON.parse(sessionStorage.getItem('authData'));
      }
    let URL = BaseUrl + `admin/allergen/${params.id}`;
    http.get(URL).then((response) => {
        let responseData = response.data.data;
        setAllergens_Txt(responseData[0].allergenTitle);
        setPreAllergens_Txt(responseData[0].allergenTitle);
        //setHolidayTitle(responseData.title);
              
    }).catch((err) => {
        console.log(err);
        
    }) 
    
    let URL1 = BaseUrl + "admin/allergenlist";
    http.get(URL1).then((response) => {
        let responseData = response.data.data;
        setAllergenslist(responseData);
    }).catch((err) => {
        console.log(err);
    })

},[])
   
const btnsubmit = (e) =>
{
    e.preventDefault();
    
    const isValid = formvalidation();

  if(isValid){
    let allergenstxt = allergens_txt.trim().toUpperCase()
    let payload = {
        allergenid: params.id,
        allergenTitle   : allergenstxt,
        
     }
     if(preallergens_txt != allergenstxt)
     {

     var isFound = allergenslist.some(element => {
        if (element.allergenTitle === allergenstxt) {
          return true;
        }
    
        return false;
      });
     }
     else{

        var isFound = false;
     }
      if(isFound)
      {
        setAlertErrorPopup(true);
        setAlerterrMessage('This Allergen has already been added'); 
      }
      else
      {
      var loginDetails = {};
     if (sessionStorage.getItem('authData')) {
        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
     }
     let URL = BaseUrl + "admin/allergen/";
     http.put(URL, payload).then(async (response) => {
           let responseData = response.data.data;
           if (response.data.success === true) {
            setAlertPopup(true);
            setAlertMessage('Allergen has been updated successfully'); 
             }
      }).catch((err) => {
         console.log(err);
         
      })
    }
  }
      
}

const formvalidation = () =>
{
    
    const errmessage = {};
    const errmsg = {};
    let isValid = true;
    if(allergens_txt == '' )
    {
        errmessage.allergenstxterr = "This field is required";
        errmsg.allgtxt = true;
        isValid = false;
    }
   
   
    
    if(isValid)
    {
        errmessage.allergenstxterr = '';
        
        
        errmsg.allgtxt = false;
        //errmsg.moamt = false;
      
        setErrMessage(errmessage);
        return isValid;
    }
    else{
        
        setErrMessage(errmessage);
        setErrormsg(errmsg);
        
        return isValid;
    }
    
}

const handleOnChange = (e) =>{
    const re = /^[a-zA-Z][a-zA-Z ]*$/;
if(e.target.name ==="allergens_txt")
{
    if (e.target.value === '' || re.test(e.target.value)) {
    setAllergens_Txt(e.target.value);
    }
    if(errmessage.allergenstxterr != '')
    {
       errormsg.allgtxt = false;
    }
    
    setErrormsg(errormsg);
}

}
return (
<section className="dashboard-block">
{ alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
  <Sidebar />

<div className="dash-left">
<Header/>
    <section className="cart-block">
     <div className="container account-details">
        <h1 className="checkout-title">Edit Allergen</h1>

        <div className="right-table-outer-wrap">
        <div className='form-field freeproduct-fieldset allergens-fieldset form-container-xs'>
                    <form className='client-detail-form'>
                        <div className='input-field col-1'>
                            <label for='name'>Allergen</label>
                            <input type='text' name='allergens_txt' value={allergens_txt} onChange={handleOnChange} />
                            {errormsg.allgtxt && <div style={{color:"red"}}>{errmessage.allergenstxterr}</div> }  
                            {/* <div>Note - Multiple allergens can be added using comma (,) separator </div> */}
                        </div>
                       
                        <div className='btnfield' >
                        <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                        &nbsp;&nbsp;&nbsp;
                        <button type="button" onClick={routeChange}>Cancel</button>
                        </div>
                       
                        
                    </form>
                </div>
       </div>
     </div>
     <Footer/> 
    </section>
   
        </div>
      
    </section>
)
}
export default Editallergen