import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import { http } from '../helper';

const AddFreeproductspdf = () => {

  const [inputFieldtitle, setInputFieldtitle] = useState([]);
  const [fileinput, setFileInput] = useState([]);
  const [bannerImage, setBannerImage] = useState(null); // for banner image file
  const [bannerImagePreview, setBannerImagePreview] = useState(null); // for banner image preview
  const [errormsg, setErrormsg] = useState({});
  const [errmessage, setErrMessage] = useState({});
  const [alertPopup, setAlertPopup] = useState(false);
  const [alertMessage, setAlertMessage] = useState([]);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFeatured, setIsFeatured] = useState(true);  // For featured checkbox
  const [sortOrder, setSortOrder] = useState(1);  
  const [status, setStatus] = useState('active');   

  if (!sessionStorage.getItem("isLoggedIn")) {
    window.location.href = '/';
  }
  if (JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_newproducts')) {
    window.location.href = '/unauthorized';
  }

  function cancelbtn(e) {
    e.preventDefault();
    window.location.href = '/newproducts';
    return false;
  }

  const formvalidation = () => {

    const errmessage = {};
    const errmsg = {};
    let isValid = true;

    if (inputFieldtitle == '') {
      errmessage.inputFieldtitleerr = "This field is required";
      errmsg.inptitletxt = true;
      isValid = false;
    }

    if (document.getElementById("pdf_file").files.length == 0) {
      errmessage.fileinputerr = "This field is required";
      errmsg.fileinptxt = true;
      isValid = false;
    } else {
      if (!hasExtension('pdf_file', ['.pdf'])) {
        errmessage.fileinputerr = "Only PDF files are allowed!!!";
        errmsg.fileinptxt = true;
        isValid = false;
      }
      else{
        errmsg.fileinptxt = false;
      }

    }

    // if (!hasExtension('banner_file', ['.jpg', '.jpeg', '.png'])) {
    //   errmessage.bannerImageErr = "Only image files (.jpg, .jpeg, .png) are allowed";
    //   errmsg.bannerImageTxt = true;
    //   isValid = false;
    // }

    if (isValid) {
      setErrMessage({});
      setErrormsg({});
      return true;
    } else {
      setErrMessage(errmessage);
      setErrormsg(errmsg);
      return false;
    }

  }



  const submitButton = (e) => {

    e.preventDefault();
    const isValid = formvalidation();

    if (isValid) {
      uploadFile(fileinput,bannerImage);
    }

  }

  const eventhandlechange = (e) => {
     setInputFieldtitle(e.target.value);
     if (errmessage.inputFieldtitleerr) {
        setErrMessage(prevState => ({ ...prevState, inputFieldtitleerr: '' }));
     }
  }

  const handleFileUpload = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (event.target.id === 'pdf_file') {
      setFileInput(file);
      if (errmessage.fileinputerr) {
        setErrMessage(prevState => ({ ...prevState, fileinputerr: '' }));
      }
    } else if (event.target.id === 'banner_file') {
      setBannerImage(file);
      setBannerImagePreview(URL.createObjectURL(file)); // Preview the uploaded image
      if (errmessage.bannerImageErr) {
        setErrMessage(prevState => ({ ...prevState, bannerImageErr: '' }));
      }
    }
    
  };

  const hasExtension = (inputID, exts) => {
    var fileName = document.getElementById(inputID).value;
    return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName);
  }

  const uploadFile = async (file, bannerFile) => {
    setIsLoading(true);
    try {
      var loginDetails = {};
      if (sessionStorage.getItem('authData')) {
        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
      }
      const formData = new FormData(); // Create a FormData object
      formData.append("pdf", file); // Append the file to the FormData object
      formData.append('title', inputFieldtitle.trim());
      formData.append("featuredpdf", isFeatured);  // New field for featured checkbox
      formData.append("sortOrder", sortOrder);    // New field for sort order
      formData.append("status", status);   
       // Append banner image only if it exists
    if (bannerFile) {
      formData.append("bannerImage", bannerFile); // Append the banner image
    }
      // Make a POST request to your Node.js server endpoint
      let fileEle = document.getElementById('pdf_file');

      var fileSize = (fileEle.files[0].size / 1024 / 1024).toFixed(2);
      // Check banner file size only if it exists
    let bannerFileSize = bannerFile ? (document.getElementById('banner_file').files[0].size / 1024 / 1024).toFixed(2) : 0;
    

      if (fileSize > 8 || (bannerFile && bannerFileSize > 2)) {
        setIsLoading(false);
        setVisibleAlert(true);
        return false;
      }

      const response = await http.post(BaseUrl + 'admin/addpdf', formData);
      if (response.data.success === true) {
        setIsLoading(false);
        setAlertPopup(true);
        setAlertMessage('New products added successfully');
      }

    } catch (error) {
      // Handle error
      setIsLoading(false);
      setAlertPopup(true);
      setAlertMessage(error);
      console.error('Error uploading file:', error);
    }

  };

  const popupClose = (event) => {

    setVisibleAlert(false);
  }


  return (<section className="dashboard-block">

    {alertPopup && (
      <div className='custom-alert-pop common_alert_popup' style={{ display: "block" }}>
        <div className='custom-alert-container'>
          <div className='custom-alert-wrap'>
            <div className='custom-alert-header'>
              <h4>Alert</h4>
            </div>
            <div className='custom-alert-body'>
              <p>{alertMessage}</p>

              <div className='alert-buttons'>
                <a onClick={(e) => cancelbtn(e)} className="popup-alert-btn button">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>

    )}

    <Sidebar />

    <div className="dash-left">
      <Header />
      <section className="cart-block">
        <div className="container">
          <h1 className="checkout-title">Add New Products</h1>

          <div className="right-table-outer-wrap">
          {(isLoading) ? (
            <div className='loading-buffer'></div>
          ) : (

            <form method='post' className="addform editform freeprod-form freeproduct-fieldset form-container-xs" onSubmit={submitButton}>

              <div className="input-field col-1">
                <label>Title</label>
                <input type="text" value={inputFieldtitle} name="inputFieldtitle" onChange={eventhandlechange} className="form-control" placeholder="Title" />
                {errormsg.inptitletxt && <div className="error-msg">{errmessage.inputFieldtitleerr}</div>}
              </div>

              <div className="input-field col-1">
                <label>PDF</label>
                <span className="text-note"> (Maximum Upload File Size: 8 MB)</span>
                <div className="flex align-center gap-10">
                  <input className='col-2' type="file" id="pdf_file" accept=".pdf" onChange={handleFileUpload} />
                  <span className="text-note">(*.pdf files only allowed)<br /><br /></span>
                </div>
                
                {errormsg.fileinptxt && <div className="error-msg">{errmessage.fileinputerr}</div>}
              </div>

              <div className="input-field col-1">
                    <label>Banner Image</label>
                    <span className="text-note"> (Maximum Upload File Size: 2 MB)</span>
                    <div className="flex align-center gap-10">
                    <input className='col-2' type="file" id="banner_file" accept=".jpg,.jpeg,.png" onChange={handleFileUpload} />
                    <span className="text-note">(*.jpg, .jpeg, .png files only allowed)<br /><br /></span>
                    </div>
                    {errormsg.bannerImageTxt && <div className="color-red">{errmessage.bannerImageErr}</div>}
              </div>
              {bannerImagePreview && (
                    <div className="input-field col-1">
                        <img src={bannerImagePreview} alt="Banner Preview"/>
                    </div>
                  )}
             
              {/* Featured Checkbox */}
              <div className="input-field col-1">
              <label>Display on Main Page</label>
              <div className="check-box">
                  <input type="checkbox" id='setImg' className='check-box' checked={isFeatured} onChange={(e) => setIsFeatured(e.target.checked)} />
                  <label for='setImg'>Yes</label>
                 </div>
              </div>

              {/* Sorting Input */}
              <div className="input-field col-3">
                <label className='full-width'>Sort Order</label>
                <input type="number" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} min="1" placeholder="Order (1 for top priority)" />
              </div>

                {/* Status Dropdown */}
              <div className="input-field col-1">
                <label className='full-width'>Status</label>
                <select className='col-4' value={status} onChange={(e) => setStatus(e.target.value)}>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>


              <button type='submit'>Submit</button> &nbsp;&nbsp;
              <button onClick={(e) => cancelbtn(e)}>Cancel</button>
            </form>)}
        </div>
        </div>
      </section>
      <Footer />
    </div>

    {visibleAlert === true &&
      <div className='custom-alert-pop' style={{ display: "block" }}>
        <div className='custom-alert-container'>
          <div className='custom-alert-wrap'>
            <div className='custom-alert-header'>
              <h4>Alert</h4>
            </div>
            <div className='custom-alert-body'>
              <p>File size must be less than 8 MB.</p>

              <div className='alert-buttons'>
                <a onClick={popupClose} className="popup-alert-btn button">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>}
  </section>);

}
export default AddFreeproductspdf;