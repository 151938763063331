import React, { Component, useState, useEffect } from 'react'
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper'; 
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import ShowAlert from '../common/Showalert';
const IngredientsAdd = () => {

    const [ingredients_txt, setIngredients_Txt] = useState([]);
   
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
    const [ingredientslist, setIngredientslist] = useState([]);

    const routeChange = () =>{ 
        window.location.href='/ingredients'
    }
    
    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';

    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
        {
          window.location.href = '/unauthorized';
        }
    
    useEffect(()=>{
          var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }
        let URL = BaseUrl + "admin/ingredientlist";
        http.get(URL).then((response) => {
            let responseData = response.data.data;
                     
            setIngredientslist(responseData);
        }).catch((err) => {
            console.log(err);
        })


    },[])
    const btnsubmit = (e) =>
    {
        e.preventDefault();
        
        const isValid = formvalidation();
 
      
          
       if(isValid)
       {
        let ingredientstxt = ingredients_txt.trim().toUpperCase()
        let payload = {
            ingredientTitle   : ingredientstxt,
         }
         
         const isFound = ingredientslist.some(element => {
            if (element.ingredientTitle === ingredientstxt) {
              return true;
            }
        
            return false;
          });
        if(isFound)
        {
            setAlertErrorPopup(true);
            setAlerterrMessage('This Ingredient has already been added'); 

        }
        else
        {
         var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
         }
        let URL = BaseUrl + "admin/ingredient/";
        http.post(URL, payload).then(async (response) => {
                
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage('Ingredient has been added successfully'); 
                }
            }).catch((err) => {
            console.log(err);
            
            })
        }
       }
        
    }

    const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        if(ingredients_txt == '' )
        {
            errmessage.ingredientstxterr = "This field is required";
            errmsg.ingretxt = true;
            isValid = false;
        }
       
       
        
        if(isValid)
        {
            errmessage.ingredientstxterr = '';
            
            
            errmsg.ingretxt = false;
            //errmsg.moamt = false;
          
            setErrMessage(errmessage);
            return isValid;
        }
        else{
            
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            
            return isValid;
        }
        
    }

  const handleOnChange = (e) =>{
    const re = /^[a-zA-Z][a-zA-Z ]*$/;
    if(e.target.name ==="ingredients_txt")
    {
        if (e.target.value === '' || re.test(e.target.value)) {
        setIngredients_Txt(e.target.value);
        }
        if(errmessage.ingredientstxterr != '')
        {
           errormsg.ingretxt = false;
        }
        
        setErrormsg(errormsg);
    }
   
  }
  return (
    
    <section className="dashboard-block">
        { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
            <Sidebar />
    
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container account-details">
            <h1 className="checkout-title">Add Ingredient</h1>

            <div className="right-table-outer-wrap">
                <div className='form-field freeproduct-fieldset ingredients-fieldset form-container-xs'>
                        <form className='client-detail-form'>
                            <div className='form-field'>
                                <label for='name'>Ingredient</label>
                                <input type='text' name='ingredients_txt' value={ingredients_txt} onChange={handleOnChange} />
                                {errormsg.ingretxt && <div style={{color:"red"}}>{errmessage.ingredientstxterr}</div> }  
                                {/* <div>Note - Multiple Ingredients can be added using comma (,) separator </div> */}
                            </div>
                           
                            <div className='btnfield'>
                            <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" onClick={routeChange}>Cancel</button>
                            </div>
                           
                            
                        </form>
                    </div>
                </div>
         </div>
         <Footer/> 
        </section>
       
            </div>
          
        </section>
  )
}

export default IngredientsAdd;