import React, { Component } from 'react';
import styles from './popup.module.css';

class Popup extends Component {

    constructor(props){
        super(props)
        this.state = {

        }
    } 

    toggleModel() {
        this.setState({
            visible: this.props.toggleOpen
        });
    }

    render() {

        return(
            <>

                        <div className={`modal ${styles.popupsec} ${this.props.extClass}`}>
                            <div className={styles.popupBlock}>
                                <div className={styles.popupInnr}>
                                     {this.props.closebtn}
                                    <div className={styles.popupHead}>
                                        <h3 className={styles.popupTitle}>{this.props.title}</h3>
                                        {this.props.headMoreTxt}
                                    </div>
                                    <div className={styles.popupBody}>
                                        {this.props.bodyMoreTxt}
                                    </div>
                                    <div className={styles.popupFoot}>{this.props.footMoreTxt}</div>
                                </div>
                            </div>
                            <div className={styles.popupOvly}></div>
                        </div>

            </>
        );

    }

}

export default Popup;