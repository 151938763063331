import React from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import Sidebar from '../common/sidebar';
const Unauthorized = () => {
    //return <h1>Unauthorized - You do not have access to this page</h1>;
    return (<section className="dashboard-block">
 
        
                <Sidebar />
     
        <div className="dash-left">
        <Header/>
            <section className="cart-block">
            <div className="container">
                <div className='unauthorized-wrap'>
                <p><i className="fa fa-exclamation-triangle"></i> Access Denied for this page, Please contact Tugo administrator</p>
                </div>
            
            </div>
    
            </section>
            <Footer/>
                </div>
               
            </section>);
    
    
};

export default Unauthorized;