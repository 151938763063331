import React, { useState,useEffect } from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import Loader from '../common/loader';
import { http } from '../helper'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';
const Stripepaymentkey = () =>{
    
    const [isChecked, setIsChecked] = useState(false);
    const [devpaymentkey_publish, setDevPaymentKeyPublish] = useState([]);
   
    const [livepaymentkey_publish, setLivePaymentKeyPublish] = useState([]);
    
    const [loading,setLoading] = useState(true);
    const [errormsg,setErrormsg] = useState({});
    const [errmessage, setErrMessage] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);


    if(!sessionStorage.getItem("isLoggedIn")){
        window.location.href = '/';
    }

    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
        {
          window.location.href = '/unauthorized';
        }

    useEffect(()=>{
        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
           loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        } 
        let URL = BaseUrl + "admin/settings";
        http.get(URL).then((response) => {
            let responseData = response.data.data;
            
            if(response.data.success === true)
            {
                setLoading(false);
                
                if(responseData[0].stripe_dev_enable == '1')
                {
                    setIsChecked(true); // for dev it is true
                }
                else if(responseData[0].stripe_live_enable == '1'){
                    setIsChecked(false); // for live it is false  
                }
                setDevPaymentKeyPublish(responseData[0].stripe_dev_publish_id);
                //setDevPaymentKeySecret(responseData[0].stripe_dev_client_secret_id);
                setLivePaymentKeyPublish(responseData[0].stripe_live_publish_id);
                //setLivePaymentKeySecret(responseData[0].stripe_live_client_secret_id);
                setAlertPopup(false); 
            }
                    
        }).catch((err) => {
            console.log(err);
            
        })  
    },[])

    const btnsubmit = (e) =>
    {
        e.preventDefault();
        const isValid = formvalidation();
        setAlertPopup(false);
      

        
        
         if(isValid)
           {  
            var stripe_dev_enable,stripe_live_enable = '0';
            var paymentkeypublish_dev,paymentkeysecret_dev,paymentkeypublish_live,paymentkeysecret_live = '';
    
            if(isChecked === true){
    
                stripe_dev_enable = 1;
                // paymentkeypublish_dev = devpaymentkey_publish;
                // paymentkeysecret_dev = devpaymentkey_secret;
                stripe_live_enable = 0;
                //paymentkey_live = '';
            }
            else if(isChecked === false){
                stripe_live_enable = 1;
                // paymentkeypublish_live = livepaymentkey_publish;
                // paymentkeysecret_live = livepaymentkey_secret;
                stripe_dev_enable = 0;
                //paymentkey_dev = '';
            }
            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
            let payload = {
                stripe_dev_enable: stripe_dev_enable,
                stripe_live_enable:stripe_live_enable,
                stripe_live_publish_id: livepaymentkey_publish,
                //stripe_live_client_secret_id: livepaymentkey_secret,
                stripe_dev_publish_id: devpaymentkey_publish,
                //stripe_dev_client_secret_id: devpaymentkey_secret,
            }
           
           let URL = BaseUrl + "admin/settings";
           http.post(URL, payload).then(async (response) => {
                 let responseData = response.data.data[0];
                 if (response.data.success === true) {
                    
                    if(responseData.stripe_dev_enable === '1')
                    {
                        setIsChecked(true); // for dev it is true
                       
                        setDevPaymentKeyPublish(responseData.stripe_dev_publish_id);
                        
                    }
                    else if(responseData.stripe_live_enable == '1'){
                        setIsChecked(false); // for live it is false
                        setLivePaymentKeyPublish(responseData.stripe_live_publish_id);
                        //setLivePaymentKeySecret(responseData.stripe_live_client_secret_id);
                    }
                   
                   
                    setAlertPopup(true);
                    setAlertMessage('Stripe Payment Key have been updated successfully'); 
                    //window.location = '/social-links';
                   }
            }).catch((err) => {
               console.log(err);
               
            })
        }
    }

    const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        
        if(isChecked === true)
        {

            if(devpaymentkey_publish.trim() === '')
            {
                errmessage.devpaymentkeyerr = "This field is Required";
                errmsg.devpaykey = true;
                isValid = false;
            }
            
        }

        if(isChecked === false)
        {

            if(livepaymentkey_publish == undefined || livepaymentkey_publish == '')
            {
                errmessage.livepaymentkeyerr = "This field is Required";
                errmsg.livepaykey = true;
                isValid = false;
            }
            
           

        }
       
      

        if(isValid)
        {
            errmessage.devpaymentkeyerr = '';
            
            errmessage.livepaymentkeyerr = '';
            
            errmsg.devpaykey = false;
            
            errmsg.livepaykey = false;
           
            setErrMessage(errmessage);
            return isValid;
        }
        else{
            
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            
            return isValid;
        }

       
        
    }

  const handleOnChange = (e) =>{
     
    if(e.target.name ==="devpaymentkey_publish")
    {
        setDevPaymentKeyPublish(e.target.value.replace(/\s+/g, ''));
        if(errmessage.devpaymentkeyerr != '')
        {
           errormsg.devpaykey = false;
        }
        
        setErrormsg(errormsg);
    }
    
    if(e.target.name ==="livepaymentkey_publish")
    {
        setLivePaymentKeyPublish(e.target.value.replace(/\s+/g, ''));
        if(errmessage.livepaymentkeyerr != '')
        {
           errormsg.livepaykey = false;
        }
        
        setErrormsg(errormsg);
    }

    if(e.target.name ==="stripemode")
    {
        setIsChecked(!isChecked);
        
    }
    
    setAlertPopup(false);
  }

    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
            <Sidebar />
    
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container">
            <h1 className="checkout-title">Stripe Payment key</h1>
            <div className="right-table-outer-wrap">
            {!loading && 
                
                <div className="form-field freeproduct-fieldset social-links-fieldset form-container-xs">
                    
                <div className='input-field col-1'> 
                    <label for="stripemode">Stripe Mode</label>
                    <div className="switch-button stripe-mode">
                        <input type="checkbox" className="checkbox" name="stripemode" id="stripemode" checked={isChecked}
                    onChange={handleOnChange} />
                        <div className="knobs">
                            <span></span>
                        </div>
                        <div className="layer"></div>
                    </div>
                </div>
                {isChecked &&  
              
                <div className='input-field col-1'> 
                <label for="stripepayment">Test Publishable Key </label>
                    <input type='text' name="devpaymentkey_publish" value={devpaymentkey_publish} onChange={handleOnChange}/>
                    {errormsg.devpaykey && <div style={{color:"red"}}>{errmessage.devpaymentkeyerr}</div> }
                </div>
              
                 }
                
                {!isChecked && 
                    <div className='input-field col-1'>  
                        <label for="stripepayment">Live Publishable Key </label>               
                        <input type='text' name="livepaymentkey_publish" value={livepaymentkey_publish} onChange={handleOnChange}/>
                        {errormsg.livepaykey && <div style={{color:"red"}}>{errmessage.livepaymentkeyerr}</div> }
                    </div>  
                }
                
                   <div className='btnfield'>
                    <button type="submit" name="btnsubmit" onClick={btnsubmit}>Submit</button>
                    </div>
          
               
                </div>
            }
            { loading && <Loader/>


            }
           </div>
         </div>

        </section>
        <Footer/>
          
            </div>
           
        </section>);

}
export default Stripepaymentkey;