import React, { useState, useEffect } from 'react';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import { http } from '../helper';

const Settings = () =>{

    const [isChecked, setIsChecked] = useState(false);
    const [alertPopup,setAlertPopup] = useState(false);
    
    const [visibleAlert,setVisibleAlert] = useState(false);

  
    if(!sessionStorage.getItem("isLoggedIn")){
        window.location.href = '/';
    }
    if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
        {
          window.location.href = '/unauthorized';
        }

    const handleOnChange = () => {
      
      setVisibleAlert(true);
      setIsChecked(!isChecked);
      setAlertPopup(false);

    };

    useEffect(()=>{

        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }

        let URL = BaseUrl + "admin/settings";
        http.get(URL).then((response) => {
            let responseData = response.data.data;
           
            if(response.data.success === true)
            {
                 
                if(responseData[0].maintananceMode == '1')
                {
                    setIsChecked(true);
                }
                else{
                    setIsChecked(false); 
                }
            }
                    
        }).catch((err) => {
            console.log(err);
            
        })  
    },[])
    const alertPopupclose = () =>
    {
        setIsChecked(!isChecked);
        setVisibleAlert(false);
       
    }
    const btnsubmit = () =>
    {
       
        var maintenance_checked = 0;

        if(isChecked === true){

            maintenance_checked = 1;
        }

        let payload = {
            maintananceMode: maintenance_checked,
           }

        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }          
           let URL = BaseUrl + "admin/settings";
           http.post(URL, payload).then(async (response) => {
                 
                  
                 if (response.data.success === true) {
                    setVisibleAlert(false);
                    
                   }
             }).catch((err) => {
               console.log(err);
               
             })
    }
   
    return (<section className="dashboard-block">

    
            <Sidebar />
    
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container">
            <h1 className="checkout-title">Website Maintanance</h1>
            <div className="checkout-table">
            <div className="input-group">
               
                <div className='switch-button-holder'>
                    <label>Website Maintenance</label>
                    <div className="switch-button">
                        <input type="checkbox" className="checkbox" name="sitemaintenance" id="sitemaintenance" checked={isChecked}
                    onChange={handleOnChange} />
                        <div className="knobs">
                            <span></span>
                        </div>
                        <div className="layer"></div>
                    </div>
                </div>
                               
            </div>
           </div>
         </div>

        </section>
        <Footer/>
            </div>
            {visibleAlert === true && 
                <div className='custom-alert-pop paylater-popup'>
                    <div className='custom-alert-container'>
                        <div className='custom-alert-wrap'>
                            <div className='custom-alert-header'>
                                <h4>Alert</h4>
                            </div>
                            <div className='custom-alert-body'>
                                <p>Are you sure you want to switch {(isChecked)?'ON':'OFF'} the website maintenance setting?</p>

                                <div className='alert-buttons'>

                                    <a onClick={btnsubmit} className="popup-alert-btn button" >Yes</a>
                                    <a onClick={alertPopupclose} className="popup-alert-btn button">No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} 
        </section>);

}
export default Settings;