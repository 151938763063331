import React, { Component } from 'react';
import styles from '../Layout.module.css';
import { Link, Navigate } from "react-router-dom";
import Logo from '../../assets/images/logo.png';
import LoginImg from '../../assets/images/login-ico.png';
import DarkLoginImg from '../../assets/images/dark-login-ico.png';
 
class Header extends Component {


    constructor(props) {
        super(props);
        this.state = {
        products:[],
        loading:false,
        tempProducts:[],
        payBool:false,
        adminName:(JSON.parse(sessionStorage.getItem('authData'))?JSON.parse(sessionStorage.getItem('authData')).adminName:""),
        roleName:(JSON.parse(sessionStorage.getItem('authData'))?JSON.parse(sessionStorage.getItem('authData')).adminRole.roleName:""),
        rolePermissions:(JSON.parse(sessionStorage.getItem('authData'))?JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions:""),
        cartCount:0
        };
        
        }
   
        logout(e)
        {
            sessionStorage.clear();
            window.location.href='/';
        }
       

    componentDidMount() {
        document.body.classList.add("split");
    }
        
    
    render(){  
        return(
            <>
        <header className= {(window.location.hostname === 'dev-tugoadmin.ezzysales.com')?'dev-portal':''}>
            
            <div className="sm-container">
                
                <div className={styles.headFlx}>
                    <Link to={this.state.adminName ? "/products" : '/'} className={styles.logo}>
                        <img src={Logo} alt="Tugo" />
                    </Link>
                    <p className={styles.headTxt}>
                        Online Ordering Portal
                        <span>Backend Panel</span>
                    </p>
                    <div className={styles.headRight}>
                           <div className={styles.rgtFlx}>
                           {
                            this.state.adminName && 
                           (
                            <><span className={styles.headLog}>
                            <Link to='/changepassword'><i className={`fa fa-key ${styles.ico}`}></i> Change Password   </Link> |
                            </span> <span className={styles.headLog} onClick={(e)=>this.logout(e)}>
                             <i className={`fa fa-sign-out ${styles.ico}`}></i>
                                Logout
                            </span> </>)
                            }
                            {/* {!this.state.adminName && 
                            <Link to="/">
                                <span className={styles.headLog}>
                                Login <img className={styles.nrm} src={LoginImg} alt="Log in" />
                                    <img className={styles.hov} src={DarkLoginImg} alt="Log in" />
                                </span>
                            </Link>
                            } */}
                        </div>
                        
                        {/* Login In End */}
                        {/* <a href="tel:02195367351" className={styles.headLink}>
                            01295 367 351
                        </a> */}

                    </div>
                </div>

            </div>
        </header>
        </>
        );
    }
}
export default Header;