import React, { useState} from 'react';
import { BaseUrl } from '../common/config';
import { http } from '../helper';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import Sidebar from '../common/sidebar';
import CommonAlert from '../common/Commonalert';


const Changepassword =()=>{

const [npassword,setNpassword] = useState([]);
const [cpassword,setCpassword] = useState([]);
const [cnpassword,setCNpassword] = useState([]);
const [errmessage, setErrMessage] = useState({});
const [alertPopup,setAlertPopup] = useState(false);
const [alertMessage,setAlertMessage] = useState([]);
const [errormsg,setErrormsg] = useState({});
const [validationMessage, setValidationMessage] = useState('');
const [passwordFocused, setPasswordFocused] = useState(false);
const [showCurrentPassword, setshowCurrentPassword] = useState(false);   
const [showNewPassword, setshowNewPassword] = useState(false);   
const [showConfirmPassword, setshowConfirmPassword] = useState(false);   
const [criteria, setCriteria] = useState({
  length: false,
  upperCase: false,
  lowerCase: false,
  number: false,
  specialChar: false
});

if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
}


 /*Added for View Password icon*/
    const toggleCurrentPasswordVisibility = () => {
      setshowCurrentPassword(!showCurrentPassword);
    };
   const toggleNewPasswordVisibility = () => {
    setshowNewPassword(!showNewPassword);
    };
   const toggleConfirmPasswordVisibility = () => {
    setshowConfirmPassword(!showConfirmPassword);
    };
 /*Added for View Password icon*/
    const validatePassword = (password) => {
        const lengthCriteria = /.{8,}/;
        const upperCaseCriteria = /[A-Z]/;
        const lowerCaseCriteria = /[a-z]/;
        const numberCriteria = /[0-9]/;
        const specialCharacterCriteria = /[!@#$%^&*(),.?":{}|<>_\[\]-]/;
    
        setCriteria({
          length: lengthCriteria.test(password),
          upperCase: upperCaseCriteria.test(password),
          lowerCase: lowerCaseCriteria.test(password),
          number: numberCriteria.test(password),
          specialChar: specialCharacterCriteria.test(password)
        });

        let message = 'Password must include: ';
        let isValid = true;
    
        if (!lengthCriteria.test(password)) {
          message += 'at least 8 characters, ';
          isValid = false;
        }
        if (!upperCaseCriteria.test(password)) {
          message += 'one uppercase letter, ';
          isValid = false;
        }
        if (!lowerCaseCriteria.test(password)) {
          message += 'one lowercase letter, ';
          isValid = false;
        }
        if (!numberCriteria.test(password)) {
          message += 'one number, ';
          isValid = false;
        }
        if (!specialCharacterCriteria.test(password)) {
          message += 'one special character, ';
          isValid = false;
        }
    
        if (isValid) {
          setValidationMessage('Password is strong.');
        } else {
          setValidationMessage(message.slice(0, -2) + '.');
        }
      };

const changepwdsubmit = (e) =>{
  e.preventDefault();
  const isValid = formvalidation();
  if(isValid)
  {   
    let URL = BaseUrl + "admin/changepassword";
    let payload = {
        cpassword: cpassword.trim(),
        npassword: npassword.trim(),
        emailaddress: JSON.parse(sessionStorage.getItem('authData')).adminEmail,
        
       //status:status,
       }

     var loginDetails = {};
     if (sessionStorage.getItem('authData')) {
        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
     }
      
     http.post(URL,payload).then((response) => {
        
        if (response.data.success === true) {
          setAlertPopup(true);
          setAlertMessage("Password has been changed successfully!");
        
         
          
        }
      
    }).catch((err) => {
        console.log(err);
        if (err.response) {   
            if(err.response.status === 400){
                const apierror = {};
                const errmsg ={};
                errmsg.cpass = true;
                apierror.cpassworderr = 'The Current Password entered is incorrect';
                 
                setErrMessage(apierror);
                setErrormsg(errmsg);

            }
            
        }
        
        
    })  
     
  }
}
const handlePasswordFocus=()=> {
 setPasswordFocused(true);
}

const handlePasswordBlur=()=> {
  setPasswordFocused(false);
}
const formvalidation = () =>
    {
        
        const errmessage = {};
        const errmsg = {};
        let isValid = true;
        
        if(npassword == '' )
        {
            errmessage.npassworderr = "This field is required";
            errmsg.npass = true;
            isValid = false;
        } else {
            validatePassword(npassword);
            if (validationMessage !== 'Password is strong.') {
              
              setPasswordFocused(true);
              isValid = false;
            }
          }
       
        if(cpassword == '' )
        {
            errmessage.cpassworderr = "This field is required";
            errmsg.cpass = true;
            isValid = false;
        }
        if(cnpassword == '' )
        {
            errmessage.cnpassworderr = "This field is required";
            errmsg.cnpass = true;
            isValid = false;
        }
         // Check if new password and current password are the same
        if (npassword !== '' && npassword === cpassword) {
            errmessage.npassworderr = "New password cannot be the same as the current password";
            errmsg.npass = true;
            isValid = false;
        }
        
        if(cnpassword != '')
        {
            if(cnpassword !== npassword)
            {
                errmessage.cnpassworderr = "New password and Confirm New Password should be same";
                errmsg.cnpass = true;
                isValid = false;
            }
        }

        if(isValid)
        {
            errmessage.npassworderr = '';
            errmessage.cpassworderr = '';
            errmessage.cnpassworderr='';
            errmsg.cnpass = false;
            errmsg.npass = false;
            errmsg.cnpass = false;
            setErrMessage(errmessage);
            return isValid;
        }
        else{
            
            setErrMessage(errmessage);
            setErrormsg(errmsg);
            
            return isValid;
        }

       
        
    }

     

const handlepass = (e) =>
{
    if(e.target.name === 'npassword')
    {
        setNpassword(e.target.value.replace(/\s+/g, ''));
        validatePassword(e.target.value.replace(/\s+/g, ''));
        if(errmessage.npassworderr != '')
        {
           errormsg.npass = false;
        }
        
        setErrormsg(errormsg);
    }

    if(e.target.name === 'cpassword')
    {
        setCpassword(e.target.value.replace(/\s+/g, ''));
        if(errmessage.cpassworderr != '')
        {
           errormsg.cpass = false;
        }
        
        setErrormsg(errormsg);
    }
    if(e.target.name === 'cnpassword')
    {
        setCNpassword(e.target.value.replace(/\s+/g, ''));
        if(errmessage.cnpassworderr != '')
        {
           errormsg.cnpass = false;
        }
        
        setErrormsg(errormsg);
    }
    
}
 
    return (<section className="dashboard-block">
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
            <Sidebar />
   
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container">
         
                <div className="head">
                <h1 className="checkout-title">Change Password</h1>
                </div>
               
                <div className="right-table-outer-wrap">
                <div className="form-field freeproduct-fieldset changepwd-fieldset form-container-xs">
                    {/* <div style={{color:"red"}}>{errmessage.pwderr}</div> */}
                    <div className='input-field col-1'> 
                        <label for="cpassword">Current Password</label>
                        <input type={showCurrentPassword  ? 'text' : 'password'} name="cpassword" value={cpassword} onChange={handlepass}/>
                        {errormsg.cpass && <div className='error-msg'>{errmessage.cpassworderr}</div> }
                        <span className='icon-eye' onClick={toggleCurrentPasswordVisibility}>
                          <i
                          className={`fa ${showCurrentPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                          ></i>
                        </span>
                    </div>

                    <div className='input-field col-1'> 
                        <label for="npassword">New Password</label>
                        <input type={showNewPassword  ? 'text' : 'password'} name="npassword" value={npassword} onFocus={handlePasswordFocus}
                    onBlur={handlePasswordBlur} onChange={handlepass}/>
                        {errormsg.npass && <div className='error-msg'>{errmessage.npassworderr}</div> }
                        {passwordFocused && npassword && (<div className='chg-pwd-lgd'>
                         <ul className='chg-pwd-list'>
                          <li className={ criteria.length ? 'checked' : 'unchecked' }>At least 8 characters</li>
                          <li className={ criteria.upperCase ? 'checked' : 'unchecked' }>One uppercase letter</li>
                          <li className={ criteria.lowerCase ? 'checked' : 'unchecked' }>One lowercase letter</li>
                          <li className={ criteria.number ? 'checked' : 'unchecked' }>One number</li>
                          <li className={ criteria.specialChar ? 'checked' : 'unchecked'}>One special character</li>
                         </ul>

                        </div>)}
                        <span className='icon-eye' onClick={toggleNewPasswordVisibility}>
                          <i
                          className={`fa ${showNewPassword  ? 'fa-eye-slash' : 'fa-eye'}`}
                          ></i>
                        </span>
                    </div>

                    <div className='input-field col-1'> 
                        <label for="cnpassword">Confirm New Password</label>
                        <input type={showConfirmPassword ? 'text' : 'password'} name="cnpassword" value={cnpassword} onChange={handlepass}/>
                        {errormsg.cnpass && <div className='error-msg'>{errmessage.cnpassworderr}</div> }
                        <span className='icon-eye' onClick={toggleConfirmPasswordVisibility}>
                          <i
                          className={`fa ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'}`}
                          ></i>
                        </span>
                    </div>
                    
                    <div className='btnfield'>
                        <button onClick={changepwdsubmit}>Submit</button>
                    </div>
                    
                </div>

            </div>
         </div>

        </section>
        <Footer/> 
            </div>
           
        </section>
   
    );

 
}
export default Changepassword;