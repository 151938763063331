import React, { Component } from 'react';
import Header from '../common/layout/header';
import Footer from '../common/layout/footer';
import DataTable from 'react-data-table-component';
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar';
import { http } from '../helper';
class Freeproducts extends Component {



    constructor(props) {
        super(props);
        this.state = {
            searchTextVal: "",
            searchTextVal: "",
            freeproductslist: [],
            templist: [],
            templist: [],
            loading: true,
            popupView: "",
            visibleAlert: false,
            freeproductvalue: '',
            visibleAlert: false,
            freeproductvalue: '',
        };

        if (!sessionStorage.getItem("isLoggedIn")) {
        if (!sessionStorage.getItem("isLoggedIn")) {
            window.location.href = '/';
        }
        if (JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_freeproducts')) {
            window.location.href = '/unauthorized';
        }
        if (JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_freeproducts')) {
            window.location.href = '/unauthorized';
        }

    }
    }

    getFreeproductlist() {

        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }

        let URL = BaseUrl + "admin/offerlist";
        http.get(URL).then((response) => {
            this.setState({ freeproductslist: response.data.data, loading: false, templist: response.data.data });
            this.setState({ freeproductslist: response.data.data, loading: false, templist: response.data.data });
        }).catch((err) => {
            console.log(err);
        })
    }



    editfreeproductDetails = (editid) => {
        //setEditfreeproduct('edit');

        window.location.href = `/freeproducts/editfreeproducts/${editid}`;

        window.location.href = `/freeproducts/editfreeproducts/${editid}`;
        //window.location.href = `/holiday/edit/${editid}`;
    };
    deleteFree = (value) => {
        this.setState({ visibleAlert: true, freeproductvalue: value });
    }
    deletefreeproducts = () => {
    };
    deleteFree = (value) => {
        this.setState({ visibleAlert: true, freeproductvalue: value });
    }
    deletefreeproducts = () => {

        var loginDetails = {};
        if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }

        let URL = BaseUrl + `admin/offer/${this.state.freeproductvalue._id}`;
        http.delete(URL).then(async (response) => {

           if (response.data.success === true) {

                window.location.reload();
            }



        }).catch((err) => {
            console.log(err);

        });


    }

    alertPopupclose = () => {
        this.setState({ visibleAlert: false, freeproductvalue: '' });
    }

    componentDidMount() {
        this.getFreeproductlist();
    }

    addfreeproduct = () => {
        window.location.href = '/freeproducts/addfreeproducts'
    }

    handleReset = () => {
        this.setState({ searchTextVal: '', freeproductslist: this.state.templist });
    };
    addfreeproduct = () => {
        window.location.href = '/freeproducts/addfreeproducts'
    }

    handleReset = () => {
        this.setState({ searchTextVal: '', freeproductslist: this.state.templist });
    };

    handleChange = async (value) => {
        this.setState({ searchTextVal: value });
        this.filterData(value);
    };


    filterData = async (value) => {

        let accoun = this.state.templist;
       
        const lowercasedValue = value.toLowerCase().trim();
        let result = [];
        if (lowercasedValue.length > 0) {
            accoun.map((item) => {
                if (item.offerTitle.toString().toLowerCase().includes(lowercasedValue)) {
                    result.push(item);
                }
            })
            this.setState({ freeproductslist: result });
        } else {
            this.setState({ freeproductslist: accoun });
        }
    };


    render() {

        const columns = [
            {
                name: "Title",
                selector: row => row.offerTitle,
                sortable: true,
            },
            // {
            //     name: "Category Name",
            //     selector: row => (row.categoryId==="1"?"PIZZA":(row.categoryId==="2"?"PASTA, RICE & NOODLE SAUCES":"BURRITO")),
            //     sortable: true,
            // },   
            {
                name: "Offer @ Qty",
                selector: row => row.productQtyToBeAdded,
                sortable: true,
            },
            {
                name: "Actions",
                selector: row => <div style={{ display: "flex" }}>
                    <a className='editbtn button title-tooltip title-up' data-title='Edit' style={{ color: "white" }} onClick={() => this.editfreeproductDetails(row._id)}><i className="fa fa-pencil" aria-hidden="true"></i></a>
                    {/* <a className='editbtn button title-tooltip title-up' data-title='Delete' style={{ color: "white" }} onClick={() => this.deleteFree(row)}><i className="fa fa-trash" aria-hidden="true"></i></a> */}


                </div>,
                sortable: false,
            }
        ];

        const customStyles = {
            rows: {
                style: {
                    minHeight: '25px',
                },
            },
            headCells: {
                style: {
                    padding: '8px', // override the cell padding for head cells

                },
            },
            cells: {
                style: {
                    padding: '8px', // override the cell padding for data cells

                },
            },
        };

        const paginationComponentOptions = {
            rowsPerPageText: '',
            rangeSeparatorText: '',
            selectAllRowsItem: true,
            selectAllRowsItemText: '',
            noRowsPerPage: '',
        };

        return (
            <>
                <div className="dashboard-block">

                    <Sidebar />

                    <div className="dash-left">
                        <Header />

                        <section className="cart-block">
                            <div className="container">
                                <h1 className="checkout-title">FREE Products Setup</h1>

                                <div className="right-table-outer-wrap adm-free-products">
                                <div className="table-filter search-orders">
                                    <div className='table-search-reset'>
                                        <div className="table-search">
                                            <input type="text" value={this.state.searchTextVal} onChange={e => this.handleChange(e.target.value)} className="form-control" placeholder="Search by Title" />

                                        </div>
                                        <button className='submitbtn button search-ord-btn' onClick={e => this.filterData(this.state.searchTextVal)}><i className="fa fa-search"></i></button>

                                        <button className='resetbtn' type='reset' onClick={this.handleReset}>Reset</button>
                                    </div>

                                    {/* <div className='add-holidaybtn'>
                                <button className='addbtn' onClick={this.addfreeproduct}>Add</button>
                              </div> */}
                                </div>

                                <div className="table_overflow">

                                    <DataTable
                                        columns={columns}
                                        noDataComponent={<div className='no-records-found'>Sorry, there is no FREE Products Setup listing matching your keyword/s, please try a new search</div>}
                                        data={this.state.freeproductslist}
                                        customStyles={customStyles}
                                        pagination
                                        paginationPerPage={25}
                                        progressPending={this.state.loading}
                                        paginationRowsPerPageOptions={[25, 50, 75, 100]}
                                        paginationComponentOptions={paginationComponentOptions}
                                    />

                                </div>


                                </div>
                            </div>
                        </section>

                        <Footer />
                    </div>
                </div>
                {this.state.visibleAlert === true &&
                    <div className='custom-alert-pop paylater-popup'>
                        <div className='custom-alert-container'>
                            <div className='custom-alert-wrap'>
                                <div className='custom-alert-header'>
                                    <h4>Alert</h4>
                                </div>
                                <div className='custom-alert-body'>
                                    <p>Are you sure you want to delete?</p>
                                     <div className='alert-buttons'>
                                        <a onClick={this.deletefreeproducts.bind(this)} className="popup-alert-btn button" >OK</a>
                                        <a onClick={this.alertPopupclose.bind(this)} className="popup-alert-btn button">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                     
            </>
        );
    }
}

export default Freeproducts;