import React, { Component,useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Header from '../common/layout/header';
import Footer from '../common/layout/footer'; 
import { BaseUrl } from '../common/config';
import Sidebar from '../common/sidebar'; 
import moment from 'moment';
import CommonAlert from '../common/Commonalert';
import { http } from '../helper';

const Holidayadd =()=>
{
  
  const [holidayDate,setHolidayDate] = useState(null);
  const [holidayTitle,setHolidayTitle] = useState([]);
  const [errmessage, setErrMessage] = useState({});
  const [holidaystatus,setHolidayStatus] = useState('A');
  const [alertPopup,setAlertPopup] = useState(false);
  const [alertMessage,setAlertMessage] = useState([]);
 

  if(!sessionStorage.getItem("isLoggedIn")){
    window.location.href = '/';
}
if(JSON.parse(sessionStorage.getItem('authData')) != '' && !JSON.parse(sessionStorage.getItem('authData')).adminRole.permissions.includes('manage_settings'))
    {
      window.location.href = '/unauthorized';
    }
const routeChange = (e) =>{ 
    e.preventDefault();
    window.location.href='/holiday';
}

  const submitButton = (e) => {

    e.preventDefault();
    const isValid = formvalidation();

    if(isValid){
        let URL = BaseUrl +"admin/holidays";
        const payload = {
            holidayTitle: holidayTitle.trim(),
            holidayDate: moment(holidayDate).format('YYYY-MM-DD'),
            holidayStatus : holidaystatus,
                //description:description
            };
 
        var loginDetails = {};
         if (sessionStorage.getItem('authData')) {
            loginDetails = JSON.parse(sessionStorage.getItem('authData'));
        }
    
        http.post(URL,payload).then((response)=>{
   
            let responseData = response.data.data;
            if (response.data.success === true) {
                //window.location = '/holiday';
                setAlertPopup(true);
                setAlertMessage('Holiday has been added successfully'); 
              }
   
        }).catch((error)=>{
            console.log(error)
      
        });

    }
     
}

const formvalidation = () =>
{
    
    const errmessage = {};
    let isValid = true;
    
    if(holidayTitle == '' )
    {
        errmessage.holidayTitleerr = "Title is Required";
        isValid = false;
    }
    
    if(holidayDate=== '' || holidayDate === null)
    {
        errmessage.holidayDateerr = "Date is Required";
        isValid = false;
    }
    
    setErrMessage(errmessage);
    return isValid;
}

   const handleChange = (e) => {

       
        var re = /^\S/;
        if(e.target.name == 'holidayTitle')
        {
            if (e.target.value === '' || re.test(e.target.value))
            setHolidayTitle(e.target.value);
        }
        if(e.target.name == 'holidaystatus')
        {
            setHolidayStatus(e.target.value);
        }
};


  return (
    <>
    {alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
        <div className="dashboard-block">
            
                <Sidebar />
           
            <div className="dash-left">
                <Header />
                <section className="cart-block">
                <div className="container">
                    <h1 className="checkout-title vi">Add Holiday</h1> 

                    <div className="right-table-outer-wrap"> 
                     <div className='myact-wrap'>
                        <div className='client-details'>
                            <div className='form-field holiday-add-fieldset form-container-xs'>
                                <form className='form-field freeproduct-fieldset holiday-add-fieldset' method='post' onSubmit={submitButton}>
                                    <div className='input-field col-1'>
                                        <label htmlFor='name'>Title</label>
                                        <input type="text" onChange={handleChange} value={holidayTitle} name='holidayTitle' />
                                        <div style={{color:"red"}}>{errmessage.holidayTitleerr}</div> 
                                    </div>
                                    <div className='input-field col-1'>
                                        <label className='full-width' htmlFor='accountcode'>Date</label>
                                       <DatePicker
                                        selected={holidayDate}
                                        onChange={(date) => setHolidayDate(date)}
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="Select a date"
                                        disablePast={true}
                                        minDate={new Date()}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                         }}
                                     />
                                     <div style={{color:"red"}}>{errmessage.holidayDateerr}</div> 
                                    </div>
                                 

                                 <div className='btnfield'>
                                 <button type='submit' className="submitbtn" >Submit</button>
                                  &nbsp;&nbsp;
                                  <button className="submitbtn" onClick={routeChange}>Cancel</button>
                                 </div>

            

                           </form>
                            </div>
                        </div>
                      </div>

                     </div>
                     </div>
                </section>
                <Footer />
               
            </div>
        </div>
		
    </>
);


}
export default Holidayadd;

  

   