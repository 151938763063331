import { useEffect, useState, memo } from "react";
import { Bar } from "react-chartjs-2";
import { http } from "../helper";
import { BaseUrl } from "../common/config";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

export const BarChart = memo(({ }) => {
    var currentTime = new Date();
    var currentyear = currentTime.getFullYear();
    const [reportType, setReportType] = useState("weekly");
    const [year, setYear] = useState(currentyear);
    const [status, setStatus] = useState("Acknowledged");
    const [isLoading, setIsLoading] = useState(true);

    const [topSellerChartData, setTopSellerChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Top Selling Products",
                data: [],
                backgroundColor: ["rgba(252,194,84,1)", "#f4ecec"],
                borderColor: "#e6e6e6",
                borderWidth: 2,
            },
        ],
    });

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    useEffect(() => {
        let URL3 = BaseUrl + "admin/getrevenuereport";
        http
            .post(URL3, { reportType: reportType, year: parseInt(year), status: status })
            .then((response) => {
                setIsLoading(false);
                const chartData = response.data.data.filter((item) => item.amount > 0)
                setTopSellerChartData({
                    labels: chartData.map((item) =>
                        item._id.month !== undefined
                            ? item._id.month + "/" + item._id.year
                            : item._id.year !== undefined ? item._id.year : item._id
                    ),
                    datasets: [
                        {
                            label: "Total Revenue",
                            data: chartData.map((item) => item.amount),
                            backgroundColor: ["rgba(255,207,0,1)", "#f66b7b"],
                            borderColor: "#E5E5E5",
                            borderWidth: 0,
                            barThickness: 40,
                            maxBarThickness: 60,
                            borderRadius: 4,

                        },
                    ],
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [reportType, year, status]);

    const changeHandler = (e) => {
        setReportType(e.target.value);
    };
    const changeYearHandler = (e) => {
        setYear(e.target.value);
    };
    const changeStatusHandler = (e) => {
        setStatus(e.target.value);
    };
    const options = [];
    for (let i = currentyear; i >= 2023; i--) {
        const year = i;
        options.push(<option value={year}>{year}</option>);
    }
    let loadingbuffer = isLoading ? 'loading-buffer':'';
    return (
       
        <div className={`chart-container chart-revenue ${loadingbuffer}`}>
            <div className="chart-Hdr">

                <h2 style={{ textAlign: "left" }}>
                    Revenue Chart{"  "}
                </h2>
                <div className="chart-fltr">
                    <select style={{width:'170px'}}
                        value={status}
                        onChange={(e) => changeStatusHandler(e)}

                    > <option value={"Both"}>Both</option>
                        <option value={"Acknowledged"}>Acknowledged</option>
                        <option value={"Invoiced"}>Invoiced</option>

                    </select>
                    <select style={{width:'150px'}}
                        value={reportType}
                        onChange={(e) => changeHandler(e)}

                    > <option value={"weekly"}>Last 7 days</option>
                        <option value={"monthly"}>Monthly</option>
                        <option value={"yearly"}>Yearly</option>

                    </select>
                    {reportType === 'monthly' && <select style={{width:'100px'}}
                        value={year}
                        onChange={(e) => changeYearHandler(e)}
                    // style={{ maxWidth: "100px", marginLeft: "10px", display: "inline" }}
                    >
                        {options}
                    </select>}
                </div>

            </div>
            {topSellerChartData && (
                <Bar
                    data={topSellerChartData}
                    options={{
                        scales: {
                            y: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)',
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                }
                            },
                            x: {
                                grid: {
                                    color: 'rgba(247, 243, 241, 0.8)'
                                },
                                ticks: {
                                    color: 'rgba(207, 208, 211, 1)',
                                }
                            }
                        },
                        plugins: {
                            title: {
                                display: false,
                                text: "Revenue Chart",
                            },
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                titleColor: 'rgba(220, 222, 226, 1)',
                                bodyColor: 'rgba(220, 222, 226, 1)'
                            }
                        },
                    }}
                />
            )}
        </div>
    );
});
