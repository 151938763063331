import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
export const TopSellProducts = ({ }) => {
    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const columns = [
        {
            name: "Product Name",
            selector: row => row.Description,
            sortable: false,
        },
        {
            name: "Product Code",
            selector: row => row.ProductNumber,
            sortable: false,
        },
        {
            name: "Quantity Sold",
            selector: row => row.sum,
            sortable: false,
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells

            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells

            },
        },
    };

    const [filter, setFilter] = useState(0);
    const [topSellingProducts, setTopSellingProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {

        let URL1 = BaseUrl + "admin/topsellers";
        http.post(URL1, { filter: parseInt(filter) }).then((response) => {
            setIsLoading(false);
            setTopSellingProducts(response.data.data.filter((item) => item.sum > 0));


        }).catch((err) => {
            console.log(err);
        })


    }, [filter]);


    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
    };
    let loadingbuffer = isLoading ? 'loading-buffer':'';
    return (
        <div className={`chart-container chart-top-selling ${loadingbuffer}`}>
            <div className="chart-Hdr">
                <h2 style={{ textAlign: "center" }}>Top Selling Products </h2>
                <div className="chart-fltr">
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                    ><option value={0}>All</option>
                        <option value={1}>Last 30 days</option>
                        <option value={3}>Last 3 months</option>
                        <option value={6}>Last 6 months</option>
                    </select>}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={topSellingProducts}
                customStyles={customStyles}
                pagination={false}


            />
        </div>
    );
};