import React , { useState,useRef } from 'react'
import csvIcon from '../assets/images/csv.png';

const ImportPopup = (props)=> {
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); 

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        
      };

    const handleUpload = () => {
        if (file) {
            props.closePopup(null, true, file,props.impValue);
        } else {
          alert('Please select a file before uploading.');
        }
      };
  
      const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Programmatically trigger the file input click event
          }
      };


  return (
  
   <div className="product-import-pop">
        <div className="product-import-pop-overlay"></div>
        <div className="product-import-wrap">
            <span className="close-import-pop" onClick={() => props.closePopup(null, false, null,null)}></span>
            
            <div className="product-import-body">
                <div className="product-import-header">
                    <h3>Import {props.impValue} by CSV</h3>
                </div>
                <div className="product-import-content">
                {file && (<div className='file-added'><p>Added File</p></div>)}
                  {!file && ( <div className="custom-file-add">
                        <div className="custom-file" onClick={triggerFileInput}>
                            <span>Add File</span>
                            <input type="file" className="custom-file-input" onChange={handleFileChange} ref={fileInputRef} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"   style={{ display: 'none' }}/>
                        </div>
                    </div>)}
                    {file && (
                    <div className="custom-file-choose">
                        <p><span className="file-icon"><img src={csvIcon}/></span>{file.name}</p>
                        <button onClick={triggerFileInput}>Replace File</button>
                        <input 
                        type="file" 
                        className="custom-file-input" 
                        onChange={handleFileChange} 
                        ref={fileInputRef} 
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                        style={{ display: 'none' }} // Hide the file input element
                        />
                    </div>
                    )}
                </div>
                <div className="product-import-bottom">
                    <button className="button cancel-btn" onClick={() => props.closePopup(null, false, null,null)}>Cancel</button>
                    <button className={`button upload-btn ${!file ? 'disabled' : 'active'}`} onClick={handleUpload} disabled={!file}>Upload and Continue</button>
                </div>
            </div>
        </div>
    </div>
    
  )
}

export default ImportPopup