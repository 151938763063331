import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { http } from '../helper';
import { BaseUrl } from '../common/config';
export const TopCustomers = ({ }) => {

    const priceFormat = (price) => {
        return price.toLocaleString("en-US", {
            style: "currency",
            currency: "GBP",
            minimumFractionDigits: 2,
        });
    };

    const columns = [
        {
            name: "Account Code",
            selector: row => row.accountCode,
            sortable: false,
        },
        {
            name: "Account Name",
            selector: row => row.accountName,
            sortable: false,
        },
        {
            name: "Ordered Amount",
            selector: row => priceFormat(row.sum),
            sortable: false,
        }

    ];

    const customStyles = {
        rows: {
            style: {
                minHeight: '25px',
            },
        },
        headCells: {
            style: {
                padding: '8px', // override the cell padding for head cells

            },
        },
        cells: {
            style: {
                padding: '8px', // override the cell padding for data cells

            },
        },
    };



    const [filter, setFilter] = useState(0);
    const [topOrderedCustomers, setTopOrderedCustomers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {


        let URL2 = BaseUrl + "admin/topcustomers";
        http.post(URL2, { filter: parseInt(filter) }).then((response) => {
            setIsLoading(false);
            setTopOrderedCustomers(response.data.data.filter((item) => item.sum > 0));


        }).catch((err) => {
            console.log(err);
        })

    }, [filter]);

    const changeFilterHandler = (e) => {
        setFilter(e.target.value);
    };
    let loadingbuffer = isLoading ? 'loading-buffer':'';
    return (
        <div className={`chart-container chart-high-customers ${loadingbuffer}`} style={{ verticalAlign: "top" }}>
            <div className="chart-Hdr">
                <h2 style={{ verticalAlign: "top" }}>High-Value Customers </h2>
                <div className="chart-fltr">
                    {<select
                        value={filter}
                        onChange={(e) => changeFilterHandler(e)}
                    ><option value={0}>All</option>
                        <option value={1}>Last 30 days</option>
                        <option value={3}>Last 3 months</option>
                        <option value={6}>Last 6 months</option>
                    </select>}
                </div>
            </div>
            <DataTable
                columns={columns}
                data={topOrderedCustomers}
                customStyles={customStyles}
                pagination={false}


            />
        </div>
    );
};