import React, { Component, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../common/layout/header'; 
import Footer from '../common/layout/footer';
import { http } from '../helper'; 
import { BaseUrl } from '../common/config';
import CommonAlert from '../common/Commonalert';
import Sidebar from '../common/sidebar';
import ShowAlert from '../common/Showalert';
import { menuPermissions } from '../account/menupermissions';

const EditRoles=()=> {
  
    const [roleName, setroleName] = useState([]);
    const [preroleName, setPreroleName] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPermissions, setSelectedPermissions] = useState([]);    
    const [errors, setErrors] = useState({});
    const [alertPopup,setAlertPopup] = useState(false);
    const [alertMessage,setAlertMessage] = useState([]);
    const [alerterrorPopup,setAlertErrorPopup] = useState(false);
    const [alerterrMessage,setAlerterrMessage] = useState([]);
    
    const params = useParams();
    if(!sessionStorage.getItem("isLoggedIn"))
        window.location.href = '/';
    useEffect(()=>
    {
        var loginDetails = {};
    if (sessionStorage.getItem('authData')) {
        loginDetails = JSON.parse(sessionStorage.getItem('authData'));
    }       


        // Fetch permissions from the database
        const fetchPermissions = async () => {
            try {
            let URL = BaseUrl +  `admin/getRole/${params.id}`;
            const response = await http.get(URL)
           
             setroleName(response.data.data.roleName);
             setPreroleName(response.data.data.roleName);
             setSelectedPermissions(response.data.data.permissions);
             setIsLoading(false);
            } catch (error) {
            console.error('Error fetching permissions:', error);
            }

            let URL1 = BaseUrl + `admin/getRoles/`;
            http.get(URL1).then((response) => {
                let responseData1 = response.data.data;
                 
                setRoleList(responseData1);
            }).catch((err) => {
                console.log(err);
            })
        };
    
        fetchPermissions();

        
    },[]);

    const handlePermissionChange = (e) => {
        const { value, checked } = e.target;
        setSelectedPermissions((prevPermissions) =>
          checked
            ? [...prevPermissions, value]
            : prevPermissions.filter((perm) => perm !== value)
        );
    
        // Clear error message for permissions
        setErrors((prevErrors) => ({
          ...prevErrors,
          permissions: ''
        }));
      };
    
    
      const formvalidation = () =>
        {        
            const newErrors = {};
            let isValid = true;
    
            if (roleName.trim() === '') {
                newErrors.roleName = "Role Name is Required";
                isValid = false;
            }
            if (selectedPermissions.length === 0) {
                newErrors.permissions = "At least one permission must be selected";
                isValid = false;
            }
    
                
            setErrors(newErrors);
            return isValid;
        
            
        }
        const routeChange = () =>{ 
            window.location.href=`/role/`;
        }
    

    const btnsubmit = (e) =>
    {
        e.preventDefault();
        
        const isValid = formvalidation();
    
       if(isValid)
       {
        //let MasterValue = MasterValue.trim();
        let payload = {
            id : params.id,
            roleName   : roleName,
            permissions  : selectedPermissions ,
         }
         if(preroleName != roleName)
         {
            var isFound = roleList.some(element => {
                if (element.roleName.toLowerCase() === roleName.toLowerCase()) {
                  return true;
                }
            
                return false;
              });
         }
         else{
            var isFound = false;

          }
         
        if(isFound)
        {
            setAlertErrorPopup(true);
            setAlerterrMessage('This Role Name has already been added'); 

        }
        else
        {
            var loginDetails = {};
            if (sessionStorage.getItem('authData')) {
               loginDetails = JSON.parse(sessionStorage.getItem('authData'));
            } 
   
        let URL = BaseUrl + "admin/editRole";
        http.put(URL, payload).then(async (response) => {
               
                if (response.data.success === true) {
                    setAlertPopup(true);
                    setAlertMessage('Roles and Permissions have been updated successfully'); 
                }
            }).catch((err) => {
            console.log(err);
            
            })
        }
       }
        
     }

  return (
    
    <section className="dashboard-block">
        { alerterrorPopup &&  ( <ShowAlert title='Alert' message={alerterrMessage}/> )}
{alertPopup && ( <CommonAlert title='Alert' message={alertMessage}/> )}
    
            <Sidebar />
   
    <div className="dash-left">
    <Header/>
        <section className="cart-block">
         <div className="container account-details">
            <h1 className="checkout-title">Edit Role and Permissions</h1>

            <div className="right-table-outer-wrap">
            <div className='form-field freeproduct-fieldset addroles-fieldset form-container-xs'>
                        <form className='client-detail-form'>
                            <div className='input-field'>
                                <label for='name'>Role Name</label>
                                <input type='text' name='roleName' value={roleName} onChange={(e) => {setroleName(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    roleName: '',
                  }));
                }} />
                                {errors.roleName && <div style={{color:"red"}}>{errors.roleName}</div>}

                            </div>
                            <div className='input-field'>
                                <label for='name'>Permissions</label>
                                <div className='custom_check-box'>
                                {menuPermissions.map((permission,index) => (
                                 <div className='check-box'>
                                <input 
                                    id={`chkbox${index}`}
                                    type="checkbox"
                                    value={permission.value}
                                    checked={selectedPermissions.includes(permission.value)}
                                    onChange={handlePermissionChange}
                                />
                                <label for={`chkbox${index}`}>{permission.name}</label>
                                 </div>
                            ))}
                            </div>
                            {errors.permissions && <p style={{ color: 'red' }}>{errors.permissions}</p>}
                            </div>
                           
                            <div className='btnfield'>
                            <button type="submit" name="btnsubmit" onClick={btnsubmit}>Update Role</button>
                            &nbsp;&nbsp;&nbsp;
                            <button type="button" onClick={routeChange}>Cancel</button>
                            </div>
                           
                            
                        </form>
                    </div>
           </div>
         </div>
         <Footer/> 
        </section>
       
            </div>
          
        </section>
  )
}
export default EditRoles